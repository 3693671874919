import { Box, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { LoanHeader } from "../../../components/LoanHeader";
import ondc from "../../../assets/Patners/5.png";

const LoanPageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const OutletContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: 0,
  height: "70vh",
  overflowY: "scroll",
  scrollbarWidth: "none",
  "-ms-overflow-style": "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    padding: 0,
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0),
  },
}));

const LoanPages = () => {
  return (
    <LoanPageContainer>
      <LoanHeader />
      <OutletContainer>
        <Outlet />
      </OutletContainer>

      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"row"}
        flex={1}
      >
        <Typography sx={{ marginRight: 2 }}>Powered by</Typography>
        <img src={ondc} alt="ondc-logo" style={{ height: "30px" }} />
      </Stack>
    </LoanPageContainer>
  );
};

export default LoanPages;
