import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../../utils/context";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  creditInit,
  creditStatusCheck,
  getCreditOffers,
  getFormUrl,
} from "../../../slice/creditForm.slice";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";

const KYCWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(5),
}));

const useStyles = {
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  planeImage: {
    width: "50px",
    height: "50px",
    transition: "transform 1s ease-in-out",
  },
  planeImageFlying: {
    transform: "translateX(250px) rotate(-20deg)",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoanAccordianContainer = styled(Box)(({ theme, styleType }) => ({
  margin: styleType === "special" ? "20px 20px 0px 20px" : "20px",
}));

const KycPage = () => {
  /* ADDED INITALIZATION */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeStep, setActiveStep } = useContext(MyContext);
  const [progress, setProgress] = useState(0);
  const [offer, setOffer] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [openEventStatusDialog, setOpenEventStatusDialog] = useState(false);
  const user_role = sessionStorage.getItem("user_role");
  const txnId = sessionStorage.getItem("txnId");
  const offerId = sessionStorage.getItem("offer_id");
  const [page, setPage] = useState("");
  const [formUrl, setFormUrl] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [funcStep, setFuncStep] = useState(0);
  const [isFlying, setIsFlying] = useState(false);
  const [offerItemId, setOfferItemId] = useState("");

  let kyc_url;

  /* CALLING GET OFFER DETAILS TO FETCH OFFER DETAILS  */
  useEffect(() => {
    setActiveStep(3);
    const payload = {
      txnId: txnId,
    };
    dispatch(getCreditOffers(payload)).then((res) => {
      setOffer(res?.payload || []);
      if (location.state?.isResume === true) {
        setOfferItemId(
          res?.payload?.offer_list[0]?.offer_details?.offer_item_id
        );
      }
    });
  }, [txnId]);

  //KYC PAGE FUNCTIONALITY HERE!!
  const handleNextSubmit = () => {
    setFuncStep(1);
    setShowLoader(true);
    setOpenEventStatusDialog(false);
    const payload = {
      txnId: txnId,
      offerId: offerId || offerItemId,
    };
    let retryCount = 0;
    const id = setInterval(() => {
      if (retryCount > 3) {
        clearInterval(id);
        setShowLoader(false);
        setOpenEventStatusDialog(true);
        return;
      }

      dispatch(creditStatusCheck(payload)).then((res) => {
        if (res?.payload?.current_action === "ON_SELECT_KYC") {
          clearInterval(id);
          dispatch(getFormUrl(payload)).then((res) => {
            const response = res?.payload;
            if (response?.form_url) {
              setProgress(progress + 40);
              setFormUrl(response?.form_url);
              setPage("KYC");
              setConfirmationDialog(true);
              setShowLoader(false);
              setFuncStep(0);
            }
          });
        } else {
          retryCount += 1;
        }
      });
    }, 3000);
  };
  //Here we are Checking for GTS KYC
  const fetchTransactionStatus = () => {
    setShowLoader(true);
    const payload = {
      txnId: txnId,
      offerId: offerId || offerItemId,
    };
    const id = setInterval(() => {
      dispatch(creditStatusCheck(payload)).then((res) => {
        const selectResponse = res?.payload;
        if (selectResponse?.redirection_status === "KYC_APPROVED") {
          kyc_url.close();
          setProgress(progress + 40);
          clearInterval(id);
          dispatch(creditInit(payload)).then((res) => {
            const initResponse = res?.payload;
            if (initResponse?.ack_status === "ACK") {
              const nextStep = activeStep + 1;
              setShowLoader(false);
              setActiveStep(nextStep);
              navigate("/loan/bank-details");
            } else {
              console.log("ACK NOT FOUND");
            }
          });
        } else {
          console.log("Error Occured");
        }
      });
    }, 3000);
  };

  /*DIALOG FUNCTIONALITY HERE */

  //We are implementing the functionality for dialog box here
  const handleDialogSubmit = (value) => {
    if (value === "YES") {
      setIsFlying(true);
      setProgress(progress + 20);
      setTimeout(() => {
        setConfirmationDialog(false);
        setIsFlying(false);
      }, 1000);
      setTimeout(() => {
        kyc_url = window.open(formUrl, "_blank");
      }, 1800);
      fetchTransactionStatus();
    } else {
      setShowLoader(false);
      setConfirmationDialog(false);
      return;
    }
  };

  const handleRejection = () => {
    if (user_role === "guest") {
      setOpenEventStatusDialog(false);
      navigate("/loan/kyc-page");
    } else {
      setOpenEventStatusDialog(false);
      navigate("/home/dashboard");
    }
  };
  return (
    <>
      <CustomLoader open={showLoader} />
      <Stack>
        <KYCWrapper>
          <Stack gap={4}>
            <Typography
              sx={{
                fontSize: "1.4rem",
                fontFamily: "Plus Jakarta Sans bold",
              }}
            >
              KYC
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                color: "#535353",
                fontFamily: "source sans pro",
              }}
            >
              I hereby declare that I am voluntarily sharing my Aadhaar Number
              and demographic information issued by UIDAI with the concerned
              authorities for the sole purpose of completing the KYC process. I
              understand that my information can be used and shared for purposes
              as may be notified from time to time, including compliance with
              regulatory requirements.
            </Typography>
          </Stack>
          <Stack justifyContent={"center"} alignItems={"flex-end"} mt={2}>
            <Button
              onClick={handleNextSubmit}
              size="medium"
              variant="contained"
              sx={{
                backgroundColor: "#784af4",
                textTransform: "none",
                fontFamily: "Plus Jakarta Sans, bold",
              }}
            >
              Start Kyc
            </Button>
          </Stack>
        </KYCWrapper>
      </Stack>
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogContent style={useStyles.dialogContent}>
          <img
            src="https://pngfre.com/wp-content/uploads/Airplane-4.png"
            alt="Plane"
            style={{
              ...useStyles.planeImage,
              ...(isFlying ? useStyles.planeImageFlying : {}),
            }}
          />
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            Please don't exit or press back.
            <br />
            Please wait while we are redirecting you to the Verification Page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogSubmit("YES")}>Proceed</Button>
          {/* <Button onClick={() => handleDialogSubmit("NO")}>No</Button> */}
        </DialogActions>
      </Dialog>
      {/* ERROR DIALOG*/}
      <Dialog
        open={openEventStatusDialog}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogContent>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              sx={{
                fontFamily: "plus jakarta sans bold",
                fontSize: "2.8rem",
                color: "#784af4",
              }}
            >
              Oops!
            </Typography>
          </Stack>
          <Typography>
            Something went wrong while processing your request. Please try again
            by clicking the "Retry" button below.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            onClick={handleRejection}
            sx={{
              backgroundColor: "#784af4",
              textTransform: "none",
              fontFamily: "Plus Jakarta Sans, bold",
            }}
          >
            Retry
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KycPage;
