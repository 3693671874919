import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../utils/request";
import { apis } from "../utils/apis";

export const getAllCreditCustomer = createAsyncThunk(
  "getAllCreditCustomer",
  async () => {
    const response = await apiRequest("GET", `${apis?.listAllCreditCustomer}`);
    return response;
  }
);

export const creditCustomerDetails = createAsyncThunk(
  "creditCustomerDetails",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.creditCustomerDetails}?customer_id=${payload}`
    );
    return response;
  }
);

export const sendNotification = createAsyncThunk(
  "sendNotification",
  async (payload) => {
    const response = await apiRequest("POST", apis?.sendNotification, payload);
    return response;
  }
);

export const getApprovedOffers = createAsyncThunk(
  "getApprovedOffers",
  async (payload) => {
    const param = payload?.userId
      ? `user_id=${payload?.userId}`
      : payload?.customerId
      ? `customer_id=${payload?.customerId}`
      : `txn_id=${payload.txnId}`;
    const response = await apiRequest(
      "GET",
      `${apis?.getApprovedOffers}?${param}`
    );
    return response;
  }
);

export const getCreditOffers = createAsyncThunk(
  "getCreditOffers",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.getCreditOffers}?txn_id=${payload.txnId}`
    );
    return response;
  }
);

export const getCreditOffer = createAsyncThunk(
  "getCreditOffer",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.getCreditOffers}?txn_id=${payload.txnId}&offer_term_id=${payload.offerId}`
    );
    return response;
  }
);

export const submitCreditForm = createAsyncThunk(
  "submitCreditForm",
  async ({ payload, txnId }) => {
    // console.log("Checking the Api: " + payload + " " + txnId);
    const response = await apiRequest(
      "POST",
      `${apis?.submitCreditForm}?txn_id=${txnId}`,
      payload
    );
    return response;
  }
);

export const creditSelect = createAsyncThunk(
  "creditSelect",
  async (payload) => {
    // console.log(payload);
    const response = await apiRequest(
      "POST",
      `${apis?.creditSelect}?txn_id=${payload.txnId}`
    );
    return response;
  }
);

export const creditSearch = createAsyncThunk(
  "searchCredit",
  async (user_id) => {
    const response = await apiRequest(
      "POST",
      `${apis?.creditSearch}?user_id=${user_id}`
    );
    return response;
  }
);

export const creditSelectOffer = createAsyncThunk(
  "creditSelectOffer",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.creditSelect}?txn_id=${payload.txnId}&offer_item_id=${payload.offerTermId}`
    );
    return response;
  }
);

export const getaaUrl = createAsyncThunk("getaaUrl", async (payload) => {
  const response = await apiRequest(
    "GET",
    `${apis?.getaaUrl}?user_id=${payload.userId}&txn_id=${payload.txnId}`
  );
  return response;
});

export const getFormUrl = createAsyncThunk("getaaUrl", async (payload) => {
  const response = await apiRequest(
    "GET",
    `${apis?.getFormUrl}?txn_id=${payload.txnId}&offer_item_id=${payload.offerId}`
  );
  return response;
});

export const creditInit = createAsyncThunk("creditInit", async (payload) => {
  const response = await apiRequest(
    "POST",
    `${apis?.creditInit}?txn_id=${payload.txnId}&offer_item_id=${payload.offerId}`
  );
  return response;
});

export const creditConfirm = createAsyncThunk(
  "creditConfirm",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.creditConfirm}?txn_id=${payload.txnId}&offer_item_id=${payload.offerId}`
    );
    return response;
  }
);

export const selectConsent = createAsyncThunk(
  "selectConsent",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.selectConsent}?txn_id=${payload.txnId}`
    );
    return response;
  }
);

export const getItemDetails = createAsyncThunk(
  "getItemDetails",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.getItemDetails}?row_id=${payload.rowId}`
    );
    return response;
  }
);

export const creditPayment = createAsyncThunk(
  "creditPayment",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.creditPayment}`,
      payload
    );
    return response;
  }
);

export const creditPaymentStatus = createAsyncThunk(
  "creditPaymentStatus",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.creditPaymentStatus}?txn_id=${payload.txnId}&offer_item_id=${payload.offerId}`
    );
    return response;
  }
);

export const creditIssue = createAsyncThunk("creditIssue", async (payload) => {
  const response = await apiRequest(
    "POST",
    `${apis?.creditIssue}?txn_id=${payload.txnId}&order_id=${payload.orderId}&issue_action=${payload.action}&issue_category=${payload.category}&issue_sub_category=${payload.subCategory}`
  );
  return response;
});

export const creditListIssues = createAsyncThunk(
  "creditListIssues",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.creditListIssues}/${payload.customer_id}`
    );
    return response;
  }
);

export const creditIssueStatus = createAsyncThunk(
  "creditIssueStatus",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.creditIssueStatus}?txn_id=${payload.txnId}&issue_id=${payload.issueId}`
    );
    return response;
  }
);

export const creditCloseIssue = createAsyncThunk(
  "creditCloseIssue",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.creditCloseIssue}?txn_id=${payload.txnId}&order_id=${payload.orderId}&issue_action=${payload.issueAction}&issue_id=${payload.issueId}`
    );
    return response;
  }
);

export const creditStatusCheck = createAsyncThunk(
  "creditStatusCheck",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.creditStatusCheck}?txn_id=${payload.txnId}`
    );
    return response;
  }
);

export const contactUs = createAsyncThunk("contactUs", async (payload) => {
  const response = await apiRequest(
    "POST",
    `${apis.contactUs}?lead_category=${payload.lead_category}`,
    payload
  );
  return response;
});

const CreditFormSlice = createSlice({
  name: "SubmitCredit",
  initialState: {
    creditList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetSearchData: (state, value) => {
      state.creditList = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitCreditForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitCreditForm.fulfilled, (state, action) => {
        state.loading = false;
        state.creditList = action.payload;
      })
      .addCase(submitCreditForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
// export const { reducer } = ProductsSlice;
export default CreditFormSlice.reducer;
