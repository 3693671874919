import React, { useContext } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Box,
  FormLabel,
  InputAdornment,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../CustomComponents/CustomLoader";
import CustomSnackbar from "../CustomComponents/CustomSnackbar";
import {
  insuranceSearch,
  submitInsuranceForm,
} from "../../pages/AuthorizedPages/InsuranceRegistration/insurance.slice";
import { MyContext } from "../../utils/context";

const ErrorMessageBox = ({ children }) => (
  <div style={{ color: "red", fontSize: "12px" }}>{children}</div>
);

const EndAdornmentCompoenet = styled(Box)(({ theme }) => ({
  padding: "5px 15px",
  border: "1px solid #f8f8f8",
  borderRadius: "4px",
  height: "100%",
  backgroundColor: "#1976d2",
  color: "white",
  display: "flex",
  alignItems: "center",
}));

const SelfInsuranceForm = () => {
  const environment = sessionStorage.getItem("environment");
  const txnId = sessionStorage.getItem("txnId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { setActiveStep } = useContext(MyContext);

  const [error, setError] = useState(false);
  const [individualInfo, setIndividualInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    PED: "No",
    diabetes: false,
    bloodPressure: false,
    heartAilments: false,
    other: false,
    gender: "M",
    dob: "",
    amount: "",
    panIndia: false,
    pincode: "",
    politicallyExposedPerson: false,
    gstin: "",
    heightfoot: "",
    heightinch: "",
    weight: "",
    relation: "self",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setIndividualInfo((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form validation before submitting
    if (
      !individualInfo.firstName ||
      !individualInfo.lastName ||
      !individualInfo.email ||
      !individualInfo.phone ||
      !individualInfo.address ||
      !individualInfo.dob ||
      !individualInfo.gender ||
      !individualInfo.amount ||
      !individualInfo.pincode ||
      !individualInfo.weight ||
      !individualInfo.heightfoot ||
      !individualInfo.heightinch
    ) {
      setError(true);
      return;
    }
    setShowLoader(true);
    const payload = {
      formData: {
        individualInfo,
      },
      txnId: txnId,
    };
    sessionStorage.setItem("phone", individualInfo.phone);
    dispatch(submitInsuranceForm(payload)).then((res) => {
      if (res?.error && Object.keys(res?.error)?.length > 0) {
        setShowSnackbar(true);
        setShowLoader(false);
        return;
      }
      const isSuccess = res?.payload.some(
        (item) => item.form_status === "Success"
      );
      if (isSuccess) {
        const searchPayload = {
          environment: environment,
          txnId: txnId,
        };
        dispatch(insuranceSearch(searchPayload)).then((response) => {
          setTimeout(() => {
            setShowLoader(false);
            setActiveStep();
            setActiveStep(1);
            navigate("/home/insurance-offer");
          }, 3000);
        });
      } else {
        setShowLoader(false);
      }
    });
  };

  return (
    <Box style={{ padding: "20px" }}>
      <CustomLoader open={showLoader} />
      <CustomSnackbar
        open={showSnackbar}
        message={"Something Went Wrong"}
        status={"error"}
        onClose={() => setShowSnackbar(false)}
      />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.firstName && error}
            >
              <FormLabel>First Name</FormLabel>
              <TextField
                name="firstName"
                value={individualInfo.firstName}
                onChange={handleChange}
                placeholder="Enter First Name"
                error={!individualInfo.firstName && error}
              />
              {!individualInfo.firstName && error && (
                <ErrorMessageBox>Enter First Name</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.lastName && error}
            >
              <FormLabel>Last Name</FormLabel>
              <TextField
                name="lastName"
                value={individualInfo.lastName}
                onChange={handleChange}
                placeholder="Enter Last Name"
                error={!individualInfo.lastName && error}
              />
              {!individualInfo.lastName && error && (
                <ErrorMessageBox>Enter Last Name</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.email && error}
            >
              <FormLabel>Email</FormLabel>
              <TextField
                name="email"
                value={individualInfo.email}
                onChange={handleChange}
                placeholder="Enter Email"
                error={!individualInfo.email && error}
              />
              {!individualInfo.email && error && (
                <ErrorMessageBox>Enter a valid Email</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.phone && error}
            >
              <FormLabel>Phone Number</FormLabel>
              <TextField
                name="phone"
                value={individualInfo.phone}
                onChange={handleChange}
                placeholder="Enter Phone Number"
                error={!individualInfo.phone && error}
              />
              {!individualInfo.phone && error && (
                <ErrorMessageBox>Enter a valid Phone Number</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.address && error}
            >
              <FormLabel>Address</FormLabel>
              <TextField
                name="address"
                value={individualInfo.address}
                onChange={handleChange}
                placeholder="Enter Address"
                error={!individualInfo.address && error}
              />
              {!individualInfo.address && error && (
                <ErrorMessageBox>Enter Address</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <FormLabel>PED</FormLabel>
              <Select
                name="PED"
                value={individualInfo.PED}
                onChange={handleChange}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Checkbox Fields */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name="diabetes"
                  checked={individualInfo.diabetes}
                  onChange={handleChange}
                />
              }
              label="Diabetes"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name="bloodPressure"
                  checked={individualInfo.bloodPressure}
                  onChange={handleChange}
                />
              }
              label="Blood Pressure/Hypertension"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name="heartAilments"
                  checked={individualInfo.heartAilments}
                  onChange={handleChange}
                />
              }
              label="Heart Ailments"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name="other"
                  checked={individualInfo.other}
                  onChange={handleChange}
                />
              }
              label="Other Health Issues"
            />
          </Grid>

          {/* More Fields */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.gender && error}
            >
              <FormLabel>Gender</FormLabel>
              <Select
                name="gender"
                value={individualInfo.gender}
                onChange={handleChange}
                required
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
              </Select>
              {!individualInfo.gender && error && (
                <ErrorMessageBox>Select Gender</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>
          {/* Date Of Birth Feild */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.dob && error}
            >
              <FormLabel>Date of Birth</FormLabel>
              <TextField
                name="dob"
                type="date"
                value={individualInfo.dob}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              {!individualInfo.address && error && (
                <ErrorMessageBox>Enter Address</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* PAN INDIA AND POLITICALLY EXPOSE */}
          <Grid
            item
            xs={12}
            sm={3}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="panIndia"
                  checked={individualInfo.panIndia}
                  onChange={handleChange}
                />
              }
              label="PAN India Cover"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="politicallyExposedPerson"
                  checked={individualInfo.politicallyExposedPerson}
                  onChange={handleChange}
                />
              }
              label="Politically Exposed"
            />
          </Grid>

          {/* Coverage Feild */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.amount && error}
            >
              <FormLabel>Coverage Amount</FormLabel>
              <TextField
                name="amount"
                type="text"
                value={individualInfo.amount}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              {!individualInfo.amount && error && (
                <ErrorMessageBox>Enter Valid Amount</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Pincode Feild */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.pincode && error}
            >
              <FormLabel>Pincode</FormLabel>
              <TextField
                name="pincode"
                type="text"
                value={individualInfo.pincode}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              {!individualInfo.pincode && error && (
                <ErrorMessageBox>Please Enter Your Pincode</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Weight Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <FormLabel>GSTIN</FormLabel>
              <TextField
                name="gstin"
                type="text"
                value={individualInfo.gstin}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>

          {/* Weight Field */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.weight && error}
            >
              <FormLabel>Weight</FormLabel>
              <TextField
                name="weight"
                type="text"
                value={individualInfo.weight}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EndAdornmentCompoenet>kg</EndAdornmentCompoenet>
                    </InputAdornment>
                  ),
                }}
              />
              {!individualInfo.weight && error && (
                <ErrorMessageBox>Please Enter Your Weight</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Height Field */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.heightfoot && error}
            >
              <FormLabel>Height in Foot</FormLabel>
              <TextField
                name="heightfoot"
                type="text"
                value={individualInfo.heightfoot}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EndAdornmentCompoenet>Foot</EndAdornmentCompoenet>
                    </InputAdornment>
                  ),
                }}
              />
              {!individualInfo.heightfoot && error && (
                <ErrorMessageBox>
                  Please Enter Your Height in Foot
                </ErrorMessageBox>
              )}
            </FormControl>
          </Grid>
          {/* Height Field */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!individualInfo.heightinch && error}
            >
              <FormLabel>Height in Inches</FormLabel>
              <TextField
                name="heightinch"
                type="text"
                value={individualInfo.heightinch}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EndAdornmentCompoenet>Inches</EndAdornmentCompoenet>
                    </InputAdornment>
                  ),
                }}
              />
              {!individualInfo.heightinch && error && (
                <ErrorMessageBox>
                  Please Enter Your Height in Inches
                </ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SelfInsuranceForm;
