import * as React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import Logo from "../../assets/Images/easyLifiLogo.jpg";
import { useNavigate } from "react-router-dom";
import { CustomStepper } from "../CustomComponents/CustomStepper";

const StyledLoginButton = styled(Button)({
  border: "2px solid #FFCC00",
  color: "#000",
  padding: "5px 15px",
  textTransform: "none", // Prevents uppercase text
  "&:hover": {
    backgroundColor: "#FFCC00", // Add hover effect
    color: "#fff",
  },
});

const pages = ["Login"];
const steps = [
  "Personal Details",
  "Proffessional Details",
  "Select Offer",
  "KYC",
  "Account Details",
  "E-Mandate",
  "Order Details",
];

export function LoanHeader() {
  const navigate = useNavigate();
  const handleNavigateTOHome = () => {
    navigate("/");
  };

  const user_role = sessionStorage.getItem("user_role");

  const handleNavigate = () => {
    if (user_role === "admin") {
      navigate("/admin-dashboard");
    } else if (user_role === "manager") {
      navigate("/manager-dashboard");
    } else if (user_role === "agent") {
      navigate("/agent-dashboard");
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            fontFamily={"Red Hat Display"}
            sx={{
              color: "black",
              fontSize: "24px",
              fontWeight: 600,
              letterSpacing: "1px",
              flex: 1,
            }}
          >
            CliniQ360
          </Typography>
          {user_role === "guest" ? (
            <Box>
              <StyledLoginButton
                variant="outlined"
                onClick={handleNavigateTOHome}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                Login{" "}
              </StyledLoginButton>
            </Box>
          ) : (
            <Box>
              <StyledLoginButton
                variant="outlined"
                onClick={handleNavigate}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                Home{" "}
              </StyledLoginButton>
            </Box>
          )}
        </Toolbar>
      </Container>
      <CustomStepper steps={steps} showProgressBarByDefault={true} />
    </>
  );
}
