import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Slider,
  Box,
  TextField,
  Divider,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(5),
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  "& .MuiDialog-paper": {
    boxShadow: "none",
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  minHeight: "400px",
  maxHeight: "600px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "200px",
    minHeight: "100%",
    maxHeight: "none",
    padding: "10px",
  },
}));

const SliderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2, 0),
}));

const CustomizeOfferDetails = React.memo(
  ({
    offer,
    setNewOfferValues,
    handleDialogButton,
    handleButton,
    open,
    setOpen,
  }) => {
    const maxLoanAmount = offer?.quote_details?.PRINCIPAL;
    const minLoanAmount = offer?.offer_details?.MIN_LOAN_AMOUNT;
    const [loanAmount, setLoanAmount] = useState(0);
    const [error, setError] = useState(false);

    const handleSliderChange = (event, newValue) => {
      setLoanAmount(newValue);
    };

    const formatNumber = (num) => {
      const parsedNum = parseFloat(num);
      return parsedNum % 1 === 0 ? parsedNum.toFixed(0) : parsedNum.toFixed(2);
    };

    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      setLoanAmount(maxLoanAmount / 2);
    }, [maxLoanAmount]);

    useEffect(() => {
      setNewOfferValues({
        LOAN_AMOUNT: loanAmount,
      });
    }, [loanAmount]);

    return (
      <React.Fragment>
        <Button variant="text" onClick={handleDialogButton}>
          Customize
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Customize Offers
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          <StyledDialogContent dividers>
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={2}
              sx={{
                marginY: 2,
                padding: 2,
                backgroundColor: "rgba(0, 137, 233, 0.1)",
              }}
            >
              <CelebrationIcon sx={{ color: "#0561A0" }} />
              <Typography variant="body1">
                Congratulations! You have been approved a loan of ₹
                {formatNumber(maxLoanAmount)}
              </Typography>
            </Stack>
            <Typography variant="body2">
              {offer?.provider_details?.name}
            </Typography>
            <TextField
              type="number"
              label="Loan Amount"
              value={loanAmount}
              variant="outlined"
              fullWidth
              margin="normal"
              // onChange={(e) => setLoanAmount(e.target.value)}

              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />

            <Typography variant="body2" sx={{ marginBottom: 2 }}>
              You can enter up to ₹{formatNumber(maxLoanAmount)}
            </Typography>
            <SliderBox sx={{ flexGrow: 1 }}>
              <Typography variant="body2">
                ₹{formatNumber(minLoanAmount)}
              </Typography>
              <Slider
                value={Number(loanAmount)}
                onChange={handleSliderChange}
                aria-labelledby="loan-amount-slider"
                min={Number(minLoanAmount)}
                max={Number(maxLoanAmount)}
                step={1000}
                sx={{ marginX: 2, flexGrow: 1 }}
              />
              <Typography variant="body2">
                ₹{formatNumber(maxLoanAmount)}
              </Typography>
            </SliderBox>
            <Divider sx={{ marginY: 2 }} />
            <Stack
              flexDirection="row"
              gap={2}
              sx={{
                marginBottom: 1,
                backgroundColor: "#F8F8F8",
                padding: 2,
                alignItems: "center",
              }}
            >
              <InfoOutlinedIcon fontSize="small" />
              <Typography variant="subtitle2" fontSize={12} fontWeight={550}>
                The displayed EMI amount is indicative in nature, the final EMI
                amount and associated repayment details will be shown after
                proceed
              </Typography>
            </Stack>
            <Stack
              flexDirection="row"
              gap={2}
              sx={{
                backgroundColor: "#F8F8F8",
                padding: 2,
                alignItems: "center",
              }}
            >
              <InfoOutlinedIcon fontSize="small" />
              <Typography variant="subtitle2" fontSize={12} fontWeight={550}>
                You will not be able to reselect the offer amount and tenure
                after you submitted the details
              </Typography>
            </Stack>
          </StyledDialogContent>
          <DialogActions>
            <Button variant="contained" autoFocus onClick={handleButton}>
              Save Changes
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    );
  }
);
export default CustomizeOfferDetails;

/*

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Slider,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const SliderBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export default function CustomizeOfferDetails({
  open,
  handleClose,
  selectedOffer,
  setNewOfferValues,
  newOfferValues,
  handleSaveChanges,
}) {
  const [loanAmount, setLoanAmount] = useState(
    selectedOffer?.offer_details?.MAX_LOAN_AMOUNT || 0
  );
  const [tenure, setTenure] = useState(
    selectedOffer?.offer_details?.MAX_TENURE || 0
  );
  const [interestRate, setInterestRate] = useState(
    selectedOffer?.offer_details?.MAX_INTEREST_RATE || 0
  );
  const [installmentAmount, setInstallmentAmount] = useState(
    selectedOffer?.offer_details?.INSTALLMENT_AMOUNT || 0
  );

  useEffect(() => {
    setLoanAmount(selectedOffer?.offer_details?.MAX_LOAN_AMOUNT || 0);
    setTenure(selectedOffer?.offer_details?.MAX_TENURE || 0);
    setInterestRate(selectedOffer?.offer_details?.MAX_INTEREST_RATE || 0);
    setInstallmentAmount(selectedOffer?.offer_details?.INSTALLMENT_AMOUNT || 0);
  }, [selectedOffer]);

  const handleLoanAmountChange = (event, value) => {
    setLoanAmount(value);
    setNewOfferValues((prevValues) => ({
      ...prevValues,
      LOAN_AMOUNT: value,
    }));
  };

  const handleTenureChange = (event, value) => {
    setTenure(value);
    setNewOfferValues((prevValues) => ({
      ...prevValues,
      SELECTED_TENURE_PLAN: {
        ...prevValues.SELECTED_TENURE_PLAN,
        months: value,
      },
    }));
  };

  const handleInterestRateChange = (event) => {
    const value = parseFloat(event.target.value);
    setInterestRate(value);
    setNewOfferValues((prevValues) => ({
      ...prevValues,
      INTEREST_RATE: value,
    }));
  };

  const handleInstallmentAmountChange = (event) => {
    const value = parseFloat(event.target.value);
    setInstallmentAmount(value);
    setNewOfferValues((prevValues) => ({
      ...prevValues,
      SELECTED_TENURE_PLAN: {
        ...prevValues.SELECTED_TENURE_PLAN,
        emi: value,
      },
    }));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitleStyled>Customize Offer Details</DialogTitleStyled>
      <DialogContent>
        <SliderBox>
          <Typography variant="subtitle1">Loan Amount</Typography>
          <Slider
            value={loanAmount}
            min={1000}
            max={50000}
            step={1000}
            onChange={handleLoanAmountChange}
            valueLabelDisplay="auto"
          />
          <Typography variant="body2">${loanAmount}</Typography>
        </SliderBox>
        <SliderBox>
          <Typography variant="subtitle1">Tenure (Months)</Typography>
          <Slider
            value={tenure}
            min={6}
            max={60}
            step={1}
            onChange={handleTenureChange}
            valueLabelDisplay="auto"
          />
          <Typography variant="body2">{tenure} months</Typography>
        </SliderBox>
        <CustomTextField
          fullWidth
          type="number"
          label="Interest Rate (%)"
          value={interestRate}
          onChange={handleInterestRateChange}
          InputProps={{ inputProps: { min: 1, max: 25, step: 0.1 } }}
        />
        <CustomTextField
          fullWidth
          type="number"
          label="Installment Amount"
          value={installmentAmount}
          onChange={handleInstallmentAmountChange}
          InputProps={{ inputProps: { min: 0, step: 1 } }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSaveChanges} color="primary" variant="contained">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
*/
