import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  styled,
  IconButton,
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  Select,
  MenuItem,
  AppBar,
  Toolbar,
  InputLabel,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import {
  creditStatusCheck,
  sendNotification,
} from "../../slice/creditForm.slice";
import {
  insuranceCancel,
  insuranceFormUrl,
  insuranceOfferDetails,
  insuranceStatus,
  insuranceTC,
} from "../AuthorizedPages/InsuranceRegistration/insurance.slice";
import ExpandableCard from "../../components/CustomComponents/ExpandableCard";
import CustomLoader from "../../components/CustomComponents/CustomLoader";
import CustomSnackbar from "../../components/CustomComponents/CustomSnackbar";

const OrderPageWrapper = styled("div")(({ theme }) => ({
  "&": {
    fontFamily: "Source Sans Pro",
    margin: "0 20px",
  },
}));

const InsurerPolicyDetails = () => {
  const txnId = sessionStorage.getItem("txnId");
  const offerId = sessionStorage.getItem("offer_id");
  const dispatch = useDispatch();
  const [policyCard, setPolicyCard] = useState(true);
  const [insuranceCard, setInsuranceCard] = useState(false);
  const [priceCard, setPriceCard] = useState(false);
  const [offerDetails, setOfferDetails] = useState([]);
  const [insuranceDetails, setInsuranceDetails] = useState([]);
  // const [providerDetails, setProviderDetails] = useState([]);
  const [quoteDetails, setQuoteDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [policyStatus, setPolicyStatus] = useState("");
  const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [cancellationNote, setCancellationNote] = useState("");
  const [orderId, setOrderId] = useState("");
  const [cancelTermLink, setCancelTermLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const pageName = sessionStorage.getItem("pageName");
  const mobile_number =
    pageName !== "clientDashboard"
      ? sessionStorage.getItem("phone")
      : sessionStorage.getItem("customerPhNumber");
  // console.log(mobile_number, "mobile_number", pageName, "pageName");
  const order_id = sessionStorage.getItem("orderId");
  const navigate = useNavigate();
  let renew_policy_url;
  let claim_url;
  let payment_url;

  useEffect(() => {
    setShowLoader(true);

    const fetchData = async (retryCount = 0) => {
      if (retryCount >= 3) {
        console.log(
          "Maximum retry limit reached. Unable to get desired response."
        );
        return;
      }
      const payload = {
        txnId: txnId,
      };
      setTimeout(() => {
        dispatch(creditStatusCheck(payload)).then((res) => {
          if (res?.payload?.current_action === "ON_CONFIRM") {
            dispatch(insuranceOfferDetails({ txnId: txnId, offerId: offerId }))
              .then((offerResponse) => {
                if (
                  offerResponse?.payload?.transaction_details
                    ?.current_action === "ON_CONFIRM" ||
                  "ON_UPDATE" ||
                  "ON_UPDATE_RENEW" ||
                  "ON_UPDATE_CLAIMS" ||
                  "ON_UPDATE_RENEW_PMT"
                ) {
                  // console.log("offer", offerResponse?.payload);
                  sessionStorage.setItem(
                    "offer_id",
                    offerResponse?.payload?.offer_list[0]?.offer_details
                      ?.offer_item_id
                  );
                  setPolicyStatus(
                    offerResponse?.payload?.offer_list[0]?.offer_details
                      ?.order_status
                  );
                  setOfferDetails(
                    offerResponse?.payload?.offer_list[0]?.offer_details
                  );
                  setInsuranceDetails(
                    offerResponse?.payload?.offer_list[0]?.provider_details
                  );
                  setQuoteDetails(
                    offerResponse?.payload?.offer_list[0]?.quote_details
                  );
                  sessionStorage.setItem(
                    "orderId",
                    offerResponse?.payload?.offer_list[0]?.offer_details
                      .order_id
                  );
                  setOrderId(
                    offerResponse?.payload?.offer_list[0]?.offer_details
                      .order_id
                  );
                  setShowLoader(false);
                  if (
                    offerResponse?.payload?.offer_list[0]?.offer_details
                      ?.error_details?.error_code !== null
                  ) {
                    setErrorMessage(
                      offerResponse?.payload?.offer_list[0]?.offer_details
                        ?.error_details?.error_message
                    );
                    setShowSnackbar(true);
                  }
                } else {
                  console.log("Not ON_CONFIRM"); // Make the API call again with incremented retryCount
                  fetchData(retryCount + 1);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            console.log("Not ON_CONFIRM");
            fetchData(retryCount + 1);
          }
        });
      }, 5000);
    };
    fetchData();
  }, []);

  const checkCancelPolicyStatus = async (retryCount = 0) => {
    setShowLoader(true);
    if (retryCount >= 10) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setShowLoader(false);
      return;
    }
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId }))
        .then((statusResponse) => {
          if (statusResponse?.payload?.current_action === "ON_CANCEL") {
            console.log("ON_CANCEL");
            dispatch(
              insuranceOfferDetails({ txnId: txnId, offerId: offerId })
            ).then((offerResponse) => {
              setPolicyStatus(
                offerResponse?.payload?.offer_list[0]?.offer_details
                  ?.order_status
              );
              setOfferDetails(
                offerResponse?.payload?.offer_list[0]?.offer_details
              );
              setInsuranceDetails(
                offerResponse?.payload?.offer_list[0]?.provider_details
              );
              setQuoteDetails(
                offerResponse?.payload?.offer_list[0]?.quote_details
              );

              sessionStorage.setItem(
                "orderId",
                offerResponse?.payload?.offer_list[0]?.offer_details?.order_id
              );
              setOrderId(
                offerResponse?.payload?.offer_list[0]?.offer_details?.order_id
              );
              if (
                offerResponse?.payload?.offer_list[0]?.offer_details
                  ?.error_details?.error_code !== null
              ) {
                setErrorMessage(
                  offerResponse?.payload?.offer_list[0]?.offer_details
                    ?.error_details?.error_message
                );
                setShowSnackbar(true);
              }
              setShowLoader(false);
            });
          } else {
            console.log("Not ON_CANCEL"); // Make the API call again with incremented retryCount
            checkCancelPolicyStatus(retryCount + 1);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, 5000);
  };

  const handleCurrentStatus = () => {
    setIsRotating(true);
    setShowLoader(true);
    console.log("Current Status");
    dispatch(insuranceStatus({ txnId: txnId, offerId: offerId })).then(
      (statusResponse) => {
        if (statusResponse?.payload?.ack_status === "ACK") {
          dispatch(
            insuranceOfferDetails({ txnId: txnId, offerId: offerId })
          ).then((offerResponse) => {
            setIsRotating(false);
            if (offerResponse?.payload) {
              setPolicyStatus(
                offerResponse?.payload?.offer_list[0]?.offer_details
                  ?.order_status
              );
              setOfferDetails(
                offerResponse?.payload?.offer_list[0]?.offer_details
              );
              setInsuranceDetails(
                offerResponse?.payload?.offer_list[0]?.provider_details
              );
              setQuoteDetails(
                offerResponse?.payload?.offer_list[0]?.quote_details
              );
              sessionStorage.setItem(
                "orderId",
                offerResponse?.payload?.offer_list[0]?.offer_details?.order_id
              );
              setOrderId(
                offerResponse?.payload?.offer_list[0]?.offer_details?.order_id
              );
              if (
                offerResponse?.payload?.offer_list[0]?.offer_details
                  ?.error_details?.error_code !== null
              ) {
                setErrorMessage(
                  offerResponse?.payload?.offer_list[0]?.offer_details
                    ?.error_details?.error_message
                );
                setShowSnackbar(true);
              }
            }
            setShowLoader(false);
          });
        } else {
          setIsRotating(false);
          setErrorMessage("Error while fetching status. Please try again");
          setShowLoader(false);
          setShowSnackbar(true);
        }
      }
    );
  };

  const fetchPaymentStatus = async (retryCount = 0) => {
    setShowLoader(true);
    if (retryCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setShowLoader(false);
      return;
    }
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((res) => {
        if (res?.payload?.current_action === "ON_UPDATE_RENEW_PMT") {
          console.log("Desired response received");
          payment_url.close();
          setShowLoader(false);
          handleCurrentStatus();
        } else if (res?.payload?.current_action === "ON_REJECTED_RENEW_PMT") {
          payment_url.close();
          setShowLoader(false);
          // setOpenEventStatusDialog(true);
        } else {
          console.log("Not ON_UPDATE_RENEW_PMT");
          fetchPaymentStatus(retryCount + 1);
        }
      });
    }, 5000);
  };
  const fetchRenewStatus = async (retryCount = 0) => {
    setShowLoader(true);
    if (retryCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      return;
    }
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((res) => {
        if (res?.payload?.current_action === "ON_UPDATE_RENEW") {
          console.log("Desired response received");
          renew_policy_url.close();
          dispatch(
            insuranceFormUrl({
              txnId: txnId,
              offerId: offerId,
              formType: "RENEWAL_PAYMENT_LINK",
            })
          ).then((paymentUrlRes) => {
            if (paymentUrlRes?.payload?.form_url) {
              payment_url = window.open(paymentUrlRes?.payload?.form_url);
              fetchPaymentStatus();
            }
          });
          // handleCurrentStatus();
        } else if (res?.payload?.redirection_status === "ON_REJECTED_RENEW") {
          renew_policy_url.close();
          setShowLoader(false);
          // setOpenEventStatusDialog(true);
        } else {
          console.log("Not ON_UPDATE_RENEW");
          fetchRenewStatus(retryCount + 1);
        }
      });
    }, 5000);
  };

  const fetchClaimStatus = async (retryCount = 0) => {
    setShowLoader(true);
    if (retryCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setShowLoader(false);
      return;
    }
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((res) => {
        if (res?.payload?.current_action === "ON_UPDATE_CLAIMS") {
          console.log("Desired response received");
          claim_url.close();
          setShowLoader(false);
          handleCurrentStatus();
        } else if (res?.payload?.redirection_status === "ON_REJECTED_RENEW") {
          claim_url.close();
          setShowLoader(false);
          // setOpenEventStatusDialog(true);
        } else {
          console.log("Not ON_UPDATE_CLAIMS");
          fetchClaimStatus(retryCount + 1);
        }
      });
    }, 5000);
  };
  const handleRenewPolicy = () => {
    offerDetails?.documents.map((doc) => {
      if (doc.descriptor.name === "Renew Insurance Policy") {
        renew_policy_url = window.open(doc.url, "_blank");
        fetchRenewStatus();
      }
    });
  };
  const handlePolicyClaim = () => {
    offerDetails?.documents.map((doc) => {
      if (doc.descriptor.name === "Claim Insurance Policy") {
        claim_url = window.open(doc.url, "_blank");
        fetchClaimStatus();
      }
    });
  };
  const handleCancelPolicy = () => {
    dispatch(insuranceTC({ orderId: orderId })).then((res) => {
      if (res?.payload[0]?.external_ref?.url)
        setCancelTermLink(res?.payload[0]?.external_ref?.url);
    });
    setOpenPolicyDialog(true);
  };

  const handleFinish = () => {
    setShowLoader(true);
    const payload = {
      order_id: order_id,
      channel: "whatsapp",
      mobile_number: mobile_number,
    };
    // console.log(payload);
    dispatch(sendNotification(payload)).then((res) => {
      setShowLoader(false);
    });

    pageName !== "clientDashboard"
      ? navigate("/home/dashboard")
      : navigate("/user-dashboard");
  };

  const handlePolicy = () => {
    offerDetails?.documents.map((doc) => {
      if (doc.descriptor.name === "Insurance Policy Document") {
        window.open(doc.url, "_blank");
      }
    });
  };
  const handleCancelPolicyDialog = () => {
    const payload = {
      txnId: txnId,
      offerId: offerId,
      cancellationId: selectedReason,
      note: cancellationNote,
    };
    dispatch(insuranceCancel(payload)).then((res) => {
      // console.log(res);
      if (res?.payload[0]?.ack_status === "ACK") {
        checkCancelPolicyStatus();
      }
      setOpenPolicyDialog(false);
    });
  };
  const handleClosePolicyDialog = () => {
    setOpenPolicyDialog(false);
  };

  const handleCancellationReason = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleNoteChange = (e) => {
    setCancellationNote(e.target.value);
  };

  const onSnackbarClose = () => {
    setShowSnackbar(false);
  };

  return (
    <>
      <CustomLoader open={showLoader} />
      <CustomSnackbar
        message={errorMessage || "Something went wrong"}
        open={showSnackbar}
        status={"error"}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Dialog
        open={openPolicyDialog}
        // TransitionComponent={Transition}
        // keepMounted
        onClose={handleClosePolicyDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Cancel Insurance
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClosePolicyDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Cancellation Reason</InputLabel>
            <Select
              value={selectedReason}
              label="Cancellation Reason"
              onChange={handleCancellationReason}
            >
              <MenuItem value="5">5</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Cancellation Note"
            value={cancellationNote}
            onChange={handleNoteChange}
            style={{ marginBottom: "20px" }}
            required
            fullWidth
          />
          {cancelTermLink && (
            <a
              href={cancelTermLink}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              *terms apply
            </a>
          )}
        </div>
        <DialogActions style={{ marginBottom: "10px" }}>
          <Button variant="contained" onClick={handleClosePolicyDialog}>
            Discard
          </Button>
          <Button variant="contained" onClick={handleCancelPolicyDialog}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <OrderPageWrapper>
        <div style={{ textAlign: "right" }}>
          <p>
            <Button variant="outlined" onClick={handleCurrentStatus}>
              Get Current Status &nbsp;
              {isRotating ? (
                <CircularProgress
                  color="inherit"
                  size={20}
                  style={{ verticalAlign: "middle" }}
                />
              ) : (
                <RefreshIcon style={{ verticalAlign: "middle" }} />
              )}
            </Button>
          </p>
        </div>
        <ExpandableCard
          title="Policy Details"
          expanded={policyCard}
          setExpanded={setPolicyCard}
        >
          {offerDetails && (
            <Box
              sx={{ width: "100%", maxWidth: 680, bgcolor: "background.paper" }}
            >
              <nav aria-label="main mailbox folders">
                <List>
                  <>
                    <ListItem>
                      <ListItem> Order ID</ListItem>
                      <ListItem> {offerDetails?.order_id} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Status</ListItem>
                      <ListItem> {policyStatus} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Premium Amount</ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails?.premium_amount?.currency}{" "}
                        {offerDetails?.premium_amount?.value}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Coverage Amount </ListItem>
                      <ListItem> {offerDetails["COVERAGE_AMOUNT"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Co Payment </ListItem>
                      <ListItem> {offerDetails["CO_PAYMENT"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Room Rent Cap </ListItem>
                      <ListItem> {offerDetails["ROOM_RENT_CAP"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Room Category </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["ROOM_CATEGORY"]
                          ? offerDetails["ROOM_CATEGORY"].split("_").join(" ")
                          : ""}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Restoration Benifit </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["RESTORATION_BENEFIT"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Claim Settlement Ratio </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["CLAIM_SETTLEMENT_RATIO"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Pre Hospitalization Coverage Days </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["PRE_HOSPITALIZATION_COVERAGE_DAYS"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Post Hospitalization Coverage Days</ListItem>
                      <ListItem>
                        {" "}
                        {
                          offerDetails["POST_HOSPITALIZATION_COVERAGE_DAYS"]
                        }{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Maternity Coverage </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["MATERNITY_COVERAGE"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Initial Waiting Period </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["INITIAL_WAITING_PERIOD"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Cashless Hospitals </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["CASHLESS_HOSPITALS"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Base Price </ListItem>
                      <ListItem> {offerDetails["BASE_PRICE"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Convience Fee </ListItem>
                      <ListItem> {offerDetails["CONVIENCE_FEE"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Processing Fee </ListItem>
                      <ListItem> {offerDetails["PROCESSING_FEE"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Tax </ListItem>
                      <ListItem> {offerDetails["TAX"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Offer Validity </ListItem>
                      <ListItem> {offerDetails["OFFER_VALIDITY"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Claim Status </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails?.fulfillment_status?.["claim_status"]
                          ?.split("_")
                          .join(" ")}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Renewal Status </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails?.fulfillment_status?.["renewal_status"]
                          ?.split("_")
                          .join(" ")}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Order Status </ListItem>
                      <ListItem>
                        {" "}
                        {
                          offerDetails?.fulfillment_status?.["order_status"]
                        }{" "}
                      </ListItem>
                    </ListItem>
                  </>
                </List>
              </nav>
            </Box>
          )}
        </ExpandableCard>
        <br />
        <ExpandableCard
          title="Insurer Details"
          expanded={insuranceCard}
          setExpanded={setInsuranceCard}
        >
          {insuranceDetails && (
            <Box
              sx={{ width: "100%", maxWidth: 680, bgcolor: "background.paper" }}
            >
              <nav aria-label="main mailbox folders">
                <List>
                  <>
                    <ListItem key="0">
                      <ListItem> Name </ListItem>
                      <ListItem> {insuranceDetails["name"]} </ListItem>
                    </ListItem>
                    <ListItem key="1">
                      <ListItem> Long Description </ListItem>
                      <ListItem> {insuranceDetails["long_desc"]} </ListItem>
                    </ListItem>
                    <ListItem key="2">
                      <ListItem> Short Description </ListItem>
                      <ListItem> {insuranceDetails["short_desc"]} </ListItem>
                    </ListItem>
                  </>
                </List>
              </nav>
            </Box>
          )}
        </ExpandableCard>
        <br />
        <ExpandableCard
          title="Price Details"
          expanded={priceCard}
          setExpanded={setPriceCard}
        >
          {quoteDetails?.breakup && (
            <Box
              sx={{ width: "100%", maxWidth: 680, bgcolor: "background.paper" }}
            >
              <nav aria-label="main mailbox folders">
                <List>
                  {Object.entries(quoteDetails?.breakup).map(([key, value]) => (
                    <ListItem key={key}>
                      <ListItem> {value.title.split("_").join(" ")} </ListItem>
                      <ListItem>
                        {" "}
                        {value.price.currency} {value.price.value}{" "}
                      </ListItem>
                    </ListItem>
                  ))}
                  <hr />
                  <ListItem>
                    <ListItem> TOTAL PRICE</ListItem>
                    <ListItem>
                      {" "}
                      {quoteDetails?.price?.currency}{" "}
                      {quoteDetails?.price?.value}{" "}
                    </ListItem>
                  </ListItem>
                </List>
              </nav>
            </Box>
          )}
        </ExpandableCard>
        <br />
        <div style={{}}>
          <Button onClick={handleRenewPolicy} variant="contained">
            Renew
          </Button>
          &nbsp;&nbsp;
          <Button onClick={handlePolicyClaim} variant="contained">
            Claim
          </Button>
          &nbsp;&nbsp;
          <Button onClick={handleCancelPolicy} variant="contained">
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button onClick={handleFinish} variant="contained">
            Finish
          </Button>
          &nbsp;&nbsp;
          <Button onClick={handlePolicy} variant="contained">
            Policy
          </Button>
        </div>
      </OrderPageWrapper>
    </>
  );
};

export default InsurerPolicyDetails;
