import React, { useContext, useEffect, useState } from "react";
import {
  creditCustomerDetails,
  creditPayment,
  creditPaymentStatus,
  creditStatusCheck,
  getCreditOffers,
  sendNotification,
} from "../../../slice/creditForm.slice";
import { useDispatch } from "react-redux";
import {
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  styled,
  IconButton,
  FormControl,
  Card,
  CardContent,
  TablePagination,
  CircularProgress,
  Grid,
  Typography,
  CssBaseline,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Stack,
  Dialog,
  DialogActions,
} from "@mui/material";
import Button from "@mui/material/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import ExpandableCard from "../../../components/CustomComponents/ExpandableCard";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import CustomSnackbar from "../../../components/CustomComponents/CustomSnackbar";
import SpeedDialTooltipOpen from "../../../components/CustomComponents/CustomSpeedDial";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../../utils/context";

const PageHeader = styled("div")(({ theme }) => ({
  margin: "20px 0px 20px 0px",
  [theme.breakpoints.down("sm")]: {
    margin: "20px 0px 10px 20px",
  },
}));

const OrderPageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "10px 20px",
  overflowY: "scroll",
  scrollbarWidth: "none",
  "&": {
    fontFamily: "Source Sans Pro",
  },
}));

const OrderDetails = () => {
  const { setActiveStep } = useContext(MyContext);
  const txnId = sessionStorage.getItem("txnId");
  const offerId = sessionStorage.getItem("offer_id");
  const user_role = sessionStorage.getItem("user_role");
  const roles = sessionStorage.getItem("roles");
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = useState(false);
  const [loanDetails, setLoanDetails] = useState(false);
  const [emiDetails, setEmiDetails] = useState(false);
  const [amountDetails, seAmountDetails] = useState(false);
  const [repaymentType, setRepaymentType] = useState("PRE_PART_PAYMENT");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [offerDetails, setOfferDetails] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  // const [providerDetails, setProviderDetails] = useState([]);
  const [quoteDetails, setQuoteDetails] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showLoader, setShowLoader] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [openEventStatusDialog, setOpenEventStatusDialog] = useState(false);
  const navigate = useNavigate();

  const fetchOffers = async () => {
    dispatch(getCreditOffers({ txnId: txnId })).then((offerResponse) => {
      if (offerResponse?.payload) {
        const cusId = offerResponse?.payload?.transaction_details?.customer_id;
        dispatch(creditCustomerDetails(cusId)).then((res) => {
          setUserDetail(res?.payload);
        });

        setOfferDetails(offerResponse?.payload?.offer_list[0]?.offer_details);
        setPaymentDetails(
          offerResponse?.payload?.offer_list[0]?.payment_details
        );
        setQuoteDetails(offerResponse?.payload?.offer_list[0]?.quote_details);
        setOrderId(
          offerResponse?.payload?.offer_list[0]?.offer_details.order_id
        );
        sessionStorage.setItem(
          "orderId",
          offerResponse?.payload?.offer_list[0]?.offer_details.order_id
        );
        if (
          offerResponse?.payload?.offer_list[0]?.payment_details?.type ===
          "POST_FULFILLMENT"
        ) {
          setPaymentAmount(
            parseInt(
              offerResponse?.payload?.offer_list[0]?.payment_details[0]?.params
                ?.amount
            )
          );
        } else
          setPaymentAmount(
            parseInt(
              offerResponse?.payload?.offer_list[0]?.payment_details[1]?.params
                ?.amount
            )
          );
        setShowLoader(false);
      } else {
        console.log("Failed to fetch offer details");
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    setActiveStep(6);
    setShowLoader(true);
    setOpenEventStatusDialog(false);
    const fetchOfferDetails = async (retryCount = 0) => {
      if (retryCount >= 10) {
        console.log(
          "Maximum retry limit reached. Unable to get desired response."
        );
        setOpenEventStatusDialog(true);
        return;
      }
      // console.log(userDetail);
      setTimeout(() => {
        dispatch(creditStatusCheck({ txnId: txnId })).then((res) => {
          if (res?.payload?.current_action === "ON_CONFIRM") {
            // console.log("Desired response received:");
            fetchOffers();
            setShowLoader(false);
            // Call the API immediately when the component mounts
            const interval = setInterval(() => {
              fetchOffers();
              // Call the API every 2 minutes
            }, 2 * 60 * 1000);
            // 2 minutes in milliseconds
            return () => clearInterval(interval);
            // Clear the interval when the component unmounts
          } else {
            console.log("Not ON_CONFIRM");
            fetchOfferDetails(retryCount + 1);
          }
        });
      }, 5000);
    };
    fetchOfferDetails();
  }, []);

  const fetchOfferDetails = async (retryCount = 0) => {
    setShowLoader(true);
    setOpenEventStatusDialog(false);
    if (retryCount >= 2) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setOpenEventStatusDialog(true);
      return;
    }
    // console.log(userDetail);
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((res) => {
        if (res?.payload?.current_action === "ON_CONFIRM") {
          // console.log("Desired response received:");
          fetchOffers();
          setShowLoader(false);
          // Call the API immediately when the component mounts
          const interval = setInterval(() => {
            fetchOffers();
            // Call the API every 2 minutes
          }, 2 * 60 * 1000);
          // 2 minutes in milliseconds
          return () => clearInterval(interval);
          // Clear the interval when the component unmounts
        } else {
          console.log("Not ON_CONFIRM");
          fetchOfferDetails(retryCount + 1);
        }
      });
    }, 5000);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - paymentDetails.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAmountChange = (event) => {
    setPaymentAmount(parseInt(event.target.value));
  };
  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleCurrentStatus = () => {
    setIsRotating(true);
    setShowLoader(true);
    console.log("Current Status");
    const statusPayload = {
      txnId: txnId,
      offerId: offerId,
    };
    dispatch(creditPaymentStatus(statusPayload)).then((statusResponse) => {
      if (statusResponse?.payload) {
        // setTimeout(() => {
        dispatch(getCreditOffers({ txnId: txnId })).then((offerResponse) => {
          setIsRotating(false);
          if (offerResponse?.payload) {
            setOfferDetails(
              offerResponse?.payload?.offer_list[0]?.offer_details
            );
            setPaymentDetails(
              offerResponse?.payload?.offer_list[0]?.payment_details
            );
            setQuoteDetails(
              offerResponse?.payload?.offer_list[0]?.quote_details
            );
            setOrderId(
              offerResponse?.payload?.offer_list[0]?.offer_details.order_id
            );
            if (
              offerResponse?.payload?.offer_list[0]?.payment_details?.type ===
              "POST_FULFILLMENT"
            ) {
              setPaymentAmount(
                parseInt(
                  offerResponse?.payload?.offer_list[0]?.payment_details[0]
                    ?.params?.amount
                )
              );
            } else
              setPaymentAmount(
                parseInt(
                  offerResponse?.payload?.offer_list[0]?.payment_details[1]
                    ?.params?.amount
                )
              );
          }
        });
        // }, 2000);
      }
      setShowLoader(false);
    });
  };

  const handleRepaymentChange = (e) => {
    setRepaymentType(e.target.value);
  };

  const handleSubmitRepayment = (e) => {
    console.log("Form submission");
    setShowLoader(true);
    let payload;
    if (repaymentType === "PRE_PART_PAYMENT") {
      payload = {
        txn_id: txnId,
        order_id: orderId,
        label: repaymentType,
        amount: paymentAmount,
      };
    } else {
      payload = {
        txn_id: txnId,
        order_id: orderId,
        label: repaymentType,
        amount: 0,
      };
    }
    dispatch(creditPayment(payload)).then((res) => {
      setTimeout(() => {
        dispatch(getCreditOffers({ txnId: txnId })).then((offerResponse) => {
          if (offerResponse?.payload?.offer_list[0]?.payment_details[0]?.url) {
            window.open(
              offerResponse?.payload?.offer_list[0]?.payment_details[0]?.url,
              "_blank"
            );
          } else {
            console.log("Payment type not supported");
            setShowSnackbar(true);
            setPaymentMessage("Payment type not supported");
          }
          setShowLoader(false);
        });
      }, 6000);
    });
  };

  const handleSmsSending = () => {
    setShowLoader(true);
    const payload = {
      order_id: orderId,
      channel: "whatsapp",
    };
    dispatch(sendNotification(payload)).then((res) => {
      setShowLoader(false);
    });
    if (user_role === "guest") {
      navigate("/loan/credit-form");
    } else {
      navigate("/user-dashboard");
    }
  };

  const onSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const actions = [
    {
      icon: <HelpOutlineIcon />,
      name: "Raise Issues",
      actions: () =>
        navigate(
          user_role === "guest" ? "/loan/issue-status" : "/issue-status"
        ),
    },
    {
      icon: isRotating ? (
        <CircularProgress
          color="inherit"
          size={20}
          style={{ verticalAlign: "middle" }}
        />
      ) : (
        <RefreshIcon style={{ verticalAlign: "middle" }} />
      ),
      name: "GET CURRENT STATUS",
      actions: () => handleCurrentStatus(),
    },
  ];

  return (
    <>
      <CustomLoader open={showLoader} />
      <CustomSnackbar
        message={paymentMessage || "Something went wrong"}
        open={showSnackbar}
        status={"error"}
        onClose={onSnackbarClose}
      />

      <OrderPageWrapper>
        <PageHeader>
          <Typography variant="h4" fontSize={36}>
            {userDetail?.customer_details?.name || "Customer Name"}
          </Typography>
          <Typography variant="subtitle1" fontSize={15} color={"#5A5A5A"}>
            ID: {userDetail?.customer_details?.id || "###"}
          </Typography>
        </PageHeader>
        <CssBaseline />
        <Box>
          <Box
            sx={{
              height: "200px",
              width: "100%",
              backgroundColor: "white",
              marginBottom: "30px",
              padding: "30px 30px",
              overflowY: "scroll",
              marginRight: "25px",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Typography>Gender</Typography>
                <Typography sx={{ fontWeight: "800" }}>
                  {userDetail?.customer_details?.gender || ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>Date of Birth</Typography>
                <Typography sx={{ fontWeight: "800" }}>
                  {userDetail?.customer_details?.dob || ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>Email Address</Typography>
                <Typography sx={{ fontWeight: "800" }}>
                  {userDetail?.customer_details?.email || ""}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography>Order Id</Typography>
                <Typography sx={{ fontWeight: "800" }}>
                  {orderId || "###"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>Principal</Typography>
                <Typography sx={{ fontWeight: "800" }}>
                  {quoteDetails ? quoteDetails["PRINCIPAL"] : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>Interest</Typography>
                <Typography sx={{ fontWeight: "800" }}>
                  {quoteDetails ? quoteDetails["INTEREST"] : ""}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ExpandableCard
          title="Order Details"
          expanded={orderDetails}
          setExpanded={setOrderDetails}
        >
          {quoteDetails && (
            <Box
              sx={{ width: "100%", maxWidth: 680, bgcolor: "background.paper" }}
            >
              <nav aria-label="main mailbox folders">
                <List>
                  <ListItem>
                    <ListItem> Order ID </ListItem>
                    <ListItem> {orderId} </ListItem>
                  </ListItem>
                  <>
                    <ListItem key="0">
                      <ListItem> Principal </ListItem>
                      <ListItem> {quoteDetails["PRINCIPAL"]} </ListItem>
                    </ListItem>
                    <ListItem key="1">
                      <ListItem> Interest </ListItem>
                      <ListItem> {quoteDetails["INTEREST"]} </ListItem>
                    </ListItem>
                    <ListItem key="2">
                      <ListItem> Net Disbursed Amount </ListItem>
                      <ListItem>
                        {" "}
                        {quoteDetails["NET_DISBURSED_AMOUNT"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem key="3">
                      <ListItem> Other Upfront Charges </ListItem>
                      <ListItem>
                        {" "}
                        {quoteDetails["OTHER_UPFRONT_CHARGES"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem key="4">
                      <ListItem> Insurance Charges </ListItem>
                      <ListItem> {quoteDetails["INSURANCE_CHARGES"]} </ListItem>
                    </ListItem>
                    <ListItem key="5">
                      <ListItem> Other Charges </ListItem>
                      <ListItem> {quoteDetails["OTHER_CHARGES"]} </ListItem>
                    </ListItem>
                    <ListItem key="6">
                      <ListItem> Processing Fee </ListItem>
                      <ListItem> {quoteDetails["PROCESSING_FEE"]} </ListItem>
                    </ListItem>
                    <ListItem key="7">
                      <ListItem> Final Price </ListItem>
                      <ListItem> {quoteDetails["quote_price"]} </ListItem>
                    </ListItem>
                  </>
                </List>
              </nav>
            </Box>
          )}
        </ExpandableCard>
        <br />
        <ExpandableCard
          title="Loan Details"
          expanded={loanDetails}
          setExpanded={setLoanDetails}
        >
          {offerDetails && (
            <Box
              sx={{ width: "100%", maxWidth: 680, bgcolor: "background.paper" }}
            >
              <nav aria-label="main mailbox folders">
                <List>
                  <>
                    <ListItem>
                      <ListItem> Annual Percentage Rate </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["ANNUAL_PERCENTAGE_RATE"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Application Fee </ListItem>
                      <ListItem> {offerDetails["APPLICATION_FEE"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Cool Off Period </ListItem>
                      <ListItem> {offerDetails["COOL_OFF_PERIOD"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Delay Penalty Fee </ListItem>
                      <ListItem> {offerDetails["DELAY_PENALTY_FEE"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Foreclouser Fee </ListItem>
                      <ListItem> {offerDetails["FORECLOSURE_FEE"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Installment Amount </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["INSTALLMENT_AMOUNT"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Interest Rate </ListItem>
                      <ListItem> {offerDetails["INTEREST_RATE"]} </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Interest Rate Conversion Charge </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["INTEREST_RATE_CONVERSION_CHARGE"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Interest Rate Type </ListItem>
                      <ListItem>
                        {" "}
                        {offerDetails["INTEREST_RATE_TYPE"]}{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Number of Installments of Repayment </ListItem>
                      <ListItem>
                        {" "}
                        {
                          offerDetails["NUMBER_OF_INSTALLMENTS_OF_REPAYMENT"]
                        }{" "}
                      </ListItem>
                    </ListItem>
                    <ListItem>
                      <ListItem> Loan Status </ListItem>
                      <ListItem> {offerDetails["loan_status"]} </ListItem>
                    </ListItem>
                  </>
                </List>
              </nav>
            </Box>
          )}
        </ExpandableCard>
        <br />
        <ExpandableCard
          title="EMI Details"
          expanded={emiDetails}
          setExpanded={setEmiDetails}
        >
          {paymentDetails && (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">ID</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(paymentDetails.length > 0 && rowsPerPage > 0
                      ? paymentDetails.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : paymentDetails
                    )?.map(
                      (offer, index) =>
                        offer?.type === "POST_FULFILLMENT" && (
                          <>
                            <TableRow key={index}>
                              <TableCell align="left">{offer?.id}</TableCell>
                              <TableCell align="left">
                                {offer?.params?.amount}
                              </TableCell>

                              <TableCell align="left">
                                {offer?.time?.range
                                  ? new Date(
                                      offer?.time?.range?.start
                                    ).toLocaleDateString()
                                  : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {(offer?.status).split("-").join(" ")}
                              </TableCell>
                              <TableCell align="left">
                                {(offer?.time?.label).split("_").join(" ")}
                              </TableCell>
                            </TableRow>
                          </>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                component="div"
                colSpan={3}
                count={paymentDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </>
          )}
        </ExpandableCard>
        <br />
        <br />

        <Card style={{ marginBottom: "25px" }}>
          <CardContent>
            <h3>Select Repayment Type</h3>
            <Grid container padding={"10px 10px"} marginLeft={5} gap={3}>
              <Grid
                item
                xs={12}
                md={3.5}
                sx={{
                  height: "auto",
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  marginRight: "10px",
                  padding: "10px 5px",
                }}
              >
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    value={repaymentType}
                    onChange={handleRepaymentChange}
                  >
                    <FormControlLabel
                      value="PRE_PART_PAYMENT"
                      name="PRE_PART_PAYMENT"
                      control={<Radio />}
                      label="Part Payment"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={3.5}
                sx={{
                  height: "auto",
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  marginRight: "10px",
                  padding: "10px 5px",
                }}
              >
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    value={repaymentType}
                    onChange={handleRepaymentChange}
                  >
                    <FormControlLabel
                      value="FORECLOSURE"
                      name="FORECLOSURE"
                      control={<Radio />}
                      label="Foreclosure"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={3.5}
                sx={{
                  height: "auto",
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  marginRight: "10px",
                  padding: "10px 5px",
                }}
              >
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    value={repaymentType}
                    onChange={handleRepaymentChange}
                  >
                    <FormControlLabel
                      value="MISSED_EMI_PAYMENT"
                      name="MISSED_EMI_PAYMENT"
                      control={<Radio />}
                      label="Missed Payment"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5} padding={0}>
                <FormControl fullWidth>
                  {repaymentType === "PRE_PART_PAYMENT" && (
                    <>
                      <FormLabel>Amount</FormLabel>
                      <TextField
                        value={paymentAmount}
                        onChange={handleAmountChange}
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        placeholder="Enter an Amount"
                        fullWidth
                      />
                    </>
                  )}
                  <span style={{ color: "red", alignContent: "center" }}>
                    *amount is inclusive of late payment fine
                  </span>
                </FormControl>
              </Grid>
            </Grid>
            <Stack flexDirection={"row"} justifyContent={"flex-end"}>
              <Button
                onClick={handleSmsSending}
                variant="contained"
                color="primary"
              >
                Finish
              </Button>{" "}
              &nbsp;&nbsp;
              <Button
                type="submit"
                onClick={handleSubmitRepayment}
                variant="contained"
                color="primary"
              >
                Pay
              </Button>{" "}
              &nbsp;&nbsp;
            </Stack>
          </CardContent>
        </Card>
        <SpeedDialTooltipOpen
          actions={actions}
          setOpen={setSpeedDialOpen}
          open={speedDialOpen}
        />
      </OrderPageWrapper>
      {/* ERROR DIALOG*/}
      <Dialog
        open={openEventStatusDialog}
        keepMounted
        // onClose={handleCloseConsentDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "10px" }}
          >
            <Button variant="contained" onClick={() => fetchOfferDetails()}>
              Retry
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default OrderDetails;
