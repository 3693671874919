import React, { useState, useEffect, useContext, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Divider,
  Button,
  Breadcrumbs,
  Typography,
  Link,
  TextField,
  InputAdornment,
  Box,
  DialogContent,
  Stack,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import SortIcon from "@mui/icons-material/Sort";
import CachedIcon from "@mui/icons-material/Cached";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  creditSelectOffer,
  creditStatusCheck,
  getaaUrl,
  getCreditOffers,
  getItemDetails,
  selectConsent,
  submitCreditForm,
  getApprovedOffers,
} from "../../../slice/creditForm.slice";
import ViewOfferDetails from "./section/viewOfferDetails";
import CustomizeOfferDetails from "./section/customizeOfferDetails";
import { MyContext } from "../../../utils/context";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import CustomTimer from "../../../components/CustomComponents/CustomTimer";
import CustomSnackbar from "../../../components/CustomComponents/CustomSnackbar";
import themePalette from "../../../styles/themePalette";

const SelectLoanOfferBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  margin: "20px 0px ",
}));

const SelectLoanOfferContainer = styled("div")(({ theme }) => ({
  padding: "0px 20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  overflowY: "auto",
  minHeight: "300px",
  scrollbarWidth: "none",
  [theme.breakpoints.down("sm")]: {
    width: 380,
    padding: "0px",
  },
}));

const SelectBoxContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: 0,
  border: "1px solid #9E9E9E",
  padding: theme.spacing(2),
  borderRadius: "5px",
  minWidth: "300px",
  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  [theme.breakpoints.down("sm")]: {
    minWidth: "320px",
    padding: theme.spacing(1),
  },
}));

const SelectBoxHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

const SelectBoxDetails = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const DetailRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: theme.spacing(1),
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  margin: theme.spacing(0, 2),
}));

const ScrollContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  overflowX: "auto",
  scrollBehavior: "smooth",
  maxWidth: "100%",
  boxSizing: "border-box",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const SmallScreenContainer = styled(FormControl)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: "350px",
    display: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
      overflowX: "auto",
      scrollBehavior: "smooth",
    },
  },
}));

const useStyles = {
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  planeImage: {
    width: "50px",
    height: "50px",
    transition: "transform 1s ease-in-out",
  },
  planeImageFlying: {
    transform: "translateX(250px) rotate(-20deg)",
  },
};

export default function UAOffersPage() {
  const navigate = useNavigate();
  const { setActiveStep } = useContext(MyContext);
  const location = useLocation();
  const rowId = location.state?.rowId || null;
  const isResume = location.state?.isResume || false;
  const [searchText, setSearchText] = useState("");
  const txnId = sessionStorage.getItem("txnId");
  let handle_aa_url;
  const contactNumber = location.state?.contactNumber || null;
  const user_role = sessionStorage.getItem("user_role");
  const [showLoader, setShowLoader] = useState(false);
  const [offers, setOffers] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [radioSelected, setRadioSelected] = useState(null);
  const [newOfferValues, setNewOfferValues] = useState({});
  const [duration, setDuration] = useState(15);
  const [onReload, setOnReload] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const initialShowTimer =
    sessionStorage.getItem("showTimer") === "true" ? true : false;
  const [getAAOffers, setGetAAOffers] = useState(false);
  const [isFlying, setIsFlying] = useState(false);
  const [aaURL, setAAURL] = useState("");
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [showTimer, setShowTimer] = useState(initialShowTimer);
  const [openDialog, setOpenDialog] = useState({
    offerDetailsDetails: false,
    customizeOfferDetails: false,
  });
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState({
    message: "",
    Status: "",
  });
  const retryCountRef = useRef(0);
  const retryIntervalRef = useRef(null);

  useEffect(() => {
    // Update localStorage whenever showTimer changes
    sessionStorage.setItem("showTimer", showTimer);
  }, [showTimer]);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredLoanOffers = offers.filter((offer) =>
    offer.provider_details.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleContinue = (offer) => {
    sessionStorage.setItem("selected_offer", JSON.stringify(offer));
    navigate("/loan/customize-offers");
  };

  const scrollTo = (index) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft =
        scrollRef.current.children[index].offsetLeft;
      setCurrentSlide(index);
    }
  };

  const next = () => {
    if (currentSlide < filteredLoanOffers.length - 1) {
      scrollTo(currentSlide + 1);
    }
  };

  const previous = () => {
    if (currentSlide > 0) {
      scrollTo(currentSlide - 1);
    }
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, children } = scrollRef.current;
      const newIndex = Array.from(children).findIndex(
        (child) => child.offsetLeft >= scrollLeft
      );
      setCurrentSlide(newIndex);
    }
  };

  useEffect(() => {
    const ref = scrollRef.current;
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
      return () => {
        ref.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleInputChange = (event, offer) => {
    event.preventDefault();
    setSelectedOffer(offer);
    setRadioSelected(true);
  };

  const formatNumber = (num) => {
    const parsedNum = parseFloat(num);
    return parsedNum % 1 === 0 ? parsedNum.toFixed(0) : parsedNum.toFixed(2);
  };

  useEffect(() => {
    const maxRetries = 6;

    const fetchOffers = () => {
      setShowLoader(true);

      if (!txnId) {
        console.log("txnId is not available");
        setShowLoader(false);
        return;
      }

      const makeApiCall = () => {
        const api = getAAOffers
          ? getCreditOffers({ txnId })
          : getApprovedOffers({ txnId });
        const ApiCall = isResume ? getItemDetails({ rowId }) : api;

        dispatch(ApiCall)
          .then((res) => {
            const offers = res?.payload?.offer_list || [];
            if (offers.length > 0) {
              setOffers(offers);
              console.log("getCreditOffers response:", res?.payload);
              setShowTimer(false);
              setShowLoader(false);
              clearInterval(retryIntervalRef.current);
            } else if (retryCountRef.current < maxRetries) {
              retryCountRef.current += 1;
              console.log(
                `Payload is null, retrying in 10 seconds... Attempt ${retryCountRef.current}`
              );
            } else {
              console.log("Maximum retry attempts reached. Stopping retries.");
              setShowLoader(false);
              clearInterval(retryIntervalRef.current);
            }
          })
          .catch((e) => {
            console.log("Error in getCreditOffers:", e);
            setShowLoader(false);
            clearInterval(retryIntervalRef.current);
          });
      };

      retryIntervalRef.current = setInterval(() => {
        makeApiCall();
      }, 10000); // Call the API every 10 seconds
    };

    fetchOffers();

    // Cleanup interval on component unmount
    return () => {
      clearInterval(retryIntervalRef.current);
    };
  }, [dispatch, txnId, onReload]);

  const handleCustomizeOffer = (offer) => {
    setSelectedOffer(offer);
    setOpenDialog((prevState) => ({
      ...prevState,
      customizeOfferDetails: true,
    }));
  };

  const handleViewDetails = (offer) => {
    setSelectedOffer(offer);
    setOpenDialog((prevState) => ({
      ...prevState,
      offerDetailsDetails: true,
    }));
  };

  const handleDialogClose = () => {
    setOpenDialog({
      offerDetailsDetails: false,
      customizeOfferDetails: false,
    });
  };

  const handleSaveChanges = () => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) => {
        if (
          offer.provider_details.name === selectedOffer.provider_details.name
        ) {
          const updatedOffer = {
            ...offer,
            quote_details: {
              ...offer.quote_details,
              SelectedLoanAmount: newOfferValues.LOAN_AMOUNT,
            },
          };

          return updatedOffer;
        }
        return offer;
      })
    );
    setOpenDialog(false);
  };

  /* Sorting Offers */
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortOffers = () => {
    setOffers((prevOffers) =>
      [...prevOffers].sort((a, b) => {
        let comparison = 0;

        if (sortField === "interest_rate") {
          comparison =
            formatNumber(a.offer_details.INTEREST_RATE) -
            formatNumber(b.offer_details.INTEREST_RATE);
        } else if (sortField === "loan_amount") {
          comparison =
            (a.quote_details?.SelectedLoanAmount ||
              a.quote_details?.PRINCIPAL) -
            (b.quote_details?.SelectedLoanAmount || b.quote_details?.PRINCIPAL);
        } else if (sortField === "term") {
          comparison =
            formatNumber(a.offer_details.TERM) -
            formatNumber(b.offer_details.TERM);
        }

        return sortOrder === "asc" ? comparison : -comparison;
      })
    );
  };

  /* Sorting Offers */

  const handleDialogSubmit = (value) => {
    if (value === "YES") {
      setIsFlying(true);
      setTimeout(() => {
        setConfirmationDialog(false);
        setIsFlying(false);
        handle_aa_url = window.open(aaURL, "_blank");
      }, 1500);
      fetchTransactionStatus();
    } else {
      setShowLoader(false);
      setConfirmationDialog(false);
      return;
    }
  };

  const fetchTransactionStatus = async (retryCount = 0) => {
    if (retryCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      return;
    }

    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId })).then((res) => {
        if (res?.payload?.redirection_status === "AA_APPROVED") {
          console.log("Desired response received:");
          handle_aa_url.close();
          const consentPayload = {
            txnId: txnId,
          };
          dispatch(selectConsent(consentPayload)).then((consentResponse) => {
            setShowLoader(false);
            setGetAAOffers(true);
            setDuration(60);
            setOnReload(!onReload);
            sessionStorage.setItem("showTimer", true);
          });
        } else if (res?.payload?.redirection_status === "AA_REJECTED") {
          handle_aa_url.close();
          setConfirmationDialog(true);
        } else {
          console.log("Not AA_APPROVED");
          fetchTransactionStatus(retryCount + 1);
        }
      });
    }, 5000);
  };

  const handleAAVerification = () => {
    setShowLoader(true);
    const get_AA_url = () => {
      const payload = {
        txnId: txnId,
        userId: `${contactNumber}@finvu`,
      };
      dispatch(getaaUrl(payload)).then((aaUrlResponse) => {
        if (aaUrlResponse?.payload?.aa_url !== null) {
          if (aaUrlResponse?.payload?.aa_url === "AA_flow_not_required") {
            setShowLoader(false);
            setShowSnackbar(true);
            setSnackBarMsg({
              message: "Couldn`t Find More Offers For You!",
              Status: "success",
            });
            return;
          } else if (
            aaUrlResponse?.payload?.aa_url !== "AA_flow_not_required"
          ) {
            setShowSnackbar({});
            setShowSnackbar(false);
            setAAURL(aaUrlResponse?.payload?.aa_url);
            setConfirmationDialog(true);
            sessionStorage.setItem("showTimer", true);
          }
        } else {
          console.log("aa_url null");
          setShowLoader(false);
        }
      });
    };
    setTimeout(get_AA_url, 1000);
  };

  return (
    <>
      <CustomSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        message={snackBarMsg?.message}
        status={snackBarMsg?.Status}
      />
      <SelectLoanOfferBody>
        {!showTimer ? (
          <CustomLoader open={showLoader} />
        ) : (
          <CustomTimer
            open={showTimer}
            onClose={() => setShowLoader(false)}
            setShowTimer={setShowTimer}
          />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0px",
          }}
        >
          <Typography variant="h4">Available Offers</Typography>
        </Box>
        <Stack direction={"column"} gap={3} sx={{ marginBottom: "30px" }}>
          <TextField
            value={searchText}
            onChange={handleSearch}
            placeholder="Search by bank name"
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Button
              variant="outlined"
              size="small"
              sx={{ height: "40px" }}
              onClick={() => setOpenSortDialog(true)}
              startIcon={<SortIcon />}
            >
              Sort
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ height: "40px" }}
              onClick={() => setOnReload(!onReload)}
              startIcon={<CachedIcon />}
            >
              Reload
            </Button>
          </Stack>
        </Stack>
        <SelectLoanOfferContainer>
          <SmallScreenContainer component="fieldset" viewType={"mobile"}>
            <RadioGroup>
              {filteredLoanOffers && filteredLoanOffers?.length > 0 ? (
                <>
                  <ScrollContainer ref={scrollRef}>
                    {filteredLoanOffers.map((offer, index) => (
                      <SelectBoxContainer key={index}>
                        <SelectBoxHeader>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {offer.provider_details?.images?.[0]?.url && (
                              <img
                                src={offer.provider_details.images[0].url}
                                style={{ height: "24px", marginRight: "10px" }}
                                alt={"img"}
                              />
                            )}
                            <Typography
                              fontFamily={"Plus Jakarta Sans Bold"}
                              fontSize={16}
                              sx={{ textTransform: "initial" }}
                            >
                              {offer.provider_details.name}
                            </Typography>
                          </div>
                          <div>
                            <FormControlLabel
                              value={offer.offer_details.offer_item_id}
                              onChange={(e) => handleInputChange(e, offer)}
                              control={<Radio />}
                              label=""
                              style={{ margin: 0 }}
                            />
                          </div>
                        </SelectBoxHeader>
                        <Divider
                          style={{
                            width: "100%",
                            margin: "5px 0px",
                          }}
                        />
                        <SelectBoxDetails>
                          <DetailRow>
                            <Typography
                              fontFamily={"Source Sans Pro"}
                              color={"#5A5A5A"}
                              fontSize={16}
                            >
                              Loan Amount
                            </Typography>
                            <Typography
                              fontFamily={"Source Sans Pro"}
                              fontSize={16}
                            >
                              Rs.{" "}
                              {formatNumber(offer.quote_details.PRINCIPAL) ||
                                ""}
                            </Typography>
                          </DetailRow>
                          <DetailRow>
                            <Typography
                              fontFamily={"Source Sans Pro"}
                              color={"#5A5A5A"}
                              fontSize={16}
                            >
                              Interest Rate
                            </Typography>
                            <Typography
                              fontFamily={"Source Sans Pro"}
                              fontSize={16}
                            >
                              {formatNumber(
                                offer.offer_details.INTEREST_RATE
                              ) || ""}{" "}
                              %
                            </Typography>
                          </DetailRow>
                          <DetailRow>
                            <Typography
                              fontFamily={"Source Sans Pro"}
                              color={"#5A5A5A"}
                              fontSize={16}
                            >
                              Tenure
                            </Typography>
                            <Typography
                              fontFamily={"Source Sans Pro"}
                              fontSize={16}
                            >
                              {formatNumber(offer.offer_details.TERM) || ""}{" "}
                              Months
                            </Typography>
                          </DetailRow>
                          <DetailRow>
                            <Typography
                              fontFamily={"Source Sans Pro"}
                              color={"#5A5A5A"}
                              fontSize={16}
                            >
                              Installment Amount
                            </Typography>
                            <Typography
                              fontFamily={"Source Sans Pro"}
                              fontSize={16}
                            >
                              Rs.{" "}
                              {formatNumber(
                                offer.offer_details.INSTALLMENT_AMOUNT
                              ) || ""}{" "}
                              /Month
                            </Typography>
                          </DetailRow>
                        </SelectBoxDetails>
                        <Divider
                          style={{
                            width: "100%",
                            margin: "5px 0px",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            width: "100%",
                          }}
                        >
                          <ViewOfferDetails
                            offer={selectedOffer}
                            handleDialogButton={() => handleViewDetails(offer)}
                            open={openDialog.offerDetailsDetails}
                            setOpen={handleDialogClose}
                            pageName={"offerPage"}
                          />
                          {/* <Divider orientation="vertical" flexItem />
                          <CustomizeOfferDetails
                            offer={selectedOffer}
                            setNewOfferValues={setNewOfferValues}
                            handleDialogButton={() =>
                              handleCustomizeOffer(offer)
                            }
                            handleButton={handleSaveChanges}
                            open={openDialog.customizeOfferDetails}
                            setOpen={handleDialogClose}
                          /> */}
                        </Box>
                      </SelectBoxContainer>
                    ))}
                  </ScrollContainer>
                  {filteredLoanOffers?.length > 0 && (
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection={"row"}
                      gap={2}
                      m={0}
                      sx={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <ButtonContainer
                        disabled={currentSlide === 0}
                        onClick={previous}
                      >
                        <KeyboardArrowLeftIcon />
                      </ButtonContainer>

                      <Typography variant="body2" color="textSecondary">
                        {currentSlide + 1} / {filteredLoanOffers.length}
                      </Typography>

                      <ButtonContainer
                        disabled={currentSlide >= filteredLoanOffers.length - 1}
                        onClick={next}
                      >
                        <ChevronRightIcon />
                      </ButtonContainer>
                    </Stack>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" color="textSecondary">
                    Please try reloading the page by clicking the reload button.
                  </Typography>
                </Box>
              )}
            </RadioGroup>
          </SmallScreenContainer>
        </SelectLoanOfferContainer>
        <ButtonContainer>
          <Button
            variant="outlined"
            onClick={() => handleAAVerification()}
            sx={{
              mx: 1,
              textTransform: "none",
              fontFamily: "Plus Jakarta Sans, bold",
            }}
          >
            Get More Offers
          </Button>
          <Button
            variant="contained"
            disabled={!radioSelected && !selectedOffer}
            onClick={() => handleContinue(selectedOffer)}
            sx={{
              backgroundColor: themePalette.primary,
              textTransform: "none",
              fontFamily: "Plus Jakarta Sans, bold",
              mx: 1,
            }}
          >
            Continue
          </Button>
        </ButtonContainer>
      </SelectLoanOfferBody>
      <Dialog open={openSortDialog} sx={{ p: "5px 10px" }}>
        <DialogTitle
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ minWidth: 300 }}
        >
          <Box flex={1}>
            <Typography variant="h6">Sort Offers</Typography>
          </Box>
          <IconButton onClick={() => setOpenSortDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider orientation="horizontal" />
        <DialogContent>
          <Typography variant="body1">Sort offers based on:</Typography>
          <RadioGroup
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
          >
            <FormControlLabel
              value="interest_rate"
              control={<Radio />}
              label="Interest Rate"
            />
            <FormControlLabel
              value="loan_amount"
              control={<Radio />}
              label="Loan Amount"
            />
            <FormControlLabel value="term" control={<Radio />} label="Term" />
          </RadioGroup>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Order:
          </Typography>
          <RadioGroup
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            row
          >
            <FormControlLabel
              value="asc"
              control={<Radio />}
              label="Ascending"
            />
            <FormControlLabel
              value="desc"
              control={<Radio />}
              label="Descending"
            />
          </RadioGroup>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Button variant="contained" onClick={handleSortOffers}>
              Apply Sort
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogContent style={useStyles.dialogContent}>
          <img
            src="https://pngfre.com/wp-content/uploads/Airplane-4.png"
            alt="Plane"
            style={{
              ...useStyles.planeImage,
              ...(isFlying ? useStyles.planeImageFlying : {}),
            }}
          />
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            Please don't exit or press back.
            <br />
            Please wait while we are redirecting you to the Verification Page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogSubmit("YES")}>Proceed</Button>
          {/* <Button onClick={() => handleDialogSubmit("NO")}>No</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
