import { combineReducers } from "redux";
import creditFormSlice from "../slice/creditForm.slice";
import navbarSlice from "../pages/AuthorizedPages/Navbar/navbar.slice";
import authReducer from "../app/authUser.slice";
// import insuranceSlice from "../pages/InsuranceRegistration/insurance.slice";

const rootReducer = combineReducers({
  auth: authReducer,
  SearchCredit: navbarSlice,
  SubmitCredit: creditFormSlice,
  // submitInsurance: insuranceSlice,
});

export default rootReducer;
