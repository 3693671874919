import React, { useEffect, useState } from "react";
import {
  Avatar,
  styled,
  Typography,
  Box,
  Grid,
  Divider,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import { getApprovedOffers } from "../../../slice/credit.slice";
// import { AuthContext } from "../../context/AuthProvider";

const OfferCardContainer = styled(Card)(({ theme }) => ({
  padding: "20px",
  margin: "20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  overflowY: "auto",
  scrollbarWidth: "thin",
  [theme.breakpoints.down("sm")]: {
    minWidth: 300,
  },
}));

const SelectBoxHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

const SelectBoxDetails = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const SelectBoxContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: theme.spacing(3),
  border: "1px solid #9E9E9E",
  padding: theme.spacing(2),
  borderRadius: "5px",
  minWidth: "350px",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: 280,
    padding: theme.spacing(1),
  },
}));

const DetailsHeaderContainer = styled("div")(({ theme }) => ({
  "&": {
    backgroundColor: theme.palette.primaryWhite,
    padding: theme.spacing(5, 6),
    marginBottom: "10px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  ".details-header": {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  ".details-avatar-container": {
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  ".details-Patientdetails": {
    padding: theme.spacing(0, 6),
    borderRight: `1px solid ${theme.palette.primaryGrey}`,
    [theme.breakpoints.down("md")]: {
      display: "inline",
      borderRight: "0",
    },
  },
  ".details-emailContainer": {
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("sm")]: {
      display: "inline",
      marginBottom: "10px",
    },
  },
  ".details-subContainer": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  ".details-patient-name": {
    "&.MuiTypography-root": theme.typography.h3,
  },
  ".details-patient-id": {
    "&.MuiTypography-root": theme.typography.body4,
  },
  "details-patient-email": {
    "&.MuiTypography-root": theme.typography.body3,
  },
  displayDocuments: {
    display: "block",
  },
  ".documents-subContainer": {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block ",
    },
  },
}));

const DetailRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: theme.spacing(1),
}));

const ConsentDocsContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 2),
}));

const FiltersContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primaryWhite,
  padding: theme.spacing(4),
}));

const SubHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.primaryWhite,
  padding: theme.spacing(0),
}));

const UserDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user_details"));
  const location = useLocation();
  const customerDetails = location?.state?.customerDetails;
  const userId = user?.id;
  const [showLoader, setShowLoader] = useState(false);
  const [offerDetails, setOfferDetails] = React.useState([]);
  const [showUserOffers, setShowUserOffers] = React.useState(false);
  const [filter, setFilter] = useState("CustomerOffers");
  // console.log(user);

  useEffect(() => {
    setShowLoader(true);
    let payload;
    user?.user_role === "customer"
      ? (payload = {
          userId: userId,
        })
      : (payload = {
          customerId: customerDetails?.id,
        });
    dispatch(getApprovedOffers(payload))
      .then((res) => {
        setShowLoader(false);
        if (res?.payload) {
          const response = res?.payload;
          console.log("response", response);
          setOfferDetails(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showUserOffers]);

  const handleCardClick = (offer, txnId) => {
    const currentAction = offer?.offer_details?.offer_current_action;
    const rowId = offer?.offer_details?.offer_row_id;
    console.log("offerDetail:", offer, "for txnId:", txnId);
    sessionStorage.setItem("txnId", txnId);
    if (currentAction === "ON_SELECT_CST") {
      navigate("/loan/credit-form", { state: { resumeState: 1 } });
    } else if (currentAction === "ON_SELECT_LOAN_AMT") {
      navigate("/loan/loan-offers", {
        state: { rowId: rowId, isResume: true },
      });
    } else if (currentAction === "ON_SELECT_KYC") {
      navigate("/loan/kyc-page", {
        state: { resumeState: 0, isResume: true },
      });
    } else if (currentAction === "ON_INIT_ACC_DTL") {
      navigate("/loan/bank-details", {
        state: { resumeState: 1, isResume: true },
      });
    } else if (currentAction === "ON_INIT_EMNDT") {
      navigate("/loan/emandate-page", {
        state: { resumeState: 2, isResume: true },
      });
    } else if (currentAction === "ON_CONFIRM") {
      navigate("/loan/order-details");
    }
  };

  const formatNumber = (num) => {
    const parsedNum = parseFloat(num);
    return parsedNum % 1 === 0 ? parsedNum.toFixed(0) : parsedNum.toFixed(2);
  };

  // const handleFilterChange = (event) => {
  //   const value = event.target.value;
  //   // setFilter(value);
  //   // if (value === "all") {
  //   //   setTxnTableData(formattedConsentList);
  //   // } else if (value === "Credit") {
  //   //   setTxnTableData(
  //   //     formattedConsentList.filter((item) => item.domain === "ONDC:FIS12")
  //   //   );
  //   // } else if (value === "Insurance") {
  //   //   setTxnTableData(
  //   //     formattedConsentList.filter((item) => item.domain === "ONDC:FIS13")
  //   //   );
  //   // }
  //   setFilter(value);
  //   if (value === "UserOffers") {
  //     setShowUserOffers(false);
  //   } else if (value === "CustomerOffers") {
  //     setShowUserOffers(true);
  //   }
  // };

  return (
    <>
      <ConsentDocsContainer>
        <CustomLoader open={showLoader} />
        <DetailsHeaderContainer>
          <div className="details-header">
            <div className="details-avatar-container">
              <Avatar />
            </div>
            <div className="details-Patientdetails">
              <Typography className="details-patient-name">
                {user?.name}
              </Typography>
              <div className="details-subContainer">
                <Typography className="details-patient-id">
                  {user?.email}
                </Typography>
              </div>
            </div>
            <div className="details-emailContainer">
              <Typography className="details-patient-email">
                {user?.dob}
              </Typography>
              <Typography className="details-patient-email">
                {user?.mobile_number}
              </Typography>
            </div>
          </div>
        </DetailsHeaderContainer>
        <br />

        <div className="documents-subContainer" style={{ margin: "20px" }}>
          <SubHeaderContainer>
            <Typography
              variant="h4"
              m={3}
              fontWeight={"bold"}
              fontFamily={"Red Hat Display"}
              flex={1}
            >
              Transaction List
            </Typography>
            <DetailsHeaderContainer type={"subHeader"}>
              <div className="details-header">
                <div className="details-Patientdetails">
                  <Typography className="details-patient-name">
                    {customerDetails?.name}
                  </Typography>
                  <div className="details-subContainer">
                    <Typography className="details-patient-id">
                      {customerDetails?.user_role}
                    </Typography>
                  </div>
                </div>
                <div className="details-emailContainer">
                  <Typography className="details-patient-email">
                    {customerDetails?.dob}
                  </Typography>
                  <Typography className="details-patient-email">
                    {customerDetails?.mobile_number}
                  </Typography>
                </div>
              </div>
            </DetailsHeaderContainer>
          </SubHeaderContainer>
          {/* <FiltersContainer>
            <FormControl variant="outlined" style={{ width: 150 }}>
              <InputLabel>Filter Offers</InputLabel>
              <Select
                value={filter}
                onChange={handleFilterChange}
                label="Filter Offers"
              >
                <MenuItem value="UserOffers">User Offers</MenuItem>
                <MenuItem value="CustomerOffers">Customer offers</MenuItem>
              </Select>
            </FormControl>
          </FiltersContainer> */}
        </div>

        <OfferCardContainer>
          <Grid container spacing={1}>
            {offerDetails ? (
              offerDetails?.map((offerItem) =>
                offerItem?.offer_list?.map((offer, index) => (
                  <Grid item xs={12} md={5.8} lg={3.8} key={index}>
                    <SelectBoxContainer
                      onClick={() => {
                        const txnId = offerItem?.transaction_details?.txn_id;
                        handleCardClick(offer, txnId);
                      }}
                    >
                      <SelectBoxHeader>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {offer.provider_details.images[0].url && (
                            <img
                              src={offer.provider_details.images[0].url}
                              style={{ height: "40px", marginRight: "10px" }}
                              alt=""
                            />
                          )}
                          <Typography fontSize={18} fontWeight={600}>
                            {offer.provider_details.name}
                          </Typography>
                        </div>
                      </SelectBoxHeader>
                      <Divider style={{ width: "100%", margin: "10px 0" }} />
                      <SelectBoxDetails>
                        <DetailRow>
                          <span>Loan Amount</span>
                          <span>
                            Rs.
                            {formatNumber(
                              offer?.quote_details?.SelectedLoanAmount ||
                                offer?.quote_details?.PRINCIPAL
                            ) || ""}
                          </span>
                        </DetailRow>
                        <DetailRow>
                          <span>Interest Rate</span>
                          <span>
                            {formatNumber(offer.offer_details.INTEREST_RATE) ||
                              ""}{" "}
                            %
                          </span>
                        </DetailRow>
                        <DetailRow>
                          <span>Tenure</span>
                          <span>
                            {formatNumber(offer.offer_details.TERM) || ""}{" "}
                            Months
                          </span>
                        </DetailRow>
                        <DetailRow>
                          <span>Installment Amount</span>
                          <span>
                            Rs.
                            {formatNumber(
                              offer.offer_details.INSTALLMENT_AMOUNT
                            ) || ""}
                            /Month
                          </span>
                        </DetailRow>
                      </SelectBoxDetails>
                    </SelectBoxContainer>
                  </Grid>
                ))
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  Please try reloading the page by clicking the reload button.
                </Typography>
              </Box>
            )}
          </Grid>
        </OfferCardContainer>
      </ConsentDocsContainer>
    </>
  );
};

export default UserDashboard;
