import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Autocomplete,
  FormHelperText,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { State, City } from "country-state-city";

const PersonalDetailsContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ErrorMessageBox = styled(FormHelperText)(({ theme }) => ({
  margin: "4px 0",
}));

export default function PersonalDetailsForm({
  formData,
  updateFormData,
  error,
  activeStep,
  setActiveStep,
  checkError,
  setShowSnackbar,
}) {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const handleContinue = () => {
    const hasError = checkError(activeStep);
    if (!hasError) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
    } else {
      setShowSnackbar(true);
    }
  };

  useEffect(() => {
    setStates(State.getStatesOfCountry("IN"));
  }, []);

  useEffect(() => {
    if (formData && formData?.state) {
      const selectedState = states.find(
        (state) => state.name === formData?.state
      );
      if (selectedState) {
        setCities(City.getCitiesOfState("IN", selectedState.isoCode));
      }
    }
  }, [formData?.state, states]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedFormData = { ...formData, [name]: value };

    // If the contactNumber field is updated, update the aa_id field as well
    if (name === "contactNumber") {
      const removeZero = value.replace(/^0+/, "");
      updatedFormData = {
        ...updatedFormData,
        aa_id: removeZero + "@finvu",
        contactNumber: removeZero,
      };
    }
    // console.log(updatedFormData);

    updateFormData(updatedFormData);
  };

  const handleAutoCompleteChange = (event, value, name) => {
    let updatedFormData = { ...formData, [name]: value };

    // Reset city if state is updated
    if (name === "state") {
      updatedFormData = { ...updatedFormData, city: "" };
    }
    // console.log(updatedFormData);
    updateFormData(updatedFormData);
  };

  const handleRadioChange = (event) => {
    updateFormData({ ...formData, gender: event.target.value });
  };

  return (
    <PersonalDetailsContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h4">Personal Details</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.firstName && error}>
            <FormLabel>First Name</FormLabel>
            <TextField
              name="firstName"
              value={formData?.firstName}
              onChange={handleInputChange}
              placeholder="Enter First Name"
              error={!formData?.firstName && error}
            />
            {!formData?.firstName && error && (
              <ErrorMessageBox>Enter First Name</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.lastName && error}>
            <FormLabel>Last Name</FormLabel>
            <TextField
              name="lastName"
              value={formData?.lastName}
              onChange={handleInputChange}
              placeholder="Enter Last Name"
              error={!formData?.lastName && error}
            />
            {!formData?.lastName && error && (
              <ErrorMessageBox>Enter Last Name</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.dob && error}>
            <FormLabel>Date of Birth</FormLabel>
            <TextField
              name="dob"
              type="date"
              value={formData?.dob}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              placeholder="MM/DD/YYYY"
              error={!formData?.dob && error}
            />
            {!formData?.dob && error && (
              <ErrorMessageBox>Enter DOB</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.gender && error}>
            <FormLabel>Gender</FormLabel>
            <RadioGroup
              row
              value={formData?.gender}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
            {!formData?.gender && error && (
              <ErrorMessageBox>Enter Gender</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.email && error}>
            <FormLabel>Email</FormLabel>
            <TextField
              name="email"
              type="email"
              value={formData?.email}
              onChange={handleInputChange}
              placeholder="Enter Email"
              error={!formData?.email && error}
            />
            {!formData?.email && error && (
              <ErrorMessageBox>Enter email</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            required
            error={!formData?.contactNumber && error}
          >
            <FormLabel>Contact</FormLabel>
            <TextField
              name="contactNumber"
              value={formData?.contactNumber}
              onChange={handleInputChange}
              placeholder="Enter Contact Number"
              error={!formData?.contactNumber && error}
            />
            {!formData?.contactNumber && error && (
              <ErrorMessageBox>Enter Contact Number</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.endUse && error}>
            <FormLabel>End Use</FormLabel>
            <Autocomplete
              required
              value={formData?.endUse}
              options={[
                "Consumer Durable Purchase",
                "Education",
                "Travel",
                "Health",
                "Other",
              ]}
              getOptionLabel={(option) => option}
              onChange={(event, value) =>
                handleAutoCompleteChange(event, value, "endUse")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!formData?.endUse && error}
                />
              )}
            />
            {!formData?.endUse && error && (
              <ErrorMessageBox>Select End Use</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.addressL1 && error}>
            <FormLabel>Address 1</FormLabel>
            <TextField
              required
              name="addressL1"
              value={formData?.addressL1}
              onChange={handleInputChange}
              placeholder="Enter Address 1"
              error={!formData?.addressL1 && error}
            />
            {!formData?.addressL1 && error && (
              <ErrorMessageBox>Enter Address Line 1</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.addressL2 && error}>
            <FormLabel>Address 2</FormLabel>
            <TextField
              name="addressL2"
              value={formData?.addressL2}
              onChange={handleInputChange}
              placeholder="Enter Address 2"
              error={!formData?.addressL2 && error}
            />
            {!formData?.addressL2 && error && (
              <ErrorMessageBox>Enter Address Line 2</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.pan && error}>
            <FormLabel>PAN</FormLabel>
            <TextField
              name="pan"
              value={formData?.pan}
              onChange={handleInputChange}
              placeholder="Enter PAN Number"
              error={!formData?.pan && error}
            />
            {!formData?.pan && error && (
              <ErrorMessageBox>Enter PAN</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!formData?.state && error}>
              <FormLabel>State</FormLabel>
              <Autocomplete
                required
                value={
                  states.find((state) => state.name === formData?.state) || null
                }
                options={states}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) =>
                  handleAutoCompleteChange(
                    event,
                    value ? value.name : "",
                    "state"
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!formData?.state && error}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    required
                  />
                )}
              />
              {!formData?.state && error && (
                <ErrorMessageBox>Select State</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!formData?.city && error}>
              <FormLabel>City</FormLabel>
              <Autocomplete
                required
                value={
                  cities.find((city) => city.name === formData?.city) || null
                }
                options={cities}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) =>
                  handleAutoCompleteChange(
                    event,
                    value ? value.name : "",
                    "city"
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!formData?.city && error}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    required
                  />
                )}
                disabled={!formData?.state}
              />
              {!formData?.city && error && (
                <ErrorMessageBox>Select City</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.pincode && error}>
            <FormLabel>Pincode</FormLabel>
            <TextField
              error={!formData?.pincode && error}
              required
              name="pincode"
              type="number"
              value={formData?.pincode}
              onChange={handleInputChange}
              placeholder="Enter Pincode"
            />
            {!formData?.pincode && error && (
              <ErrorMessageBox>Enter Pincode</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Button variant="contained" onClick={handleContinue}>
          Continue
        </Button>
      </Box>
    </PersonalDetailsContainer>
  );
}
