import { Pagination, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

const CustomPagination = ({
  data,
  setPage,
  page,
  productsLength,
  numberOfItems,
}) => {
  const [numberOfPages, setNumberOfPages] = useState(1);

  // Define itemsPerPage here so it's accessible to the whole component
  const itemsPerPage = numberOfItems;

  console.log(itemsPerPage);

  useEffect(() => {
    const totalPages = Math.ceil(productsLength / itemsPerPage);
    setNumberOfPages(totalPages);
  }, [productsLength, itemsPerPage]); // Add itemsPerPage as a dependency

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Stack sx={{ margin: 2 }} justifyContent={"center"} alignItems={"center"}>
      <Pagination
        count={numberOfPages} // Total number of pages
        page={page} // Current page number
        onChange={handlePageChange} // Function to handle page changes
        color="secondary"
      />
    </Stack>
  );
};

export default CustomPagination;
