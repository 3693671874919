import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../utils/request";
import { apis } from "../utils/apis";

export const getAllCustomer = createAsyncThunk(
  "getAllCustomer",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.listAllCustomer}/${payload.userId}`
    );
    return response;
  }
);

export const getUserByUserRole = createAsyncThunk(
  "getUserByUserRole",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.getAllUserByUserRole}?user_role=${payload.userRole}`
    );
    return response;
  }
);

export const getAgentsByManagerID = createAsyncThunk(
  "getAgentsByManagerID",
  async (payload) => {
    const response = await apiRequest(
      "GET",
      `${apis?.getAgentsByManagerID}?manager_id=${payload.managerId}`
    );
    return response;
  }
);

export const getApprovedOffers = createAsyncThunk(
  "getApprovedOffers",
  async (payload) => {
    const param = payload?.userId
      ? `user_id=${payload?.userId}`
      : `customer_id=${payload?.customerId}`;
    const response = await apiRequest(
      "GET",
      `${apis?.getApprovedOffers}?${param}`
    );
    return response;
  }
);

const ClientSlice = createSlice({
  name: "clientSlice",
  initialState: {
    clientList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetSearchData: (state, value) => {
      state.clientList = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.creditList = action.payload;
      })
      .addCase(getAllCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
// export const { reducer } = ProductsSlice;
export default ClientSlice.reducer;
