import React, { useEffect, useState } from "react";
import { Avatar, Button, styled, Typography } from "@mui/material";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import { getUserByUserRole } from "../../../slice/credit.slice";
import CustomDataGrid from "../../../components/CustomComponents/CustomDataGrid";
import themePalette from "../../../styles/themePalette";

const DetailsHeaderContainer = styled("div")(({ theme }) => ({
  "&": {
    backgroundColor: theme.palette.primaryWhite,
    padding: theme.spacing(5, 6),
    // marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  ".details-header": {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  ".details-avatar-container": {
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  ".details-Patientdetails": {
    padding: theme.spacing(0, 6),
    // borderRight: `1px solid ${theme.palette.primaryGrey}`,
    [theme.breakpoints.down("md")]: {
      display: "inline",
      borderRight: "0",
    },
  },
  ".details-emailContainer": {
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("sm")]: {
      display: "inline",
      marginBottom: "10px",
    },
  },
  ".details-subContainer": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  ".details-patient-name": {
    "&.MuiTypography-root": theme.typography.h3,
  },
  ".details-patient-id": {
    "&.MuiTypography-root": theme.typography.body4,
  },
  "details-patient-email": {
    "&.MuiTypography-root": theme.typography.body3,
  },
  displayDocuments: {
    display: "block",
  },
  ".documents-subContainer": {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block ",
    },
  },
}));

const ConsentDocsContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 2),
}));

const SubHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  // marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primaryWhite,
  padding: theme.spacing(3, 0),
}));

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user_details"));
  const [showLoader, setShowLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [managerList, setManagerList] = useState([]);

  const handleRowClick = (row) => {
    navigate("/manager-dashboard", { state: { managerDetails: row } });
  };

  useEffect(() => {
    setShowLoader(true);
    const payload = { userRole: "manager" };
    dispatch(getUserByUserRole(payload)).then((res) => {
      const formattedTxnList = res?.payload?.map((item) => {
        const createdAt = format(item?.created_at, "dd-MM-yyyy hh:mm aaaaa'm'");
        const updatedAt = format(item?.updated_at, "dd-MM-yyyy hh:mm aaaaa'm'");
        return {
          createdAt: createdAt,
          updatedAt: updatedAt,
          ...item,
        };
      });

      const sortedManagerList = res?.payload?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });

      setManagerList(sortedManagerList);
      setTableData(formattedTxnList);
      setShowLoader(false);
    });
  }, []);

  const tableColumns = [
    { key: "id", header: "ID" },
    { key: "username", header: "Username" },
    { key: "name", header: "Name" },
    { key: "createdAt", header: "Registered On" },
  ];

  return (
    <>
      <ConsentDocsContainer>
        <CustomLoader open={showLoader} />
        <DetailsHeaderContainer>
          <div className="details-header">
            <div className="details-avatar-container">
              <Avatar />
            </div>
            <div className="details-Patientdetails">
              <Typography className="details-patient-name">
                {user?.name}
              </Typography>
              <div className="details-subContainer">
                <Typography className="details-patient-id">
                  {user?.user_role}
                </Typography>
              </div>
            </div>
            <div className="details-emailContainer">
              <Typography className="details-patient-email">
                {user?.dob}
              </Typography>
              <Typography className="details-patient-email">
                {user?.mobile_number}
              </Typography>
            </div>
          </div>
          <Button
            variant="contained"
            sx={{ backgroundColor: themePalette?.primary }}
            onClick={() =>
              navigate("/register-employee", { state: { managerList } })
            }
          >
            Register Employee
          </Button>
        </DetailsHeaderContainer>

        <div className="documents-subContainer">
          <SubHeaderContainer>
            <Typography
              variant="h4"
              m={3}
              fontWeight={"bold"}
              fontFamily={"Red Hat Display"}
              flex={1}
            >
              Managers List
            </Typography>
          </SubHeaderContainer>
        </div>
        {tableData?.length > 0 ? (
          <CustomDataGrid
            columns={tableColumns}
            rows={tableData}
            onRowClick={(row) => handleRowClick(row)}
            enableRowClick={true}
            rowId={(row) => row.id}
          />
        ) : (
          <Typography>No Data Found</Typography>
        )}
      </ConsentDocsContainer>
    </>
  );
};

export default AdminDashboard;
