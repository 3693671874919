import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import {
  creditInit,
  creditStatusCheck,
  submitCreditForm,
} from "../../../slice/creditForm.slice";
import { MyContext } from "../../../utils/context";
import CustomSnackbar from "../../../components/CustomComponents/CustomSnackbar";
import themePalette from "../../../styles/themePalette";

const BankDetailWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(5),
}));
const BankDetailHeader = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));
const BankFormWrapper = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
}));

const BankFormWrapperItem = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const RadioItemGroup = styled(Stack)(({ theme }) => ({
  width: "40%",
  height: "40px",
  padding: theme.spacing(2),
  marginRight: theme.spacing(4),
  marginTop: 4,
  display: "flex",
  flexDirection: "row",
  //   alignItems: "center",
  justifyContent: "flex-start",
  border: "1px solid #D2D2D2",
  borderRadius: "5px",
}));

const ErrorMessageBox = styled(FormHelperText)(({ theme }) => ({
  margin: "4px 0",
}));

const BankDetailsPage = () => {
  const [formData, setFormData] = useState({
    accHolderName: "",
    acctype: "",
    accNo: "",
    ifscCode: "",
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [re_num, setRe_num] = useState("");
  const [validateError, setValidateError] = useState(false);
  const [funcStep, setFuncStep] = useState(0);
  const [isMatched, setIsMatched] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const { activeStep, setActiveStep } = useContext(MyContext);
  const user_role = sessionStorage.getItem("user_role");
  const txnId = sessionStorage.getItem("txnId");
  const offerId = sessionStorage.getItem("offer_id");
  const [progress, setProgress] = useState(0);

  /* USE DISPATCH */
  const dispatch = useDispatch();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      acctype: value,
    }));
  };

  useEffect(() => {
    if (formData.accNo === re_num) {
      setIsMatched(false);
    } else {
      setIsMatched(true);
    }
  });

  const checkError = () => {
    if (
      !formData.accHolderName ||
      !formData.acctype ||
      !formData.accNo ||
      !formData.ifscCode ||
      formData.accNo !== re_num
    ) {
      setValidateError(true);
      return true;
    }
    return false;
  };

  const handleReNumChange = (event) => {
    setRe_num(event.target.value);
  };

  const handleSubmit = (retryCount = 0) => {
    const hasError = checkError(activeStep);
    if (hasError) {
      setShowSnackbar(true);
      return;
    }
    setShowLoader(true);
    const payload = {
      txnId: txnId,
      accountDetails: { ...formData, aaMonitoringConsent: "true" },
    };
    setProgress(progress + 70);
    const id = setInterval(() => {
      dispatch(creditStatusCheck(payload)).then((creditRes) => {
        // console.log(creditRes?.payload);
        if (creditRes?.payload?.current_action === "ON_INIT_ACC_DTL") {
          clearInterval(id);
          dispatch(submitCreditForm({ payload, txnId: txnId })).then((res) => {
            const formResponse = res?.payload;
            if (formResponse) {
              const hasSuccess = formResponse.some(
                (item) => item.form_status === "Success"
              );
              if (hasSuccess) {
                const initPayload = {
                  txnId: txnId,
                  offerId: offerId,
                };
                dispatch(creditInit(initPayload)).then((res) => {
                  if (res?.payload?.ack_status === "ACK") {
                    const nextStep = activeStep + 1;
                    setActiveStep(nextStep);
                    setProgress(progress + 30);
                    navigate("/loan/emandate-page");
                  } else {
                    console.warn("Error Occurred");
                  }
                  setShowLoader(false);
                });
              } else {
                setShowLoader(false);
              }
            }
          });
        } else {
          retryCount += 1;
          if (retryCount > 5) {
            clearInterval(id);
            setShowLoader(false);
          }
        }
      });
    }, 5000);
  };

  return (
    <>
      <CustomLoader open={showLoader} />
      <CustomSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        message={"Fill all the fields"}
        status={"warning"}
      />
      <BankDetailWrapper>
        <BankDetailHeader>
          <Typography
            sx={{
              fontSize: "1.43rem",
              fontWeight: 700,
              fontFamily: "plus jakarta sans bold",
            }}
          >
            Bank Details
          </Typography>
        </BankDetailHeader>
        <BankFormWrapper container>
          <BankFormWrapperItem sm={12} xs={12}>
            <FormControl
              fullWidth
              error={!formData.accHolderName && validateError}
            >
              <FormLabel
                sx={{
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "source sans pro",
                    fontSize: "1rem",
                  }}
                >
                  {" "}
                  Account Holder Name
                </Typography>
              </FormLabel>
              <TextField
                onChange={handleInputChange}
                name="accHolderName"
                value={formData.accHolderName}
                placeholder={"Enter Your Bank Name"}
                error={!formData.accHolderName && validateError}
              />
              {!formData.accHolderName && validateError && (
                <ErrorMessageBox>Enter Your Bank Name</ErrorMessageBox>
              )}
            </FormControl>
          </BankFormWrapperItem>
          <BankFormWrapperItem sm={12} xs={12} width={"100%"}>
            <FormControl fullWidth error={!formData.acctype && validateError}>
              <FormLabel>
                <Typography
                  sx={{
                    fontFamily: "source sans pro",
                    fontSize: "1rem",
                  }}
                >
                  {" "}
                  Account Type
                </Typography>
              </FormLabel>
              {/* <RadioGroup
                onChange={handleRadioChange}
                value={formData.acctype}
                row
              >
                <RadioItemGroup>
                  <FormControlLabel
                    sx={{ height: "100%", mr: 0, ml: "1px" }}
                    value="Savings"
                    name="Savings"
                    control={<Radio />}
                  />
                  <Stack justifyContent={"center"} alignItems={"center"}>
                    <Typography
                      sx={{ fontSize: "1rem", fontFamily: "source sans pro" }}
                    >
                      Saving
                    </Typography>
                  </Stack>
                </RadioItemGroup>
                <RadioItemGroup>
                  <FormControlLabel
                    sx={{ height: "100%", mr: 0, ml: "1px" }}
                    value="current"
                    name="current"
                    control={<Radio />}
                  />
                  <Stack justifyContent={"center"} alignItems={"center"}>
                    <Typography
                      sx={{ fontSize: "1rem", fontFamily: "source sans pro" }}
                    >
                      Current
                    </Typography>
                  </Stack>
                </RadioItemGroup>
              </RadioGroup> */}
              <RadioGroup
                onChange={handleRadioChange}
                value={formData.acctype}
                row
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "60px",
                    display: "flex",
                    flexDirection: { xs: "row", sm: "row" },
                    gap: { xs: 2, sm: 1 }, // Adds a gap between boxes
                    marginBottom: { xs: "10px", sm: "0px" },
                    mt: 1,
                  }}
                >
                  <Box
                    sx={{
                      flex: 1, // Allow boxes to flex and occupy equal space
                      border:
                        !formData.acctype && validateError
                          ? "1px solid red"
                          : "1px solid lightGray",
                      padding: "10px",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center", // Align content vertically in the center
                      justifyContent: "center", // Center the radio and label
                    }}
                  >
                    <FormControlLabel
                      value="Savings"
                      name="Savings"
                      control={<Radio />}
                      label="Savings"
                    />
                  </Box>

                  <Box
                    sx={{
                      flex: 1,
                      border:
                        !formData.acctype && validateError
                          ? "1px solid red"
                          : "1px solid lightGray",
                      padding: "10px",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FormControlLabel
                      value="Current"
                      name="Current"
                      control={<Radio />}
                      label="Current"
                    />
                  </Box>
                </Box>
              </RadioGroup>

              {!formData.acctype && validateError && (
                <ErrorMessageBox>Select Account Type</ErrorMessageBox>
              )}
            </FormControl>
          </BankFormWrapperItem>
          <BankFormWrapperItem sm={12} xs={12}>
            <FormControl fullWidth error={!formData.ifscCode && validateError}>
              <FormLabel
                sx={{
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "source sans pro",
                    fontSize: "1rem",
                  }}
                >
                  IFSC Code
                </Typography>
              </FormLabel>
              <TextField
                onChange={handleInputChange}
                name="ifscCode"
                value={formData.ifscCode}
                placeholder={"Enter Your IFSC Code"}
                error={!formData.ifscCode && validateError}
              />
              {!formData.ifscCode && validateError && (
                <ErrorMessageBox>Enter IFSC Number</ErrorMessageBox>
              )}
            </FormControl>
          </BankFormWrapperItem>
          <BankFormWrapperItem sm={12} xs={12}>
            <FormControl fullWidth error={!formData.accNo && validateError}>
              <FormLabel
                sx={{
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "source sans pro",
                    fontSize: "1rem",
                  }}
                >
                  Account Number
                </Typography>
              </FormLabel>
              <TextField
                onChange={handleInputChange}
                name="accNo"
                value={formData.accNo}
                placeholder={"Enter Your Account Number"}
                error={!formData.accNo && validateError}
              />
              {!formData.accNo && validateError && (
                <ErrorMessageBox>Enter Account Number</ErrorMessageBox>
              )}
            </FormControl>
          </BankFormWrapperItem>
          <BankFormWrapperItem sm={12} xs={12}>
            <FormControl fullWidth error={!re_num && validateError}>
              <FormLabel
                sx={{
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "source sans pro",
                    fontSize: "1rem",
                  }}
                >
                  Re-enter Account Number
                </Typography>
              </FormLabel>
              <TextField
                onChange={handleReNumChange}
                name="re_num"
                value={re_num}
                placeholder={"Enter Your Re-Account Number"}
                error={!re_num && validateError}
              />
              <Typography color={"red"}>
                {isMatched && re_num !== "" && "Not matched"}
              </Typography>
            </FormControl>
          </BankFormWrapperItem>
        </BankFormWrapper>
        <Stack mt={5} mb={5} justifyContent={"center"} alignItems={"flex-end"}>
          <Button
            sx={{
              backgroundColor: themePalette.primary,
              boxShadow: "none",
              textTransform: "none",
              marginBottom: 3,
              fontFamily: "Plus Jakarta Sans, bold",
            }}
            variant="contained"
            onClick={handleSubmit}
            size="medium"
          >
            Proceed
          </Button>
        </Stack>
      </BankDetailWrapper>
    </>
  );
};

export default BankDetailsPage;
