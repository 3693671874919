import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  styled,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  MenuItem,
  Select,
  Typography,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import {
  mapManagerToAgent,
  resetPassword,
  signUpEmployee,
} from "../../../app/authUser.slice";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import CustomSnackbar from "../../../components/CustomComponents/CustomSnackbar";
import { useLocation, useNavigate } from "react-router-dom";

const RegisterEmployeeWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(4),
  width: "100%",
  height: "70vh",
  backgroundColor: theme.palette.background.default,
}));

const RegisterEmployeeContainer = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  width: "100%",
  maxWidth: "400px",
  backgroundColor: "#fff",
  padding: theme.spacing(3),
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
}));

const ErrorMessageBox = styled(FormHelperText)(({ theme }) => ({
  margin: "4px 0",
  color: theme.palette.error.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  borderRadius: "8px",
  color: "#fff",
  textTransform: "none",
  fontFamily: "plus jakarta sans bold",
}));

const RegisterEmployeePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const managerList = location.state?.managerList || [];

  const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
  const [showLoader, setShowLoader] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    user_role: "",
    password: "1234",
  });

  const [managerid, setManagerId] = useState("");
  const [showSnackbar, setShowSnacker] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    message: "",
    status: "",
  });
  const [error, setError] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateFeild = () => {
    const { username, name, user_role } = formData;

    if (!username || !name || !user_role) {
      setError(true);
      return false;
    }

    if (
      userDetails?.user_role === "admin" &&
      formData.user_role === "agent" &&
      !managerid
    ) {
      setError(true);
      return false;
    }

    setError(false);
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFeild()) {
      setShowLoader(true);
      const payload = {
        ...formData,
      };
      if (userDetails?.user_role === "manager") {
        dispatch(signUpEmployee(payload)).then((res) => {
          if (res?.payload) {
            const newpayload = {
              manager_id:
                userDetails?.user_role === "manager" ? userDetails?.id : "",
              agent_id: res?.payload?.id,
            };
            dispatch(mapManagerToAgent(newpayload)).then((res) => {
              setShowLoader(false);
              setShowSnacker(true);
              setSnackbarMsg({
                message: "User Created Successfully !",
                status: "success",
              });
              setFormData({
                username: "",
                name: "",
                user_role: "",
                password: "1234",
              });
            });
            navigate(-1);
          }
        });
      } else if (
        userDetails?.user_role === "admin" &&
        formData?.user_role === "agent"
      ) {
        dispatch(signUpEmployee(payload)).then((res) => {
          if (res?.payload) {
            const newpayload = {
              manager_id: managerid,
              agent_id: res?.payload?.id,
            };
            dispatch(mapManagerToAgent(newpayload)).then((res) => {
              setShowLoader(false);
              setShowSnacker(true);
              setSnackbarMsg({
                message: "User Created Successfully !",
                status: "success",
              });
              setFormData({
                username: "",
                name: "",
                user_role: "",
                password: "1234",
              });
              navigate(-1);
            });
          }
        });
      } else if (
        userDetails?.user_role === "admin" &&
        formData?.user_role === "manager"
      ) {
        dispatch(signUpEmployee(payload)).then((res) => {
          setShowLoader(false);
          setShowSnacker(true);
          setSnackbarMsg({
            message: "User Created Successfully !",
            status: "success",
          });
          setFormData({
            username: "",
            name: "",
            user_role: "",
            password: "1234",
          });
          navigate(-1);
        });
      }
    }
  };

  return (
    <>
      <CustomLoader open={showLoader} />
      <CustomSnackbar
        open={showSnackbar}
        onClose={() => setShowSnacker(false)}
        status={snackbarMsg?.status}
        message={snackbarMsg?.message}
      />
      <RegisterEmployeeWrapper>
        <RegisterEmployeeContainer>
          <Stack gap={3}>
            <form onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                required
                error={!formData.name && error}
                sx={{ marginBottom: 3 }}
              >
                <FormLabel>Enter Name</FormLabel>
                <TextField
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter Name"
                  error={!formData.name && error}
                />
                {!formData.name && error && (
                  <ErrorMessageBox>Enter Name</ErrorMessageBox>
                )}
              </FormControl>
              <FormControl
                fullWidth
                required
                error={!formData?.username && error}
                sx={{ marginBottom: 3 }}
              >
                <FormLabel>Enter Username</FormLabel>
                <TextField
                  name="username"
                  type="text"
                  value={formData?.username}
                  onChange={handleInputChange}
                  placeholder="Enter Username"
                  error={!formData?.username && error}
                />
                {!formData?.username && error && (
                  <ErrorMessageBox>Username is required.</ErrorMessageBox>
                )}
              </FormControl>
              {userDetails?.user_role === "admin" ? (
                <FormControl
                  fullWidth
                  required
                  error={!formData.user_role && error}
                  sx={{ marginBottom: 3 }}
                >
                  <FormLabel>Enter User Role</FormLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.user_role || ""}
                    name="user_role"
                    fullWidth
                    onChange={handleInputChange}
                    error={!formData?.user_role && error}
                  >
                    <MenuItem value={"manager"}>Manager</MenuItem>
                    <MenuItem value={"agent"}>Agent</MenuItem>
                  </Select>
                  {/* Display an error message if the user doesn't have access */}
                </FormControl>
              ) : (
                <FormControl
                  fullWidth
                  required
                  error={!formData.user_role && error}
                  sx={{ marginBottom: 3 }}
                >
                  <FormLabel>Enter User Role</FormLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formData.user_role || ""}
                    name="user_role"
                    fullWidth
                    onChange={handleInputChange}
                    error={!formData?.user_role && error}
                  >
                    <MenuItem value={"agent"}>Agent</MenuItem>
                  </Select>
                </FormControl>
              )}
              {userDetails?.user_role !== "admin" &&
                userDetails?.user_role !== "manager" && (
                  <Typography color="error">
                    You don't have access to select roles
                  </Typography>
                )}
              {userDetails?.user_role === "admin" &&
                formData.user_role === "agent" && (
                  <FormControl
                    fullWidth
                    required
                    error={!managerid && error}
                    sx={{ marginBottom: 3 }}
                  >
                    <FormLabel>Select Manager you want assign</FormLabel>
                    <Autocomplete
                      disablePortal
                      options={managerList}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      fullWidth
                      onChange={(event, newValue) => {
                        setManagerId(newValue ? newValue.id : null);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    {!managerid && error && (
                      <ErrorMessageBox>
                        Selection of Manager is required.
                      </ErrorMessageBox>
                    )}
                  </FormControl>
                )}

              <StyledButton type="submit" variant="contained" fullWidth>
                Register
              </StyledButton>
            </form>
          </Stack>
        </RegisterEmployeeContainer>
      </RegisterEmployeeWrapper>
    </>
  );
};

export default RegisterEmployeePage;
