import React, { createContext, useRef, useState } from "react";

const MyContext = createContext(null);

const MyProvider = ({ children }) => {
  const aboutUsRef = useRef(null);
  const productOfferingsRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [auth, setAuth] = useState({});
  const [environment, setEnvironment] = useState("PREPROD");
  const [showLoader, setShowLoader] = useState(false);

  return (
    <MyContext.Provider
      value={{
        aboutUsRef,
        productOfferingsRef,
        activeStep,
        setActiveStep,
        auth,
        setAuth,
        environment,
        setEnvironment,
        setShowLoader,
        showLoader,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyProvider };
