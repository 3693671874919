import React, { useContext, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  FormLabel,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slide from "@mui/material/Slide";
import { creditStatusCheck } from "../../slice/creditForm.slice";
import {
  insuranceConfirm,
  insuranceFormUrl,
  insuranceOfferDetails,
  submitInsuranceForm,
} from "../../pages/AuthorizedPages/InsuranceRegistration/insurance.slice";
import CustomSnackbar from "../CustomComponents/CustomSnackbar";
import CustomLoader from "../CustomComponents/CustomLoader";
import { MyContext } from "../../utils/context";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorMessageBox = ({ children }) => (
  <div style={{ color: "red", fontSize: "12px" }}>{children}</div>
);

const NomineeForm = () => {
  // const environment = sessionStorage.getItem("environment");
  const offerId = sessionStorage.getItem("offer_id");
  const txnId = sessionStorage.getItem("txnId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [openEventStatusDialog, setOpenEventStatusDialog] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [nackStatus, setNackStatus] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");
  const [error, setError] = useState(false);
  const { setActiveStep } = useContext(MyContext);

  let payment_URL;
  let handle_payment_url;
  let interval;

  const [nomineeDetails, setNomineeDetails] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    relation: "",
  });

  const validateFields = () => {
    if (
      !nomineeDetails.firstName ||
      !nomineeDetails.relation ||
      !nomineeDetails.phone ||
      !nomineeDetails.lastName
    ) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const fetchTransactionStatus = async (retryCount = 0) => {
    setOpenPaymentDialog(false);
    setShowLoader(true);
    if (retryCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setShowLoader(false);
      return;
    }
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((res) => {
        const status = res?.payload?.redirection_status;
        if (
          status === "PAYMENT_FORM_PROCESSED" ||
          status === "PAYMENT_APPROVED"
        ) {
          handle_payment_url.close();
          setActiveStep(7);
          handleNext();
        } else if (
          status === "PAYMENT_FORM_FAILD" ||
          status === "PAYMENT_REJECTED" ||
          status === "PAYMENT_PENDING"
        ) {
          handle_payment_url.close();
          setErrorMessage("Payment failed");
          setShowLoader(false);
          setOpenEventStatusDialog(true);
        } else {
          console.log("Not PAYMENT_APPROVED");
          fetchTransactionStatus(retryCount + 1);
        }
      });
    }, 5000);
  };

  const fetchOffers = () => {
    if (!validateFields()) return;
    setShowLoader(true);
    const payload = {
      txnId: txnId,
      offerId: offerId,
      formData: {
        nomineeDetails,
      },
    };
    const id = setInterval(() => {
      dispatch(creditStatusCheck(payload)).then((res) => {
        const response = res?.payload;
        if (response.current_action === "ON_INIT_NOMINEE") {
          console.log("Success");
          clearInterval(id);
          setTimeout(() => {
            dispatch(insuranceOfferDetails({ txnId: txnId })).then(
              (offerResponse) => {
                if (
                  offerResponse?.payload?.transaction_details
                    ?.current_action === "ON_INIT_NOMINEE" &&
                  offerResponse?.payload?.offer_list[0]?.offer_details
                    ?.error_details?.error_code === null
                ) {
                  console.log("ON_INIT_NOMINEE");
                  dispatch(submitInsuranceForm(payload)).then((res) => {
                    if (res?.payload) {
                      setTimeout(() => {
                        dispatch(
                          insuranceFormUrl({
                            txnId: txnId,
                            offerId: offerId,
                            formType: "PAYMENT",
                          })
                        ).then((res) => {
                          if (res?.payload?.form_url) {
                            setActiveStep(6);
                            setPaymentURL(res?.payload?.form_url);
                            setOpenPaymentDialog(true);
                          }
                          setShowLoader(false);
                        });
                      }, 15000);
                    } else return;
                  });
                } else {
                  if (retryCount < 4) {
                    setRetryCount(retryCount + 1);
                    fetchOffers();
                  } else {
                    setShowLoader(false);
                    setShowSnackbar(true);
                    setErrorMessage(
                      offerResponse?.payload?.offer_list[0]?.offer_details
                        ?.error_details?.error_message || "Something went wrong"
                    );
                    setOpenEventStatusDialog(true);
                  }
                }
              }
            );
          }, 5000);
        }
      });
    }, 3000);
  };

  const onSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNomineeDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePaymentDialog = () => {
    handle_payment_url = window.open(paymentURL, "_blank");
    fetchTransactionStatus();
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchOffers();
  };

  const handleNext = () => {
    setShowLoader(true);
    setNackStatus(false);
    setTimeout(() => {
      dispatch(insuranceConfirm({ txnId: txnId, offerId: offerId })).then(
        (res) => {
          if (res?.error && Object.keys(res?.error)?.length > 0) {
            setShowLoader(false);
            return;
          } else if (res?.payload && res?.payload[0]?.ack_status === "ACK") {
            setShowLoader(false);
            setActiveStep(8);
            navigate("/home/insurer-policy-details");
          } else if (res?.payload && res?.payload[0]?.ack_status === "NACK") {
            setShowLoader(false);
            setNackStatus(true);
          } else {
            setShowLoader(false);
            return;
          }
        }
      );
    }, 2000);
  };

  const handleRetryConfirmCall = () => {
    handleNext();
  };
  const handleCloseConfirmCall = () => {
    setNackStatus(false);
  };
  const handleRejection = () => {
    navigate("/products");
  };
  return (
    <>
      <Box style={{ padding: "20px" }}>
        <CustomLoader open={showLoader} />
        <CustomSnackbar
          message={"Something went wrong"}
          open={showSnackbar}
          status={"error"}
          onClose={onSnackbarClose}
        />

        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                required
                error={!nomineeDetails.firstName && error}
              >
                <FormLabel>First Name</FormLabel>
                <TextField
                  name="firstName"
                  value={nomineeDetails.firstName}
                  onChange={handleChange}
                  placeholder="Enter First Name"
                  error={!nomineeDetails.firstName && error}
                  fullWidth
                />
                {!nomineeDetails.firstName && error && (
                  <ErrorMessageBox>Enter First Name</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                required
                error={!nomineeDetails.lastName && error}
              >
                <FormLabel>Last Name</FormLabel>
                <TextField
                  name="lastName"
                  value={nomineeDetails.lastName}
                  onChange={handleChange}
                  placeholder="Enter Last Name"
                  error={!nomineeDetails.lastName && error}
                  fullWidth
                />
                {!nomineeDetails.lastName && error && (
                  <ErrorMessageBox>Enter Last Name</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                required
                error={!nomineeDetails.phone && error}
              >
                <FormLabel>Phone Number</FormLabel>
                <TextField
                  name="phone"
                  value={nomineeDetails.phone}
                  onChange={handleChange}
                  placeholder="Enter Phone Number"
                  error={!nomineeDetails.phone && error}
                  fullWidth
                />
                {!nomineeDetails.phone && error && (
                  <ErrorMessageBox>Enter Phone Number</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                required
                error={!nomineeDetails.relation && error}
              >
                <FormLabel>Relation</FormLabel>
                <TextField
                  name="relation"
                  value={nomineeDetails.relation}
                  onChange={handleChange}
                  placeholder="Enter Relation"
                  error={!nomineeDetails.relation && error}
                  fullWidth
                />
                {!nomineeDetails.relation && error && (
                  <ErrorMessageBox>Enter Relation</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
              {/* <Button onClick={handleNext}>Confirm</Button> */}
            </Grid>
          </Grid>
        </form>
      </Box>
      <Dialog
        open={openPaymentDialog}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseConsentDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          <DialogTitle
            sx={{ fontFamily: "plus jakarta sans bold", color: "#1976d2" }}
          >
            You are being redirected to Payment page{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                fontSize: "1.1rem",
                color: "#555",
                fontFamily: "source sans pro",
              }}
            >
              Please stay with us, we are redirecting you to the next page. It
              won't take long!
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: "10px" }}>
            <Button
              sx={{
                backgroundColor: "#f4f4f4",
                boxShadow: "none",
                textTransform: "none",
                color: "black",
                fontWeight: 700,
                fontSize: "1rem",
              }}
              variant="contained"
              onClick={handleClosePaymentDialog}
            >
              Cancel
            </Button>
            <Button
              sx={{
                boxShadow: "none",
                textTransform: "none",
                fontWeight: 700,
                fontSize: "1rem",
              }}
              variant="contained"
              onClick={() => handlePaymentDialog()}
            >
              Proceed
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={openEventStatusDialog}
        keepMounted
        // onClose={handleCloseConsentDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          <DialogTitle>{errorMessage}</DialogTitle>
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "10px" }}
          >
            <Button variant="contained" onClick={handleRejection}>
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog open={nackStatus} fullWidth={true} maxWidth={"xs"}>
        <div style={{ padding: "10px", textAlign: "center" }}>
          <DialogTitle>Something went wrong</DialogTitle>
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "10px" }}
          >
            <Button variant="contained" onClick={handleRetryConfirmCall}>
              Retry
            </Button>
            <Button variant="contained" onClick={handleCloseConfirmCall}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default NomineeForm;
