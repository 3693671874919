import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Breadcrumbs,
  Link,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  DialogTitle,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import StartKyc from "./Sections/StartKyc";
import BankDetails from "./Sections/BankDetails";
import EMandate from "./Sections/EMandate";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import DialogContentText from "@mui/material/DialogContentText";
import {
  creditConfirm,
  creditInit,
  creditStatusCheck,
  getCreditOffers,
  getFormUrl,
  submitCreditForm,
} from "../../../slice/creditForm.slice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../components/CustomComponents/CustomSnackbar";
import { MyContext } from "../../../utils/context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ViewOfferDetails from "../OffersPage/section/viewOfferDetails";

const PageHeader = styled("div")(({ theme }) => ({
  margin: "20px 0px 20px 0px",
  [theme.breakpoints.down("sm")]: {
    margin: "20px 0px 10px 20px",
  },
}));

const BankDetailsBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1),
  position: "relative",
}));

const CreditContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  margin: "20px ",
  padding: "20px",
  boxShadow: "0px 0px 10px 10px rgba(0,0,0,0.1)",
  overflowY: "auto",
  scrollbarWidth: "none",
}));

const LoanAccordianContainer = styled(Box)(({ theme }) => ({
  margin: "20px",
}));

const useStyles = {
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  planeImage: {
    width: "50px",
    height: "50px",
    transition: "transform 1s ease-in-out",
  },
  planeImageFlying: {
    transform: "translateX(250px) rotate(-20deg)",
  },
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: "20px",
}));

export default function AccountPage() {
  const { activeStep, setActiveStep } = useContext(MyContext);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [creditForm, setCreditForm] = useState({});
  const [expanded, setExpanded] = useState(activeStep);
  const [showLoader, setShowLoader] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [openEventStatusDialog, setOpenEventStatusDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const user_role = sessionStorage.getItem("user_role");
  const [confirmation, setConfirmation] = useState(false);
  const [openURlError, setOpenURlError] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const txnId = sessionStorage.getItem("txnId");
  const offerId = sessionStorage.getItem("offer_id");
  const [offer, setOffer] = useState(null);
  const [page, setPage] = useState("");
  // console.log(txnId, offerId);
  const dispatch = useDispatch();
  const [formUrl, setFormUrl] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [funcStep, setFuncStep] = useState(0);
  const [error, setError] = useState(false);
  const [AccNo, setAccNo] = useState({
    num: "",
    re_num: "",
  });
  // const classes = useStyles();
  const [isFlying, setIsFlying] = useState(false);
  const [offerItemId, setOfferItemId] = useState("");
  const location = useLocation();
  let form_emandate_URL;
  let kycWindow;

  useEffect(() => {
    setActiveStep(3);
    // console.log(txnId);
    const payload = {
      txnId: txnId,
    };
    // console.log("payload", payload);

    dispatch(getCreditOffers(payload)).then((res) => {
      setOffer(res?.payload);
      if (location.state?.isResume === true) {
        setOfferItemId(
          res?.payload?.offer_list[0]?.offer_details?.offer_item_id
        );
      }
    });
  }, [txnId]);
  const handleBack = () => {
    const prevStep = activeStep - 1;
    setActiveStep(prevStep);
    setExpanded(prevStep);
  };

  const checkError = (activeStep) => {
    const { accountDetails } = creditForm;
    if (activeStep === 4) {
      if (
        !accountDetails.accHolderName ||
        !accountDetails.acctype ||
        !accountDetails.accNo ||
        !accountDetails.ifscCode ||
        AccNo.num !== AccNo.re_num
      ) {
        setError(true);
        return true;
      }
    }
    return false;
  };
  const { resumeState } = location.state || {};

  useEffect(() => {
    if (resumeState) {
      setActiveStep(resumeState);
      setExpanded(resumeState);
    }
  }, [resumeState]);
  const handleContinue = () => {
    const hasError = checkError(activeStep);
    if (!hasError) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
      setExpanded(nextStep);
    } else {
      setShowSnackbar(true);
    }
  };

  const updateFormState = (section, data) => {
    setCreditForm((prevState) => ({
      ...prevState,
      [section]: data,
    }));
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //We are implementing the functionality for dialog box here
  const handleDialogSubmit = (value) => {
    if (value === "YES") {
      setConfirmation(true);
      setIsFlying(true);
      setProgress(progress + 20);
      setTimeout(() => {
        setConfirmationDialog(false);
        setIsFlying(false);
      }, 1000);
      setTimeout(() => {
        form_emandate_URL = window.open(formUrl, "_blank");
      }, 1800);
      if (page === "KYC") {
        fetchTransactionStatus();
      } else if (page === "E-Mandate") {
        setShowLoader(false);
        checkEMNDTStatus();
      } else if (page === "Loan payment") {
        setShowLoader(false);
        checkARGMNTStatus();
      } else {
        setShowLoader(false);
        setConfirmationDialog(false);
        return;
      }
    } else {
      setShowLoader(false);
      setConfirmationDialog(false);
      return;
    }
  };
  //Here we are starting KYC Functionality
  const handleNextSubmit = () => {
    setFuncStep(1);
    setShowLoader(true);
    setOpenEventStatusDialog(false);
    const payload = {
      txnId: txnId,
      offerId: offerId || offerItemId,
    };
    let retryCount = 0;
    const id = setInterval(() => {
      if (retryCount > 3) {
        clearInterval(id);
        setShowLoader(false);
        setOpenEventStatusDialog(true);
        return;
      }

      dispatch(creditStatusCheck(payload)).then((res) => {
        if (res?.payload?.current_action === "ON_SELECT_KYC") {
          clearInterval(id);
          dispatch(getFormUrl(payload)).then((res) => {
            const response = res?.payload;
            if (response?.form_url) {
              setProgress(progress + 40);
              setFormUrl(response?.form_url);
              setPage("KYC");
              setConfirmationDialog(true);
              setShowLoader(false);
              setFuncStep(0);
            }
          });
        } else {
          retryCount += 1;
        }
      });
    }, 3000);
  };
  //Here we are Checking for GTS KYC
  const fetchTransactionStatus = () => {
    setShowLoader(true);
    const payload = {
      txnId: txnId,
      offerId: offerId || offerItemId,
    };
    const id = setInterval(() => {
      dispatch(creditStatusCheck(payload)).then((res) => {
        const selectResponse = res?.payload;
        if (selectResponse?.redirection_status === "KYC_APPROVED") {
          form_emandate_URL.close();
          setProgress(progress + 40);
          setShowLoader(false);
          clearInterval(id);
          dispatch(creditInit(payload)).then((res) => {
            const initResponse = res?.payload;
            if (initResponse?.ack_status === "ACK") {
              const nextStep = activeStep + 1;
              setShowLoader(false);
              setActiveStep(nextStep);
              setExpanded(nextStep);
            } else {
              console.log("ACK NOT FOUND");
            }
          });
        } else {
          console.log("Error Occured");
        }
      });
    }, 3000);
  };
  //Here we are Submiting the account form
  const handleSubmit = (retryCount = 0) => {
    setFuncStep(2);
    const hasError = checkError(activeStep);
    if (hasError) {
      setShowSnackbar(true);
      return;
    }
    setShowLoader(true);
    const formData = creditForm.accountDetails;
    const payload = {
      txnId: txnId,
      accountDetails: { ...formData, aaMonitoringConsent: "true" },
    };
    setProgress(progress + 70);

    const id = setInterval(() => {
      dispatch(creditStatusCheck(payload)).then((creditRes) => {
        // console.log(creditRes?.payload);
        if (creditRes?.payload?.current_action === "ON_INIT_ACC_DTL") {
          clearInterval(id);
          dispatch(submitCreditForm({ payload, txnId: txnId })).then((res) => {
            const formResponse = res?.payload;
            if (formResponse) {
              const hasSuccess = formResponse.some(
                (item) => item.form_status === "Success"
              );
              if (hasSuccess) {
                const initPayload = {
                  txnId: txnId,
                  offerId: offerId || offerItemId,
                };
                dispatch(creditInit(initPayload)).then((res) => {
                  if (res?.payload?.ack_status === "ACK") {
                    const nextStep = activeStep + 1;
                    setActiveStep(nextStep);
                    setExpanded(nextStep);
                    setProgress(progress + 30);
                  } else {
                    console.warn("Error Occurred");
                  }
                  setShowLoader(false);
                });
              } else {
                setShowLoader(false);
              }
            }
          });
        } else {
          retryCount += 1;
          if (retryCount > 5) {
            clearInterval(id);
            setShowLoader(false);
          }
        }
      });
    }, 5000);
  };

  //Here we are checking the E-madate button functionality
  const handleEMandate = async (retryCount = 0) => {
    setFuncStep(3);
    setShowLoader(true);
    if (retryCount >= 3) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setOpenURlError(true);
      setShowLoader(false);
      clearInterval(id);
    }
    const payload = {
      txnId: txnId,
      offerId: offerId || offerItemId,
    };

    const id = setInterval(() => {
      dispatch(creditStatusCheck(payload)).then((statusResponse) => {
        if (statusResponse?.payload?.current_action === "ON_INIT_EMNDT") {
          clearInterval(id);
          setProgress(progress + 40);
          dispatch(getFormUrl(payload)).then((res) => {
            if (res?.payload?.form_url) {
              setConfirmationDialog(true);
              setFormUrl(res?.payload?.form_url);
              setPage("E-Mandate");
            }
          });
        } else {
          console.log("Not ON_INIT_EMNDT");
          handleEMandate(retryCount + 1);
        }
      });
      setShowLoader(false);
    }, 3000);
  };
  //here we are checking the emadate GTS status
  const checkEMNDTStatus = async (retryEMNDTCount = 0) => {
    setFuncStep(4);
    setShowLoader(true);
    if (retryEMNDTCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setOpenURlError(true);
      setShowLoader(false);
    }
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((statusResponse) => {
        // console.log(form_emandate_URL);

        setProgress(progress + 20);
        if (statusResponse?.payload?.redirection_status === "EMNDT_APPROVED") {
          setProgress(progress + 20);
          setConfirmationDialog(false);
          if (form_emandate_URL) {
            form_emandate_URL.close();
          }
          const initPayload = {
            txnId: txnId,
            offerId: offerId || offerItemId,
          };
          const delay = 5000;

          const init_call = () => {
            dispatch(creditInit(initPayload)).then((initResponse) => {
              // setShowLoader(false);
              if (initResponse?.payload?.txn_id) {
                setTimeout(getAgrmntUrl, delay);
              }
            });
          }; // 20000
          setTimeout(init_call, delay);
        } else if (
          statusResponse?.payload?.redirection_status === "EMNDT_REJECTED"
        ) {
          form_emandate_URL.close();
          setDialogMessage("Emandate failed");
          setOpenEventStatusDialog(true);
          setShowLoader(false);
        } else {
          console.log("Not EMNDT_APPROVED");
          // Make the API call again with incremented retryEMNDTCount
          checkEMNDTStatus(retryEMNDTCount + 1);
        }
      });
    }, 5000);
  };
  //After the emandate is succussfull we'll get another URl for Loan payment
  const getAgrmntUrl = async (retryCount = 0) => {
    setFuncStep(5);
    setShowLoader(true);
    if (retryCount >= 3) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setOpenErrorDialog(true);
      setShowLoader(false);
      return;
    }
    setProgress(progress + 40);
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((statusResponse) => {
        if (statusResponse?.payload?.current_action === "ON_INIT_LOAN_AGRMT") {
          dispatch(
            getFormUrl({ txnId: txnId, offerId: offerId || offerItemId })
          ).then((res) => {
            if (res?.payload?.form_url) {
              setFormUrl(res?.payload?.form_url);
              setConfirmationDialog(true);
              setPage("Loan payment");
            }
          });
        } else {
          console.log("Not ON_INIT_LOAN_AGRMT");
          // Make the API call again with incremented retryCount
          getAgrmntUrl(retryCount + 1);
        }
      });
    }, 5000);
  };
  //After the emandate is succussfull we'll call GTS status for Loan Payment
  const checkARGMNTStatus = async (retryCount = 0) => {
    setFuncStep(6);
    setShowLoader(true);
    if (retryCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setOpenErrorDialog(true);
      setShowLoader(false);
      return;
    }

    let confirm = false;
    const payload = {
      txnId: txnId,
      offerId: offerId || offerItemId,
    };
    setProgress(progress + 15);

    const id = setInterval(() => {
      dispatch(creditStatusCheck(payload)).then((statusResponse) => {
        if (
          statusResponse?.payload?.redirection_status === "LOAN_AGRMT_APPROVED"
        ) {
          clearInterval(id);
          setConfirmationDialog(false);
          setProgress(progress + 15);
          if (form_emandate_URL) {
            form_emandate_URL.close();
          }
          setConfirmStatus(true);
          confirm = true;

          if (confirm) {
            dispatch(creditConfirm(payload)).then((res) => {
              if (res?.payload?.txn_id) {
                setProgress(progress + 10);
                setShowLoader(false);
                if (user_role === "guest") {
                  navigate("/loan/order-details");
                } else {
                  navigate("/home/order-details");
                }
              }
            });
          }
        } else if (
          statusResponse?.payload?.redirection_status === "LOAN_AGRMT_REJECTED"
        ) {
          clearInterval(id);
          if (form_emandate_URL) {
            form_emandate_URL.close();
            setConfirmationDialog(false);
          }
          setDialogMessage("Loan agreement process failed");
          setOpenEventStatusDialog(true);
          setShowLoader(false);
        } else {
          console.log("Not ON_INIT_LOAN_AGRMT");
          clearInterval(id);
          setTimeout(() => checkARGMNTStatus(retryCount + 1), 3000);
        }
      });
    }, 3000);
  };

  const handleRejection = () => {
    if (user_role === "guest") {
      navigate("/loan");
    } else {
      navigate("/home/dashboard");
    }
  };

  return (
    <>
      <BankDetailsBody>
        <CustomLoader
          open={showLoader}
          progress={progress}
          setProgress={setProgress}
        />
        <CustomSnackbar
          open={showSnackbar}
          onClose={() => setShowSnackbar(false)}
          message={"Fill all the fields"}
          status={"warning"}
        />
        <LoanAccordianContainer>
          <Accordion sx={{ marginBottom: "30px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Loan Informations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ViewOfferDetails offer={offer} pageName={"account-details"} />
            </AccordionDetails>
          </Accordion>
        </LoanAccordianContainer>
        <CreditContainer>
          {activeStep === 3 && <StartKyc />}
          {activeStep === 4 && (
            <BankDetails
              formData={creditForm.accountDetails}
              updateFormData={(data) => updateFormState("accountDetails", data)}
              AccNo={AccNo}
              setAccNo={setAccNo}
              textFieldError={error}
            />
          )}
          {activeStep === 5 && <EMandate />}
          <ButtonContainer>
            {activeStep === 3 ? (
              <React.Fragment>
                <Button variant="outlined">Cancel</Button>
                <Button variant="contained" onClick={handleNextSubmit}>
                  Start KYC
                </Button>
              </React.Fragment>
            ) : activeStep === 4 ? (
              <React.Fragment>
                <Button variant="outlined" onClick={handleBack}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleContinue && handleSubmit}
                >
                  Continue
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button variant="outlined" onClick={handleBack}>
                  Back
                </Button>
                <Button variant="contained" onClick={handleEMandate}>
                  Setup E-mandate
                </Button>
              </React.Fragment>
            )}
          </ButtonContainer>
        </CreditContainer>
        {activeStep === 6 && (
          <Box sx={{ width: "auto", marginRight: 0 }}>
            <Button variant="outlined">Cancel</Button>
            <Button sx={{ marginLeft: "10px" }} variant="contained">
              Submit
            </Button>
          </Box>
        )}
      </BankDetailsBody>
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogContent style={useStyles.dialogContent}>
          <img
            src="https://pngfre.com/wp-content/uploads/Airplane-4.png"
            alt="Plane"
            style={{
              ...useStyles.planeImage,
              ...(isFlying ? useStyles.planeImageFlying : {}),
            }}
          />
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            Please don't exit or press back.
            <br />
            Please wait while we are redirecting you to the Verification Page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogSubmit("YES")}>Proceed</Button>
          {/* <Button onClick={() => handleDialogSubmit("NO")}>No</Button> */}
        </DialogActions>
      </Dialog>
      {/* ERROR DIALOG*/}
      <Dialog
        open={openEventStatusDialog}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseConsentDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          <DialogTitle>{dialogMessage}</DialogTitle>
          <DialogContent style={useStyles.dialogContent}>
            <Box>
              <Typography
                variant="h4"
                fontSize={"2rem"}
                fontWeight={700}
                color={"red"}
              >
                OOPS
              </Typography>
            </Box>
            <DialogContentText
              id="alert-dialog-description"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              Something Went Wrong! Please Try Again!
              <br />
              {page} Failed!!
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "10px" }}
          >
            <Button
              variant="contained"
              onClick={funcStep === 1 ? handleNextSubmit : handleRejection}
            >
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
