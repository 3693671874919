import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../utils/request";
import { apis } from "../utils/apis";

// Thunk action to perform the login API call
export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (payload) => {
    const response = await apiRequest("POST", apis.login, payload, null, {
      // api_key: process.env.REACT_APP_ORG_API_KEY,
      "api-key": process.env.REACT_APP_ORG_API_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
    });
    return response;
  }
);

export const generateOTP = createAsyncThunk(
  "login/generateOTP",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.generateOTP}?mobile_number=${payload?.mobileNumber}`,
      payload
    );
    return response;
  }
);

export const loginUsingOtp = createAsyncThunk(
  "login/loginUsingOtp",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.loginUsingOtp}?otp=${payload.otp}&txn_id=${payload.txnId}`,
      payload
    );
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  "login/resetPassword",
  async (payload) => {
    const response = await apiRequest("POST", apis?.resetPasswordApi, payload);
    return response;
  }
);
export const signUpEmployee = createAsyncThunk(
  "login/signUpEmployee",
  async (payload) => {
    const response = await apiRequest("POST", apis?.signup, payload);
    return response;
  }
);
export const mapManagerToAgent = createAsyncThunk(
  "login/mapManagerToAgent",
  async (payload) => {
    const response = await apiRequest(
      "POST",
      `${apis?.mapManagerToAgentApi}?manager_id=${payload?.manager_id}&agent_id=${payload?.agent_id}`,
      payload
    );
    return response;
  }
);

// Create the login slice
const authUserSlice = createSlice({
  name: "login",
  initialState: {
    user: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetLoginState: (state) => {
      // state.user = null;
      state.userRole = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        // state.user = action.payload;
        state.userRole = action.payload.user_role;
        state.loading = false;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetLoginState } = authUserSlice.actions;
export default authUserSlice.reducer;
