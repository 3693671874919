import { Route, Routes, useLocation } from "react-router-dom";
import "./index.css";
import { keyframes, styled } from "@mui/material";

import RequireAuth from "../pages/AuthorizedPages/RequireAuth";
import LoginPage from "../pages/AuthorizedPages/LoginPage";
import UserDashboard from "../pages/AuthorizedPages/UserDashboard";
import AccountPage from "../pages/AuthorizedPages/AccountPage";
import CreditRegistrationForm from "../pages/AuthorizedPages/CreditRegistrationForm";
import OffersPage from "../pages/AuthorizedPages/OffersPage";
import OrderDetails from "../pages/AuthorizedPages/OrderDetails";
import IssueStatus from "../pages/AuthorizedPages/IssueStatus";
import CreditIssues from "../pages/AuthorizedPages/CreditIssues";

import Unauthorized from "../pages/Unauthorized";
import LoanPages from "../pages/Unauthorized/LoanOutletPage";
import UACreditRegistrationForm from "../pages/Unauthorized/CreditRegistrationForm";
import UACreditIssues from "../pages/Unauthorized/CreditIssues";
import UAIssueStatus from "../pages/Unauthorized/IssueStatus";
import UAOffersPage from "../pages/Unauthorized/OffersPage";
import MobileOrderDetails from "../pages/Unauthorized/MobileOrderDetails";
import ContactDetailsPage from "../pages/Unauthorized/ContactNumberPage";
import BankDetailsPage from "../pages/Unauthorized/BankDetailsPage";
import EMandatePages from "../pages/Unauthorized/EMandatePage";
import KycPage from "../pages/Unauthorized/KycPage";
import ChangePasswordPage from "../pages/AuthorizedPages/ChangePasswordPage";
import AgentDashboard from "../pages/AuthorizedPages/AgentDashboard";
import ManagerDashboard from "../pages/AuthorizedPages/ManagerDashboard";
import AdminDashboard from "../pages/AuthorizedPages/AdminDashboard";
import RegisterEmployeePage from "../pages/AuthorizedPages/RegisterEmployeesPage";
import InsuranceRegistration from "../pages/AuthorizedPages/InsuranceRegistration";
import InsuranceOfferPage from "../pages/AuthorizedPages/InsuranceOfferPage";
import InsurancePolicyDetails from "../pages/AuthorizedPages/InsurancePolicyDetails";
import BuyerFormPage from "../pages/AuthorizedPages/BuyerFormPage";
import NomineeFormPage from "../pages/AuthorizedPages/NomineeFormPage";
import InsurerPolicyDetails from "../pages/InsuranceOrderDetails";
import CustomizeOfferPage from "../pages/Unauthorized/CustomizeOfferPage";
import PageNotFound from "../pages/PageNotFound";

const rotateBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const OuterContainer = styled("div")(({ theme, type }) =>
  type === "special"
    ? {
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        background:
          "linear-gradient(90deg, rgba(173,168,255,1), rgba(113,116,255,1), rgba(0,164,198,1))",
        backgroundSize: "200% 200%",
        animation: `${rotateBackground} 5s ease infinite`,
      }
    : {}
);
const AppContainer = styled("div")(({ theme, type }) =>
  type === "special"
    ? {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "20px auto",
        backgroundColor: "white",
        width: "420px",
        padding: "20px 0px",
        border: "1px solid #e0e0e0",
        boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.05)",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          border: "none",
          margin: "0px",
          height: "100vh",
        },
      }
    : {
        backgroundColor: "white",
      }
);

function App() {
  const location = useLocation();

  const getCss = () => {
    const specialRoutes = ["/loan"];
    return specialRoutes.some((route) => location.pathname.startsWith(route));
  };

  const styleType = getCss() ? "special" : "normal";

  const ROLES = {
    Admin: "admin",
    Manager: "manager",
    Agent: "agent",
    Customer: "customer",
  };

  return (
    <>
      <OuterContainer type={styleType}>
        <AppContainer className="App" type={styleType}>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            {/* authorized routes (/home route)*/}
            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
            </Route>
            <Route
              element={
                <RequireAuth allowedRoles={[ROLES.Admin, ROLES.Manager]} />
              }
            >
              <Route path="/manager-dashboard" element={<ManagerDashboard />} />
              <Route
                path="/register-employee"
                element={<RegisterEmployeePage />}
              />
            </Route>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.Agent, ROLES.Admin, ROLES.Manager]}
                />
              }
            >
              <Route path="/agent-dashboard" element={<AgentDashboard />} />
            </Route>
            {/* <Route
              element={
                <RequireAuth allowedRoles={[ROLES.Customer, ROLES.Admin]} />
              }
            >
            </Route> */}
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Manager,
                    ROLES.Agent,
                    ROLES.Customer,
                  ]}
                />
              }
            >
              <Route path="/user-dashboard" element={<UserDashboard />} />
              <Route path="/reset-password" element={<ChangePasswordPage />} />
              <Route path="/home">
                <Route
                  path="credit-form"
                  element={<CreditRegistrationForm />}
                />
                <Route path="loan-offers" element={<OffersPage />} />
                <Route path="account-details" element={<AccountPage />} />
                <Route path="order-details" element={<OrderDetails />} />
                <Route path="raise-issue" element={<CreditIssues />} />
                <Route path="issue-status" element={<IssueStatus />} />
                {/*Insurance Links */}
                <Route
                  path="insurance-registration"
                  element={<InsuranceRegistration />}
                />
                <Route
                  path="insurance-offer"
                  element={<InsuranceOfferPage />}
                />
                <Route
                  path="policy-details"
                  element={<InsurancePolicyDetails />}
                />
                <Route path="buyer-form" element={<BuyerFormPage />} />
                <Route path="nominee-form" element={<NomineeFormPage />} />
                <Route
                  path="insurer-policy-details"
                  element={<InsurerPolicyDetails />}
                />
              </Route>
            </Route>

            {/* unAuthorized routes (/loan route)*/}
            <Route path="/loan" element={<LoanPages />}>
              <Route path="welcome-page" element={<ContactDetailsPage />} />
              <Route
                path="credit-form"
                element={<UACreditRegistrationForm />}
              />
              <Route path="loan-offers" element={<UAOffersPage />} />
              <Route path="customize-offers" element={<CustomizeOfferPage />} />
              <Route path="kyc-page" element={<KycPage />} />
              <Route path="bank-details" element={<BankDetailsPage />} />
              <Route path="emandate-page" element={<EMandatePages />} />
              <Route path="order-details" element={<MobileOrderDetails />} />
              <Route path="raise-issue" element={<UACreditIssues />} />
              <Route path="issue-status" element={<UAIssueStatus />} />
            </Route>
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AppContainer>
      </OuterContainer>
    </>
  );
}

export default App;
