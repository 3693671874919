import { useState, useEffect, useContext } from "react";
import {
  Typography,
  styled,
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Button,
  Box,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import PersonalDetailsAccordian from "./Sections/PersonalDetails";
import ProfessionalDetailsForm from "./Sections/ProffesionalDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import ArticleIcon from "@mui/icons-material/Article";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import CustomSnackbar from "../../../components/CustomComponents/CustomSnackbar";
import {
  creditSelect,
  creditStatusCheck,
  selectConsent,
  submitCreditForm,
} from "../../../slice/creditForm.slice";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import { creditSearch } from "../Navbar/navbar.slice";
import { MyContext } from "../../../utils/context";

const useStyles = {
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  planeImage: {
    width: "50px",
    height: "50px",
    transition: "transform 1s ease-in-out",
  },
  planeImageFlying: {
    transform: "translateX(250px) rotate(-20deg)",
  },
};

const PageHeader = styled("div")(({ theme }) => ({
  margin: "20px 0px 20px 0px",
  [theme.breakpoints.down("sm")]: {
    margin: "20px 0px 20px 20px",
  },
}));

const ImageContainer = styled(Box)(({ theme, bgcolor, color }) => ({
  borderRadius: "50%",
  backgroundColor: bgcolor || "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 7,
  color: color || "#1976d2",
  marginRight: 10,
}));

const CreditContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "20px",
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const CreditFormPageContainer = styled("div")(({ theme }) => ({
  margin: "20px",
  width: "100%",
}));

const CreditRegistrationForm = () => {
  const { activeStep, setActiveStep } = useContext(MyContext);
  const txnId = sessionStorage.getItem("txnId");
  const user_role = sessionStorage.getItem("user_role");
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [criteria, setCriteria] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [expanded, setExpanded] = useState(activeStep);
  const [creditForm, setCreditForm] = useState({});
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [aaURL, setAAURL] = useState("");
  const [isFlying, setIsFlying] = useState(false);
  const [focus, setFocus] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [error, setError] = useState(false);
  let handle_aa_url;
  const ConsentContent =
    "You hereby undertake and confirm that the information and data furnished " +
    "by you to the platform is true and correct. The platform has been " +
    "integrated with lenders who use Credit Information Companies (CIC) to " +
    "conduct credit checks on body corporates/MSME and Individuals. You " +
    "acknowledge and confirm that you have consented to the sharing of " +
    "sensitive data provided by you with such lenders and CICs for the purposes " +
    "of obtaining the name and account number of banks with whom you have an " +
    "open cash credit or overdraft account. In addition, you consent to the " +
    "data being used to facilitate the creation of a credit offer which may " +
    "include the account to which the funds may be disbursed as per prevailing " +
    "RBI norms.";

  const updateFormState = (section, data) => {
    setCreditForm((prevState) => ({
      ...prevState,
      [section]: data,
    }));
  };

  const [userRole, setUserRole] = useState(
    sessionStorage.getItem("user_role") || ""
  );
  const { resumeState } = location.state || {};
  useEffect(() => {
    if (resumeState) {
      setActiveStep(resumeState);
      setExpanded(resumeState);
    }
  }, [resumeState]);

  useEffect(() => {
    setActiveStep(0);
    // const user_id =
    //   verificationStatus === "SUCCESS"
    //     ? JSON.parse(sessionStorage.getItem("user_details"))?.id
    //     : 3;

    const user_id = JSON.parse(sessionStorage.getItem("user_details"))?.id;

    dispatch(creditSearch(user_id)).then((res) => {
      if (res?.payload) {
        setShowLoader(false);
        sessionStorage.setItem("txnId", res?.payload?.txn_id);
        if (location.pathname.includes("/loan")) {
          sessionStorage.setItem("user_role", "guest");
          setUserRole("guest");
        }
      } else return;
    });
  }, []);

  useEffect(() => {
    console.log("userRole Updated", userRole);
  }, [userRole]);

  const checkError = (activeStep) => {
    const { loanForm } = creditForm;
    if (activeStep === 0) {
      if (
        !loanForm?.firstName ||
        !loanForm?.lastName ||
        !loanForm?.dob ||
        !loanForm?.gender ||
        !loanForm?.contactNumber ||
        !loanForm?.email ||
        !loanForm?.pan ||
        !loanForm?.endUse ||
        !loanForm?.addressL1 ||
        !loanForm?.addressL2 ||
        !loanForm?.city ||
        !loanForm?.state ||
        !loanForm?.pincode
      ) {
        setError(true);
        return true;
      }
    } else if (activeStep === 1) {
      if (
        !loanForm?.companyName ||
        !loanForm?.officialEmail ||
        !loanForm?.employmentType ||
        !loanForm?.income ||
        !loanForm?.aa_id
      ) {
        setError(true);
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const dialogShown = sessionStorage.getItem("dialogShown");
    if (!dialogShown) {
      setCriteria(true);
      sessionStorage.setItem("dialogShown", "true");
    }
  }, []);
  const handleDialogSubmit = (value) => {
    if (value === "YES") {
      setConfirmation(true);
      setIsFlying(true);
      setTimeout(() => {
        setConfirmationDialog(false);
        setIsFlying(false);
        handle_aa_url = window.open(aaURL, "_blank");
      }, 1500);
      setProgress(progress + 30);
      fetchTransactionStatus();
    } else {
      setShowLoader(false);
      setConfirmationDialog(false);
      return;
    }
  };

  const fetchTransactionStatus = async (retryCount = 0) => {
    if (retryCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      return;
    }

    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId })).then((res) => {
        if (res?.payload?.redirection_status === "AA_APPROVED") {
          setProgress(progress + 25);
          console.log("Desired response received:");
          handle_aa_url.close();
          const consentPayload = {
            txnId: txnId,
          };
          dispatch(selectConsent(consentPayload)).then((consentResponse) => {
            // console.log("1st Func", consentResponse?.payload);
            setShowLoader(false);
            if (user_role === "guest") {
              navigate("/loan/loan-offers");
            } else {
              navigate("/home/loan-offers");
            }
            sessionStorage.setItem("showTimer", true);
          });
        } else if (res?.payload?.redirection_status === "AA_REJECTED") {
          handle_aa_url.close();
          setConfirmationDialog(true);
        } else {
          console.log("Not AA_APPROVED");
          fetchTransactionStatus(retryCount + 1);
        }
      });
    }, 5000);
  };

  const handleConsent = () => {
    setShowLoader(true);
    setPopoverOpen(false);
    setProgress(progress + 10);
    const updatedLoanForm = {
      ...creditForm.loanForm,
      bureauConsent: true,
    };

    const loanFormPayload = {
      loanForm: updatedLoanForm,
    };

    dispatch(submitCreditForm({ payload: loanFormPayload, txnId }))
      .then((res) => {
        const formResponse = res?.payload;
        const response = formResponse.some(
          (item) => item.form_status == "Success"
        );
        if (response) {
          const payload = {
            txnId: txnId,
            userId: `${loanFormPayload.loanForm.contactNumber}@finvu`,
          };
          setProgress(progress + 35);
          dispatch(creditSelect(payload)).then((res) => {
            const creditResponse = res?.payload;
            const response = creditResponse.some(
              (item) => item.ack_status == "ACK"
            );
            if (response) {
              setProgress(progress + 75);
              setShowLoader(false);
              if (user_role === "guest") {
                navigate("/loan/loan-offers", {
                  state: {
                    contactNumber: loanFormPayload.loanForm.contactNumber,
                  },
                });
              } else {
                navigate("/home/loan-offers", {
                  state: {
                    contactNumber: loanFormPayload.loanForm.contactNumber,
                  },
                });
              }
              sessionStorage.setItem("showTimer", true);
            } else {
              setShowLoader(false);
              console.log("Error Occured");
            }
          });
        } else {
          console.log("Error Occured");
        }
      })
      .catch((error) => {
        setShowLoader(false);
        console.error("Error submitting credit form:", error);
      });
  };

  const criteriaData = [
    {
      icon: <CameraFrontIcon />,
      text: "You must be between the ages of 21 to 55 years.",
    },
    {
      icon: <ArticleIcon />,
      text: "You must be a resident of India.",
    },
    {
      icon: <PermIdentityIcon />,
      text: "You must have a stable source of income.",
    },
  ];

  const documentsData = [
    {
      icon: <CameraFrontIcon />,
      text: "A Selfie: A clear picture of yours with proper lighting.",
    },
    {
      icon: <ArticleIcon />,
      text: "Proof of Identity: Passport/ Aadhar card/PAN card/Driver's license.",
    },
    {
      icon: <PermIdentityIcon />,
      text: "Proof of Address: Passport/rent agreement/utility bills/voter's ID.",
    },
  ];

  const handleClick = () => {
    setCriteria(false);
  };

  return (
    <CreditFormPageContainer>
      <CustomLoader
        open={showLoader}
        progress={progress}
        setProgress={setProgress}
      />
      <CustomSnackbar
        message={"Fill all the fields"}
        status={"warning"}
        onClose={() => setShowSnackbar(false)}
        open={showSnackbar}
      />

      <CreditContainer>
        {activeStep === 0 && (
          <PersonalDetailsAccordian
            formData={creditForm.loanForm}
            updateFormData={(data) => updateFormState("loanForm", data)}
            error={error}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            checkError={checkError}
            setShowSnackbar={setShowSnackbar}
          />
        )}
        {activeStep === 1 && (
          <ProfessionalDetailsForm
            formData={creditForm.loanForm}
            updateFormData={(data) => updateFormState("loanForm", data)}
            error={error}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            checkError={checkError}
            setShowSnackbar={setShowSnackbar}
            setPopoverOpen={setPopoverOpen}
          />
        )}
      </CreditContainer>
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogContent style={useStyles.dialogContent}>
          <img
            src="https://pngfre.com/wp-content/uploads/Airplane-4.png"
            alt="Plane"
            style={{
              ...useStyles.planeImage,
              ...(isFlying ? useStyles.planeImageFlying : {}),
            }}
          />
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            Please don't exit or press back.
            <br />
            Please wait while we are redirecting you to the Verification Page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogSubmit("YES")}>Proceed</Button>
          {/* <Button onClick={() => handleDialogSubmit("NO")}>No</Button> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ height: "216" }}>
          <DialogContentText id="alert-dialog-description">
            You hereby undertake and confirm that the information and data
            furnished by you to the platform is true and correct. The platform
            has been integrated with lenders who use Credit Information
            Companies (CIC) to conduct credit checks on body corporates/MSME and
            Individuals. You acknowledge and confirm that you have consented to
            the sharing of sensitive data provided by you with such lenders and
            CICs for the purposes of obtaining the name and account number of
            banks with whom you have an open cash credit or overdraft account.
            In addition, you consent to the data being used to facilitate the
            creation of a credit offer which may include the account to which
            the funds may be disbursed as per prevailing RBI norms.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConsent(true)}>DISAGREE</Button>
          <Button onClick={() => handleConsent(false)}>AGREE</Button>
        </DialogActions>
      </Dialog>
      <BootstrapDialog
        onClose={() => setCriteria(false)}
        aria-labelledby="customized-dialog-title"
        open={criteria}
      >
        <Stack justifyContent={"center"} alignItems={"center"}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            <Typography sx={{ marginLeft: 2, fontSize: "1.2rem" }}>
              <b> Eligibility & Documents Required for HealthTech Financing </b>
            </Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent dividers>
          <Grid container gap={3} sx={{ padding: 4 }}>
            <Grid
              item
              xs={12}
              md={5.8}
              sx={{
                backgroundColor: "#e5e7eb",
                padding: "30px 20px 20px 30px",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 700 }} mb={3}>
                Eligibility Criteria
              </Typography>
              {criteriaData.map((item, index) => (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  mb={2}
                  key={index}
                >
                  <ImageContainer>{item.icon}</ImageContainer>
                  <Typography variant="body1">{item.text}</Typography>
                </Stack>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              md={5.8}
              sx={{
                backgroundColor: "#263238",
                padding: "30px 20px 20px 30px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 700 }}
                mb={3}
                color={"white"}
              >
                Documents Required
              </Typography>
              {documentsData.map((item, index) => (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  mb={2}
                  key={index}
                >
                  <ImageContainer bgcolor="white" color="#1976d2">
                    {item.icon}
                  </ImageContainer>
                  <Typography variant="body1" color={"white"}>
                    {item.text}
                  </Typography>
                </Stack>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={"1.2rem"}
            fontWeight={600}
            textAlign={"center"}
            color={"#1976d2"}
          >
            ONDC is a Gov Backed Project and we are partnered with RBI Licensed
            Lenders Only!
          </Typography>
        </DialogActions>
      </BootstrapDialog>
    </CreditFormPageContainer>
  );
};

export default CreditRegistrationForm;
