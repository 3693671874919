import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  styled,
  Dialog,
  DialogTitle,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItem,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  insuranceFormUrl,
  insuranceInit,
  insuranceOfferDetails,
} from "../InsuranceRegistration/insurance.slice";
import { creditStatusCheck } from "../../../slice/creditForm.slice";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import { MyContext } from "../../../utils/context";

const LoanDetailsWrapper = styled("div")(({ theme }) => ({
  "&": {
    padding: "10px",
  },
  ".MuiOutlinedInput-input.Mui-disabled": {
    WebkitTextFillColor: "#0000009c",
  },
}));

const InsurancePolicyDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);
  const offerId = sessionStorage.getItem("offer_id");
  const txnId = sessionStorage.getItem("txnId");
  const [showLoader, setShowLoader] = useState(false);
  const [openEventStatusDialog, setOpenEventStatusDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formUrl, setFormUrl] = useState("");
  const { setActiveStep } = useContext(MyContext);

  let form_kyc_URL;

  useEffect(() => {
    setShowLoader(true);
    const payload = {
      txnId: txnId,
      offerId: offerId,
    };
    dispatch(insuranceOfferDetails(payload)).then((res) => {
      setDetails(res?.payload?.offer_list);
      setShowLoader(false);
    });
  }, []);

  const fetchTransactionStatus = async (retryCount = 0) => {
    setShowLoader(true);
    if (retryCount >= 100) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setShowLoader(false);
      return;
    }
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((res) => {
        const status = res?.payload?.redirection_status;
        if (
          status === "KYC_FORM_PROCESSED" ||
          status === "KYC_APPROVED" ||
          status === "KYC_SUCCESS"
        ) {
          form_kyc_URL.close();
          handleNext();
        } else if (
          status === "KYC_FORM_FAILD" ||
          status === "KYC_REJECTED" ||
          status === "KYC_PENDING"
        ) {
          form_kyc_URL.close();
          setErrorMessage("KYC failed");
          setShowLoader(false);
          setOpenEventStatusDialog(true);
        } else {
          console.log("Not KYC_APPROVED");
          fetchTransactionStatus(retryCount + 1);
        }
      });
    }, 5000);
  };

  const handleKYC = () => {
    setShowLoader(true);
    dispatch(
      insuranceFormUrl({ txnId: txnId, offerId: offerId, formType: "KYC" })
    ).then((res) => {
      if (res?.payload?.form_url) {
        setOpenEventStatusDialog(true);
        setFormUrl(res?.payload?.form_url);
      }
    });
  };

  const handleNext = () => {
    setTimeout(() => {
      const payload = {
        txnId: txnId,
        offerId: offerId,
      };
      dispatch(insuranceInit(payload)).then((res) => {
        if (res?.error && Object.keys(res?.error)?.length > 0) {
          setShowLoader(false);
          return;
        } else if (res?.payload[0]?.ack_status === "ACK") {
          setShowLoader(false);
          setActiveStep(4);
          navigate("/home/buyer-form");
        } else {
          // setErrorMessage()
          // setShowSnackbar(true);
          setShowLoader(false);
          return;
        }
      });
    }, 5000);
  };

  const handleAgree = () => {
    // console.log(formUrl);
    form_kyc_URL = window.open(formUrl, "_blank");
    fetchTransactionStatus();
    setOpenEventStatusDialog(false);
    // navigate("/products");
  };
  const handleClose = () => {
    setOpenEventStatusDialog(false);
    navigate("/products");
  };
  return (
    <>
      <LoanDetailsWrapper>
        <CustomLoader open={showLoader} />

        {details?.map(
          (data) =>
            data?.offer_details.offer_item_id === offerId && (
              <>
                <div>
                  <h3>Policy Details</h3>
                  <Grid container spacing={4}>
                    {Object.entries(data?.offer_details).map(
                      ([key, value]) =>
                        key !== "available_add_ons" &&
                        key !== "error_details" && (
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label={
                                key
                                  ? key
                                      ?.split("_")
                                      .join(" ")
                                      .toLocaleUpperCase()
                                  : ""
                              }
                              value={
                                key === "selected_add_ons"
                                  ? Object.entries(value).map(([key, value]) =>
                                      value?.descriptor?.name
                                        ?.split("_")
                                        .join(" ")
                                    )
                                  : value?.value || value || ""
                              }
                              fullWidth
                              disabled
                            />
                          </Grid>
                        )
                    )}
                    {/* {(data?.quote_details).map(([key, value]) => ( */}
                    <>
                      <Grid item xs={12} sm={6}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography></Typography>
                            <ListItem>
                              <ListItem> TOTAL PRICE </ListItem>
                              <ListItem>
                                {" "}
                                {data?.quote_details?.price?.currency}{" "}
                                {data?.quote_details?.price?.value}{" "}
                              </ListItem>
                            </ListItem>
                          </AccordionSummary>
                          <AccordionDetails>
                            {data?.quote_details?.breakup &&
                              Object.entries(data?.quote_details?.breakup).map(
                                ([key, value]) => (
                                  <ListItem>
                                    <ListItem>
                                      {" "}
                                      {value.title?.split("_").join(" ")}{" "}
                                    </ListItem>
                                    <ListItem>
                                      {" "}
                                      {value.price.currency} {value.price.value}{" "}
                                    </ListItem>
                                  </ListItem>
                                )
                              )}
                          </AccordionDetails>
                        </Accordion>
                        {/* <TextField
                                            label='Total Price'
                                            value={data?.quote_details?.price?.value}
                                        fullWidth
                                        disabled
                                    /> */}
                      </Grid>
                    </>
                    {/* ))} */}
                  </Grid>
                </div>
              </>
            )
        )}
        <br />
        <Button onClick={handleKYC} variant="contained" color="primary">
          Start KYC
        </Button>
        {/* &nbsp;&nbsp;
        <Button onClick={handleNext} variant="contained" color="primary">
           Next
        </Button> */}
      </LoanDetailsWrapper>
      <Dialog
        open={openEventStatusDialog}
        keepMounted
        // onClose={handleCloseConsentDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
        key={1}
      >
        <div style={{ textAlign: "center" }}>
          <DialogTitle
            sx={{ fontFamily: "plus jakarta sans bold", color: "#1976d2" }}
          >
            {errorMessage
              ? errorMessage
              : "Hold Tight, You're Being Redirected!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                fontSize: "1.1rem",
                color: "#555",
                fontFamily: "source sans pro",
              }}
            >
              Please stay with us, we are redirecting you to the next page. It
              won't take long!
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#f4f4f4",
                boxShadow: "none",
                textTransform: "none",
                color: "black",
                fontWeight: 700,
                fontSize: "1rem",
              }}
              onClick={handleClose}
            >
              Disagree
            </Button>
            <Button
              variant="contained"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                fontWeight: 700,
                fontSize: "1rem",
              }}
              onClick={handleAgree}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default InsurancePolicyDetails;
