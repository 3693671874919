import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  creditCloseIssue,
  creditIssueStatus,
  creditListIssues,
} from "../../../slice/creditForm.slice";
import {
  Box,
  styled,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Toolbar,
  IconButton,
  AppBar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { format } from "date-fns";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";

const ConsentDocsContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: "Source Sans Pro",
  ".patientList-title-wrapper": {
    width: "100%",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

const UAIssueStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const [isRotating, setIsRotating] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [issueDetails, setIssueDetails] = useState([]);
  const [isIssueClosed, setIsIssueClosed] = useState(false);
  const user_role = sessionStorage.getItem("user_role");

  useEffect(() => {
    setShowLoader(true);
    const payload = {
      customer_id:
        user_role === "customer"
          ? JSON.parse(sessionStorage.getItem("user_details"))?.id
          : user_role === "guest"
          ? "03"
          : sessionStorage.getItem("selectedCustomerID"),
    };
    dispatch(creditListIssues(payload)).then((res) => {
      setShowLoader(false);
      setIssueDetails(res?.payload);
    });
  }, []);

  const handleClickOpen = (index) => {
    setIndexValue(index);
    issueDetails[index].complainant_actions.map((action) => {
      if (action?.complainant_action === "CLOSE") {
        setIsIssueClosed(true);
      } else setIsIssueClosed(false);
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseIssue = () => {
    const payload = {
      txnId: sessionStorage.getItem("txnId"),
      orderId: sessionStorage.getItem("orderId"),
      issueAction: "CLOSE",
      issueId: issueDetails[indexValue]?.issue_id,
    };
    dispatch(creditCloseIssue(payload)).then((res) => {
      setIsIssueClosed(true);
      handleCurrentStatus(issueDetails[indexValue]?.issue_id);
    });
  };

  const handleCurrentStatus = (issueId) => {
    setIsRotating(true);
    setShowLoader(true);
    const payload = {
      txnId: sessionStorage.getItem("txnId"),
      issueId: issueId,
    };
    dispatch(creditIssueStatus(payload)).then((statusResponse) => {
      if (statusResponse?.payload) {
        const payload = {
          customer_id:
            user_role === "customer"
              ? JSON.parse(sessionStorage.getItem("user_details"))?.id
              : user_role === "guest"
              ? "03"
              : sessionStorage.getItem("selectedCustomerID"),
        };
        dispatch(creditListIssues(payload)).then((res) => {
          setIssueDetails(res?.payload);
        });
      }
      setIsRotating(false);
      setShowLoader(false);
    });
  };

  return (
    <ConsentDocsContainer>
      <CustomLoader open={showLoader} />
      <div className="patientList-title-wrapper">
        <h2>Issues</h2>
        <Button
          variant="contained"
          style={{ backgroundColor: "#0089e9" }}
          onClick={() => navigate("/loan/raise-issue")}
        >
          Raise Issue
        </Button>
      </div>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {issueDetails && issueDetails.length > 0 ? (
          issueDetails.map((item, index) => (
            <Card
              key={index}
              sx={{
                minWidth: 300,
                maxWidth: 400,
                margin: "20px auto",
                borderRadius: 3,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => handleClickOpen(index)}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: "bold", color: "primary.main", mb: 1 }}
                >
                  Issue Category: {item.issue_category}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "0.9rem", mb: 1 }}
                >
                  Description: {item.issue_short_desc}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "0.85rem", mb: 0.5 }}
                >
                  ID: {item.issue_id}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "0.85rem" }}
                >
                  Created At: {format(item.created_at, "dd-MM-yyyy HH:mm")}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end", px: 2 }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: "none" }}
                  onClick={() => handleClickOpen(index)}
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          ))
        ) : (
          <Typography variant="h6">No issues raised</Typography>
        )}
      </Box>

      {/* Dialog for issue details remains the same */}
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Issue Details
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {/* Your content here */}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: "10px 30px 10px 0" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseDialog}
          >
            Exit
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="primary"
            disabled={isIssueClosed}
            onClick={handleCloseIssue}
          >
            Close Issue
          </Button>
        </DialogActions>
      </Dialog>
    </ConsentDocsContainer>
  );
};

export default UAIssueStatus;
