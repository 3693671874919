import React, { useEffect, useState } from "react";
import { Backdrop, Stack, styled, Typography } from "@mui/material";
import themePalette from "../../../styles/themePalette";
import Lottie from "lottie-react";
import sandTimer from "../../../styles/LottieFiles/sandTimer.json";

const TimeContainer = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backdropFilter: "blur(4px)",
  padding: "20px",
}));

// Define the keyframes for the dot animation
const ellipsisAnimation = `
  0%, 100% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
`;

const DotsAnimationContainer = styled("span")(({ theme }) => ({
  "&::after": {
    content: '"."',
    display: "inline-block",
    width: "1em",
    animation: `${ellipsisAnimation} 1.5s infinite steps(1, end)`,
  },
}));

const CustomTimer = ({ open, onClose, setShowTimer }) => {
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    let timer = null;

    if (seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else {
      onClose();
      setShowTimer(false);
    }

    return () => clearInterval(timer);
  }, [seconds, onClose, setShowTimer]);

  return (
    <TimeContainer open={open} onClick={onClose}>
      <Stack
        direction={"column"}
        gap={3}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          backgroundColor: "white",
          padding: "30px",
          borderRadius: "15px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          fontSize={"2.3rem"}
          color={themePalette.primary}
          sx={{
            fontFamily: "plus jakarta sans bold",
            textAlign: "center",
          }}
        >
          Please Be Patient
        </Typography>
        <Stack>
          <Lottie animationData={sandTimer} />
          <Typography fontSize={"1.2rem"}>
            {seconds} seconds Remaining{" "}
          </Typography>
        </Stack>

        <Typography
          fontSize={"1.5rem"}
          sx={{ textAlign: "center", maxWidth: "300px" }}
        >
          Please wait a moment while we fetch offers for you !{" "}
        </Typography>
      </Stack>
    </TimeContainer>
  );
};

export default CustomTimer;
