import React, { useContext, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Box,
  FormLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { creditStatusCheck } from "../../slice/creditForm.slice";
import {
  insuranceInit,
  submitInsuranceForm,
} from "../../pages/AuthorizedPages/InsuranceRegistration/insurance.slice";
import CustomLoader from "../CustomComponents/CustomLoader";
import { MyContext } from "../../utils/context";

const ErrorMessageBox = ({ children }) => (
  <div style={{ color: "red", fontSize: "12px" }}>{children}</div>
);

const BuyerForm = () => {
  const offerId = sessionStorage.getItem("offer_id");
  const txnId = sessionStorage.getItem("txnId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  const { setActiveStep } = useContext(MyContext);

  const [buyerInfo, setBuyerInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    gender: "M",
    dob: "",
    politicallyExposedPerson: false,
    gstin: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setBuyerInfo((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const validateFields = () => {
    if (
      !buyerInfo.firstName ||
      !buyerInfo.email ||
      !buyerInfo.phone ||
      !buyerInfo.lastName ||
      !buyerInfo.gender ||
      !buyerInfo.dob
    ) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    setShowLoader(true);
    const payload = {
      txnId: txnId,
      offerId: offerId,
      formData: {
        buyerInfo,
      },
    };

    const id = setInterval(() => {
      dispatch(creditStatusCheck(payload)).then((res) => {
        const response = res?.payload;
        if (response.current_action === "ON_INIT_BUYER_FORM") {
          clearInterval(id);
          dispatch(submitInsuranceForm(payload)).then((res) => {
            if (res?.payload) {
              setTimeout(() => {
                const payload = {
                  txnId: txnId,
                  offerId: offerId,
                };
                dispatch(insuranceInit(payload)).then((res) => {
                  if (res?.error && Object.keys(res?.error)?.length > 0) {
                    setShowLoader(false);
                    return;
                  } else if (
                    res?.payload &&
                    res?.payload[0]?.ack_status === "ACK"
                  ) {
                    setShowLoader(false);
                    setActiveStep(5);
                    navigate("/home/nominee-form");
                  } else {
                    setShowLoader(false);
                    return;
                  }
                });
              }, 5000);
            } else return;
          });
        }
      });
    }, 3000);
  };

  return (
    <Box style={{ padding: "20px" }}>
      <CustomLoader open={showLoader} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          {/* First Name Field */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!buyerInfo.firstName && error}
            >
              <FormLabel>First Name</FormLabel>
              <TextField
                name="firstName"
                value={buyerInfo.firstName}
                onChange={handleChange}
                placeholder="Enter First Name"
                error={!buyerInfo.firstName && error}
              />
              {!buyerInfo.firstName && error && (
                <ErrorMessageBox>Enter First Name</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Last Name Field */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              required
              error={!buyerInfo.lastName && error}
            >
              <FormLabel>Last Name</FormLabel>
              <TextField
                name="lastName"
                value={buyerInfo.lastName}
                onChange={handleChange}
                placeholder="Enter Last Name"
                error={!buyerInfo.lastName && error}
              />
              {!buyerInfo.lastName && error && (
                <ErrorMessageBox>Enter Last Name</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Email Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!buyerInfo.email && error}>
              <FormLabel>Email</FormLabel>
              <TextField
                name="email"
                value={buyerInfo.email}
                onChange={handleChange}
                placeholder="Enter Email"
                error={!buyerInfo.email && error}
              />
              {!buyerInfo.email && error && (
                <ErrorMessageBox>Enter a valid Email</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Phone Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!buyerInfo.phone && error}>
              <FormLabel>Phone Number</FormLabel>
              <TextField
                name="phone"
                value={buyerInfo.phone}
                onChange={handleChange}
                placeholder="Enter Phone Number"
                error={!buyerInfo.phone && error}
              />
              {!buyerInfo.phone && error && (
                <ErrorMessageBox>Enter a valid Phone Number</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Address Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!buyerInfo.address && error}>
              <FormLabel>Address</FormLabel>
              <TextField
                name="address"
                value={buyerInfo.address}
                onChange={handleChange}
                placeholder="Enter Address"
                error={!buyerInfo.address && error}
              />
              {!buyerInfo.address && error && (
                <ErrorMessageBox>Enter a valid Address</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Gender Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!buyerInfo.gender && error}>
              <FormLabel>Gender</FormLabel>
              <Select
                name="gender"
                value={buyerInfo.gender}
                onChange={handleChange}
                error={!buyerInfo.gender && error}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
              </Select>
              {!buyerInfo.gender && error && (
                <ErrorMessageBox>Enter Your Gender</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Date of Birth Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={!buyerInfo.dob && error}>
              <FormLabel>Date of Birth</FormLabel>
              <TextField
                name="dob"
                type="date"
                value={buyerInfo.dob}
                onChange={handleChange}
                error={!buyerInfo.dob && error}
                InputLabelProps={{ shrink: true }}
              />
              {!buyerInfo.dob && error && (
                <ErrorMessageBox>Enter Your Date of Birth</ErrorMessageBox>
              )}
            </FormControl>
          </Grid>

          {/* Politically Exposed Person Checkbox */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="politicallyExposedPerson"
                  checked={buyerInfo.politicallyExposedPerson}
                  onChange={handleChange}
                />
              }
              label="Politically Exposed"
            />
          </Grid>

          {/* GSTIN Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <FormLabel>GSTIN</FormLabel>
              <TextField
                name="gstin"
                value={buyerInfo.gstin}
                onChange={handleChange}
                placeholder="Enter GSTIN"
              />
            </FormControl>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default BuyerForm;
