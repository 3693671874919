import React from "react";
import { Button, Box, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import themePalette from "../../styles/themePalette";

const Unauthorized = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        bgcolor: "background.default",
      }}
    >
      <Box
        sx={{
          mb: 4,
          display: "flex",
          alignItems: "center",
          color: "error.main",
          flexDirection: { xs: "column", sm: "row" },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <LockIcon
          sx={{ fontSize: "5rem", mb: { xs: 2, sm: 0 }, mr: { sm: 1 } }}
        />
        <Typography variant="h3" color="error.main" sx={{ fontSize: "5rem" }}>
          Access Denied
        </Typography>
      </Box>
      <Typography
        variant="body1"
        color="textSecondary"
        sx={{ mb: 3, fontSize: "2.5rem" }}
      >
        Sorry, you do not have permission to access this page.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleNavigation}
        sx={{
          mt: 2,
          textTransform: "none",
          backgroundColor: themePalette.primary,
          fontSize: "1.5rem",
          fontFamily: "Inter",
        }}
      >
        Go Back
      </Button>
    </Container>
  );
};

export default Unauthorized;
