import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  Backdrop,
  FormControl,
  Menu,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
// import { insuranceSearch } from "../../pages/InsuranceRegistration/insurance.slice";
import { MyContext } from "../../../utils/context";
import { RollerShadesClosedOutlined } from "@mui/icons-material";
import themePalette from "../../../styles/themePalette";
import { insuranceSearch } from "../InsuranceRegistration/insurance.slice";

const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  marginTop: 64,
  [theme.breakpoints.down("sm")]: {
    marginTop: 56,
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  marginTop: 64,
  [theme.breakpoints.down("sm")]: {
    marginTop: 56,
  },
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 30px)`,
  },
});

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  height: "fit-screen",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar({ open, setOpen }) {
  const { environment, setEnvironment, setShowLoader } =
    React.useContext(MyContext);
  const user_id = JSON.parse(sessionStorage.getItem("user_details"))?.id;
  const role = sessionStorage.getItem("user_role");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dashboardPath =
    role === "agent"
      ? "/agent-dashboard"
      : role === "customer"
      ? "/user-dashboard"
      : role === "admin"
      ? "/admin-dashboard"
      : role === "manager" && "/manager-dashboard";

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (path) => {
    navigate(`${path}`);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    // setAuth();
    navigate("/");
    // window.location.replace("/login");
    // handleClose();
  };

  const handleCreditClick = () => {
    sessionStorage.setItem("actionType", "credit");
    navigate("/loan/credit-form");
  };

  const handleInsuranceClick = () => {
    setShowLoader(true);
    const payload = {
      environment: environment,
      user_id: user_id,
    };
    dispatch(insuranceSearch(payload)).then((response) => {
      if (response?.payload[0]?.ack_status === "ACK") {
        sessionStorage.setItem("environment", environment);
        sessionStorage.setItem("txnId", response?.payload[0]?.txn_id);
        setShowLoader(false);
        sessionStorage.setItem("actionType", "insurance");
        navigate("/home/insurance-registration");
      } else return;
    });
  };

  const handleEnvironmentChange = (e) => {
    setEnvironment(e.target.value);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: "white" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 5, ...(open && { display: "none" }), ml: 0.5 }}
          >
            <MenuIcon sx={{ color: themePalette?.primary }} />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
            edge="start"
            sx={{ marginRight: 5, ...(!open && { display: "none" }), ml: 0.5 }}
          >
            <MenuIcon sx={{ color: themePalette?.primary }} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            fontFamily={"Red Hat Display"}
            sx={{
              color: "black",
              fontSize: "24px",
              textAlign: isMobile ? "center" : "left",
              fontWeight: 600,
              letterSpacing: "1px",
            }}
          >
            CliniQ360
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <div style={{ textAlign: "right" }}>
            <FormControl>
              <Select
                size="small"
                sx={{ mx: 2 }}
                value={environment}
                onChange={handleEnvironmentChange}
              >
                <MenuItem value="PREPROD">PREPROD</MenuItem>
                <MenuItem value="STAGING">STAGING</MenuItem>
                <MenuItem value="PROD">PROD</MenuItem>
              </Select>
            </FormControl>
          </div>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircleOutlinedIcon sx={{ color: themePalette?.primary }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={() => navigate("/reset-password")}>
              Change Password
            </MenuItem>
            <MenuItem onClick={handleLogout}>LogOut</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {(!isMobile || open) && (
        <Drawer variant={"permanent"} open={open}>
          <List>
            {[
              {
                text: "Dashboard",
                icon: (
                  <HomeOutlinedIcon sx={{ color: themePalette?.primary }} />
                ),
                path: dashboardPath,
              },
              {
                text: "Credit",
                icon: (
                  <CreditScoreOutlinedIcon
                    sx={{ color: themePalette?.primary }}
                  />
                ),
                action: handleCreditClick,
              },
              {
                text: "Insurance",
                icon: <HealthAndSafetyOutlinedIcon sx={{ color: "#0561A0" }} />,
                action: handleInsuranceClick,
              },
            ].map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  onClick={
                    item.action ? item.action : () => navigateTo(item.path)
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
      {isMobile && open && <Backdrop open onClick={handleDrawerClose} />}
    </Box>
  );
}
