import React, { useEffect, useState } from "react";
import { Avatar, styled, Typography } from "@mui/material";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import { getAllCustomer } from "../../../slice/credit.slice";
import CustomDataGrid from "../../../components/CustomComponents/CustomDataGrid";

const DetailsHeaderContainer = styled("div")(({ theme, type }) => ({
  "&": {
    padding: type === "header" ? theme.spacing(5, 6) : theme.spacing(4, 2),
    backgroundColor: type === "header" ? "white" : "lightGrey",
    // marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  ".details-header": {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  ".details-avatar-container": {
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  ".details-Patientdetails": {
    padding: type === "header" ? theme.spacing(0, 6) : theme.spacing(0),
    // borderRight: `1px solid ${theme.palette.primaryGrey}`,
    [theme.breakpoints.down("md")]: {
      display: "inline",
      borderRight: "0",
    },
  },
  ".details-emailContainer": {
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("sm")]: {
      display: "inline",
      marginBottom: "10px",
    },
  },
  ".details-subContainer": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  ".details-patient-name": {
    "&.MuiTypography-root": theme.typography.h3,
  },
  ".details-patient-id": {
    "&.MuiTypography-root": theme.typography.body4,
  },
  "details-patient-email": {
    "&.MuiTypography-root": theme.typography.body3,
  },
  displayDocuments: {
    display: "block",
  },
  ".documents-subContainer": {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block ",
    },
  },
}));

const ConsentDocsContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 2),
}));

const SubHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primaryWhite,
  padding: theme.spacing(0),
}));

const AgentDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const agentDetails = location?.state?.agentDetails;
  const user = JSON.parse(sessionStorage.getItem("user_details"));
  const [showLoader, setShowLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  const handleRowClick = (row) => {
    sessionStorage.setItem("selectedCustomerID", JSON.stringify(row?.id));
    navigate("/user-dashboard", {
      state: { customerDetails: row },
    });
  };

  const agentId =
    user?.user_role === "admin" || user?.user_role === "manager"
      ? agentDetails?.id
      : user?.id;

  useEffect(() => {
    setShowLoader(true);
    const payload = {
      userId: agentId,
    };
    dispatch(getAllCustomer(payload)).then((res) => {
      const formattedTxnList = res?.payload?.map((item) => {
        const createdAt = format(item?.created_at, "dd-MM-yyyy hh:mm aaaaa'm'");
        const updatedAt = format(item?.updated_at, "dd-MM-yyyy hh:mm aaaaa'm'");
        return {
          createdAt: createdAt,
          updatedAt: updatedAt,
          ...item,
        };
      });

      setTableData(formattedTxnList);
      setShowLoader(false);
    });
  }, []);

  const tableColumns = [
    { key: "id", header: "ID" },
    { key: "username", header: "Username" },
    { key: "name", header: "Name" },
    { key: "createdAt", header: "Registered On" },
  ];

  return (
    <>
      <ConsentDocsContainer>
        <CustomLoader open={showLoader} />
        <DetailsHeaderContainer type={"header"}>
          <div className="details-header">
            <div className="details-avatar-container">
              <Avatar />
            </div>
            <div className="details-Patientdetails">
              <Typography className="details-patient-name">
                {user?.name}
              </Typography>
              <div className="details-subContainer">
                <Typography className="details-patient-id">
                  {user?.user_role}
                </Typography>
              </div>
            </div>
            <div className="details-emailContainer">
              <Typography className="details-patient-email">
                {user?.dob}
              </Typography>
              <Typography className="details-patient-email">
                {user?.mobile_number}
              </Typography>
            </div>
          </div>
        </DetailsHeaderContainer>

        <div className="documents-subContainer" style={{ margin: "20px" }}>
          <SubHeaderContainer>
            <Typography
              variant="h4"
              m={3}
              fontWeight={"bold"}
              fontFamily={"Red Hat Display"}
              flex={1}
            >
              Customer List
            </Typography>
            {user?.user_role === "admin" ||
              (user?.user_role === "manager" && (
                <DetailsHeaderContainer type={"subHeader"}>
                  <div className="details-header">
                    <div className="details-Patientdetails">
                      <Typography className="details-patient-name">
                        {agentDetails?.name}
                      </Typography>
                      <div className="details-subContainer">
                        <Typography className="details-patient-id">
                          {agentDetails?.user_role}
                        </Typography>
                      </div>
                    </div>
                    <div className="details-emailContainer">
                      <Typography className="details-patient-email">
                        {agentDetails?.dob}
                      </Typography>
                      <Typography className="details-patient-email">
                        {agentDetails?.mobile_number}
                      </Typography>
                    </div>
                  </div>
                </DetailsHeaderContainer>
              ))}
          </SubHeaderContainer>
        </div>
        {tableData?.length > 0 ? (
          <CustomDataGrid
            columns={tableColumns}
            rows={tableData}
            onRowClick={(row) => handleRowClick(row)}
            enableRowClick={true}
            rowId={(row) => row.id}
          />
        ) : (
          <Typography>No Data Found</Typography>
        )}
      </ConsentDocsContainer>
    </>
  );
};

export default AgentDashboard;
