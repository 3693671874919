import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { MyContext } from "../../../utils/context";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  creditConfirm,
  creditInit,
  creditStatusCheck,
  getFormUrl,
} from "../../../slice/creditForm.slice";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import themePalette from "../../../styles/themePalette";

const KYCWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(5),
}));

const useStyles = {
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  planeImage: {
    width: "50px",
    height: "50px",
    transition: "transform 1s ease-in-out",
  },
  planeImageFlying: {
    transform: "translateX(250px) rotate(-20deg)",
  },
};

const EMandatePages = () => {
  /* ADDED INITALIZATION */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeStep, setActiveStep } = useContext(MyContext);
  const [progress, setProgress] = useState(0);
  const [offer, setOffer] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [openEventStatusDialog, setOpenEventStatusDialog] = useState(false);
  const user_role = sessionStorage.getItem("user_role");
  const txnId = sessionStorage.getItem("txnId");
  const offerId = sessionStorage.getItem("offer_id");
  const [page, setPage] = useState("");
  const [formUrl, setFormUrl] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [funcStep, setFuncStep] = useState(0);
  const [isFlying, setIsFlying] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [openURlError, setOpenURlError] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);

  let form_emandate_URL;

  //EMANDATE PAGE FUNCTIONALITY HERE!!
  const handleEMandate = async (retryCount = 0) => {
    setFuncStep(3);
    setShowLoader(true);

    const payload = {
      txnId: txnId,
      offerId: offerId,
    };

    if (retryCount >= 3) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setOpenURlError(true);
      setShowLoader(false);
      clearInterval(id);
      return;
    }

    const checkStatus = () => {
      // Stop the interval if retryCount exceeds 3

      dispatch(creditStatusCheck(payload)).then((statusResponse) => {
        if (statusResponse?.payload?.current_action === "ON_INIT_EMNDT") {
          clearInterval(id);
          setProgress(progress + 40);
          dispatch(getFormUrl(payload)).then((res) => {
            if (res?.payload?.form_url) {
              setConfirmationDialog(true);
              setFormUrl(res?.payload?.form_url);
              setPage("E-Mandate");
            }
            setShowLoader(false);
          });
        } else {
          console.log("Not ON_INIT_EMNDT");
          clearInterval(id);
          // Recursively retry with incremented retry count
          handleEMandate(retryCount + 1);
        }
      });
    };

    const id = setInterval(checkStatus, 3000);
  };

  //here we are checking the emadate GTS status
  const checkEMNDTStatus = async (retryEMNDTCount = 0) => {
    setFuncStep(4);
    setShowLoader(true);
    if (retryEMNDTCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setOpenURlError(true);
      setShowLoader(false);
    }
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((statusResponse) => {
        setProgress(progress + 20);
        if (statusResponse?.payload?.redirection_status === "EMNDT_APPROVED") {
          setProgress(progress + 20);
          setConfirmationDialog(false);
          if (form_emandate_URL) {
            form_emandate_URL.close();
          }
          const initPayload = {
            txnId: txnId,
            offerId: offerId,
          };
          const delay = 5000;

          const init_call = () => {
            dispatch(creditInit(initPayload)).then((initResponse) => {
              // setShowLoader(false);
              if (initResponse?.payload?.txn_id) {
                setTimeout(getAgrmntUrl, delay);
              }
            });
          }; // 20000
          setTimeout(init_call, delay);
        } else if (
          statusResponse?.payload?.redirection_status === "EMNDT_REJECTED"
        ) {
          form_emandate_URL.close();
          setDialogMessage("Emandate failed");
          setOpenEventStatusDialog(true);
          setShowLoader(false);
        } else {
          console.log("Not EMNDT_APPROVED");
          // Make the API call again with incremented retryEMNDTCount
          checkEMNDTStatus(retryEMNDTCount + 1);
        }
      });
    }, 5000);
  };
  //After the emandate is succussfull we'll get another URl for Loan payment
  const getAgrmntUrl = async (retryCount = 0) => {
    setFuncStep(5);
    setShowLoader(true);
    if (retryCount >= 3) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setOpenErrorDialog(true);
      setShowLoader(false);
      return;
    }
    setProgress(progress + 40);
    setTimeout(() => {
      dispatch(creditStatusCheck({ txnId: txnId })).then((statusResponse) => {
        if (statusResponse?.payload?.current_action === "ON_INIT_LOAN_AGRMT") {
          dispatch(getFormUrl({ txnId: txnId, offerId: offerId })).then(
            (res) => {
              if (res?.payload?.form_url) {
                setFormUrl(res?.payload?.form_url);
                setConfirmationDialog(true);
                setPage("Loan payment");
              }
            }
          );
        } else {
          console.log("Not ON_INIT_LOAN_AGRMT");
          // Make the API call again with incremented retryCount
          getAgrmntUrl(retryCount + 1);
        }
      });
    }, 5000);
  };
  //After the emandate is succussfull we'll call GTS status for Loan Payment
  const checkARGMNTStatus = async (retryCount = 0) => {
    setFuncStep(6);
    setShowLoader(true);
    if (retryCount >= 50) {
      console.log(
        "Maximum retry limit reached. Unable to get desired response."
      );
      setOpenErrorDialog(true);
      setShowLoader(false);
      return;
    }

    let confirm = false;
    const payload = {
      txnId: txnId,
      offerId: offerId,
    };
    setProgress(progress + 15);

    const id = setInterval(() => {
      dispatch(creditStatusCheck(payload)).then((statusResponse) => {
        if (
          statusResponse?.payload?.redirection_status === "LOAN_AGRMT_APPROVED"
        ) {
          clearInterval(id);
          setConfirmationDialog(false);
          setProgress(progress + 15);
          if (form_emandate_URL) {
            form_emandate_URL.close();
          }
          setConfirmStatus(true);
          confirm = true;

          if (confirm) {
            dispatch(creditConfirm(payload)).then((res) => {
              if (res?.payload?.txn_id) {
                setProgress(progress + 10);
                setShowLoader(false);
                navigate("/loan/order-details");
              }
            });
          }
        } else if (
          statusResponse?.payload?.redirection_status === "LOAN_AGRMT_REJECTED"
        ) {
          clearInterval(id);
          if (form_emandate_URL) {
            form_emandate_URL.close();
            setConfirmationDialog(false);
          }
          setDialogMessage("Loan agreement process failed");
          setOpenEventStatusDialog(true);
          setShowLoader(false);
        } else {
          console.log("Not ON_INIT_LOAN_AGRMT");
          clearInterval(id);
          setTimeout(() => checkARGMNTStatus(retryCount + 1), 3000);
        }
      });
    }, 3000);
  };

  /*DIALOG FUNCTIONALITY HERE */

  //We are implementing the functionality for dialog box here
  const handleDialogSubmit = (value) => {
    if (value === "YES") {
      setConfirmation(true);
      setIsFlying(true);
      setProgress(progress + 20);
      setTimeout(() => {
        setConfirmationDialog(false);
        setIsFlying(false);
      }, 1000);
      setTimeout(() => {
        form_emandate_URL = window.open(formUrl, "_blank");
      }, 1800);
      if (page === "E-Mandate") {
        setShowLoader(false);
        checkEMNDTStatus();
      } else if (page === "Loan payment") {
        setShowLoader(false);
        checkARGMNTStatus();
      } else {
        setShowLoader(false);
        setConfirmationDialog(false);
        return;
      }
    } else {
      setShowLoader(false);
      setConfirmationDialog(false);
      return;
    }
  };

  const handleRejection = () => {
    if (user_role === "guest") {
      setOpenEventStatusDialog(false);
      navigate(location.pathname);
    } else {
      setOpenEventStatusDialog(false);
      navigate("/home/dashboard");
    }
  };
  return (
    <>
      <CustomLoader open={showLoader} />
      <Stack>
        <KYCWrapper>
          <Stack gap={4}>
            <Typography
              sx={{
                fontSize: "1.4rem",
                fontFamily: "Plus Jakarta Sans bold",
              }}
            >
              E-Mandate
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                color: "#535353",
                fontFamily: "source sans pro",
              }}
            >
              By clicking "Setup E-Mandate", you consent to use your account
              information for setting up an eMandate as a mode of payment for
              your loan.
            </Typography>
          </Stack>
          <Stack justifyContent={"center"} alignItems={"flex-end"} mt={2}>
            <Button
              onClick={handleEMandate}
              size="medium"
              variant="contained"
              sx={{
                backgroundColor: themePalette.primary,
                textTransform: "none",
                fontFamily: "Plus Jakarta Sans, bold",
              }}
            >
              Proceed
            </Button>
          </Stack>
        </KYCWrapper>
      </Stack>
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogContent style={useStyles.dialogContent}>
          <img
            src="https://pngfre.com/wp-content/uploads/Airplane-4.png"
            alt="Plane"
            style={{
              ...useStyles.planeImage,
              ...(isFlying ? useStyles.planeImageFlying : {}),
            }}
          />
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            Please don't exit or press back.
            <br />
            Please wait while we are redirecting you to the Verification Page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogSubmit("YES")}>Proceed</Button>
          {/* <Button onClick={() => handleDialogSubmit("NO")}>No</Button> */}
        </DialogActions>
      </Dialog>

      {/* ERROR DIALOG*/}
      <Dialog
        open={openEventStatusDialog}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogContent>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              sx={{
                fontFamily: "plus jakarta sans bold",
                fontSize: "2.8rem",
                color: "#784af4",
              }}
            >
              Oops!
            </Typography>
          </Stack>
          <Typography>
            Something went wrong while processing your request. Please try again
            by clicking the "Retry" button below.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            onClick={handleRejection}
            sx={{
              backgroundColor: "#784af4",
              textTransform: "none",
              fontFamily: "Plus Jakarta Sans, bold",
            }}
          >
            Retry
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EMandatePages;
