import * as React from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { createTheme } from "@mui/material";
const speedDialTheme = createTheme({
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
});
export default function SpeedDialTooltipOpen({ actions, open, setOpen }) {
  const handleOpen = (event, reason) => {
    if (reason === "toggle") {
      setOpen(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "toggle") {
      setOpen(false);
    }
  };
  const handleActionClick = (actions) => {
    actions();
    setOpen(false);
  };

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        color="white"
        sx={{ position: "fixed", top: 180, right: 16 }}
        icon={<SpeedDialIcon sx={{ color: "white" }} />}
        onClose={handleClose}
        onOpen={handleOpen}
        direction="down"
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            sx={{ marginBottom: "25px" }}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleActionClick(action.actions)}
            tooltipPlacement="left"
          />
        ))}
      </SpeedDial>
    </>
  );
}
