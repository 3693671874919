import {
  Box,
  MenuItem,
  FormControl,
  Select,
  styled,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { creditIssue } from "../../../slice/creditForm.slice";

const IssueWrapper = styled("div")(({ theme }) => ({
  "&": {
    padding: "15px",
    fontFamily: "Source Sans Pro",
  },
}));

const CreditIssues = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_role = sessionStorage.getItem("user_role");

  const [selectedCategory, setSelectedCategory] = useState("FULFILLMENT");
  const [subcategory, setSubcategory] = useState([
    {
      value: "FLM01",
      label: "Delay in disbursement/not disbursed",
    },
    {
      value: "FLM02",
      label: "Incorrect amount disbursed",
    },
    {
      value: "FLM202",
      label: "Not able to complete the KYC",
    },
    {
      value: "FLM203",
      label: "Not able to set up E-mandate",
    },
    {
      value: "FLM204",
      label: "OTP not received during the e-sign of agreement",
    },
    {
      value: "FLM205",
      label: "Not able to view the agreement",
    },
    {
      value: "FLM206",
      label: "Need to update the e-mandate details",
    },
    {
      value: "FLM207",
      label: "Feedback on collection call",
    },
    {
      value: "FLM208",
      label: "Stop Marketing Communications",
    },
    {
      value: "FLM209",
      label: "Request for documents",
    },
    {
      value: "FLM210",
      label: "Need to update personal details",
    },
    {
      value: "FLM211",
      label: "Revoke consent already granted to collect personal data",
    },
    {
      value: "FLM212",
      label: "Delete/forget existing data against my profile",
    },
  ]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("FLM01");

  const handleCategoryChange = (e) => {
    const selected_category = e.target.value;
    // sessionStorage.removeItem("issue_subcategory");
    setSelectedCategory(selected_category);
    // Logic to populate subFruits based on selected_category
    if (selected_category === "FULFILLMENT") {
      sessionStorage.setItem("issueCategory", "FULFILLMENT");
      setSubcategory([
        {
          value: "FLM01",
          label: "Delay in disbursement/not disbursed",
        },
        {
          value: "FLM02",
          label: "Incorrect amount disbursed",
        },
        {
          value: "FLM202",
          label: "Not able to complete the KYC",
        },
        {
          value: "FLM203",
          label: "Not able to set up E-mandate",
        },
        {
          value: "FLM204",
          label: "OTP not received during the e-sign of agreement",
        },
        {
          value: "FLM205",
          label: "Not able to view the agreement",
        },
        {
          value: "FLM206",
          label: "Need to update the e-mandate details",
        },
        {
          value: "FLM207",
          label: "Feedback on collection call",
        },
        {
          value: "FLM208",
          label: "Stop Marketing Communications",
        },
        {
          value: "FLM209",
          label: "Request for documents",
        },
        {
          value: "FLM210",
          label: "Need to update personal details",
        },
        {
          value: "FLM211",
          label: "Revoke consent already granted to collect personal data",
        },
        {
          value: "FLM212",
          label: "Delete/forget existing data against my profile",
        },
      ]);
    } else if (selected_category === "PAYMENT") {
      sessionStorage.setItem("issueCategory", "PAYMENT");
      setSubcategory([
        {
          value: "PMT01",
          label: "EMI not deducted",
        },
        {
          value: "PMT02",
          label: "Incorrect amount debited against the EMI",
        },
        {
          value: "PMT03",
          label: "EMI deducted twice",
        },
        {
          value: "PMT04",
          label: "Automatic debits not canceled after loan closure",
        },
        {
          value: "PMT05",
          label: "Issues with payment receipts and acknowledgment",
        },
        {
          value: "PMT06",
          label: "Unable to prepay or make partpay",
        },
        {
          value: "PMT07",
          label: "Prepayment not reflecting in loan summary",
        },
        {
          value: "PMT08",
          label: "Incorrect fees charged even during the cool-off period",
        },
        {
          value: "PMT09",
          label:
            "Loan completion certificate/NOC not received after full payment",
        },
        {
          value: "PTM09",
          label: "RoI related issues",
        },
        {
          value: "PTM10",
          label: "Fees/Charges related issues",
        },
      ]);
    } else if (selected_category === "ORDER") {
      sessionStorage.setItem("issueCategory", "ORDER");
      setSubcategory([
        {
          value: "ORD01",
          label: "Incorrect info on the credit report",
        },
        {
          value: "ORD02",
          label: "Delay in updating payment information",
        },
        {
          value: "ORD03",
          label: "Missing or lost loan documents",
        },
        {
          value: "ORD04",
          label: "Errors in loan agreements or contracts",
        },
      ]);
    }
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
    // sessionStorage.setItem("issue_subcategory", e.target.value);
  };

  const handleSubmit = () => {
    const payload = {
      txnId: sessionStorage.getItem("txnId"),
      orderId: sessionStorage.getItem("orderId"),
      action: "OPEN",
      category: selectedCategory,
      subCategory: selectedSubcategory,
    };
    dispatch(creditIssue(payload)).then((res) => {
      navigate(user_role === "guest" ? "/loan/issue-status" : "/issue-status");
    });
  };

  return (
    <IssueWrapper>
      <h2>Raise Issue</h2>
      <br />
      <h3>Select Category</h3>
      <Box sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Select Category</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCategory}
            // label="Select Category"
            onChange={handleCategoryChange}
          >
            <MenuItem value="FULFILLMENT">FULFILLMENT</MenuItem>
            <MenuItem value="PAYMENT">PAYMENT</MenuItem>
            <MenuItem value="ORDER">ORDER</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <br />
      <h3>Select Sub Category</h3>
      <Box sx={{ minWidth: 200 }}>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Select Sub Category</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedSubcategory}
            // label="Select Sub Category"
            onChange={handleSubcategoryChange}
          >
            {subcategory?.map((item, index) => (
              <MenuItem key={index} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <br />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </IssueWrapper>
  );
};

export default CreditIssues;
