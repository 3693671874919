import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";

const ContactDetailWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
}));

const ErrorMessageBox = styled(FormHelperText)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  color: theme.palette.error.main,
}));

const ContactDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showLoader, setShowLoader] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [error, setError] = useState(false);

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Indian phone number validation
    return phoneRegex.test(number);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (!validatePhoneNumber(value) && value.length > 0) {
      setError(true);
    } else {
      setError(false);
    }
    setContactNumber(value);
  };

  const handleNextSubmit = (e) => {
    e.preventDefault();
    if (!validatePhoneNumber(contactNumber)) {
      setError(true);
      return;
    }
    setShowLoader(true);
    // Dispatch or navigate logic goes here
    console.log(contactNumber);
    setShowLoader(false);
  };

  return (
    <>
      <CustomLoader open={showLoader} />
      <ContactDetailWrapper>
        <Stack spacing={4}>
          <Stack justifyContent="center" alignItems="center">
            <Typography
              variant="h5"
              component="h1"
              sx={{
                fontSize: "1.4rem",
                fontFamily: "Plus Jakarta Sans, bold",
              }}
            >
              Start Your Journey By Entering Your Mobile Number
            </Typography>
          </Stack>
          <form onSubmit={handleNextSubmit}>
            <FormControl fullWidth error={error}>
              <FormLabel htmlFor="contactNumber" sx={{ mb: 1 }}>
                Contact Number
              </FormLabel>
              <TextField
                id="contactNumber"
                name="contactNumber"
                value={contactNumber}
                onChange={handleInputChange}
                placeholder="Enter Contact Number"
                fullWidth
                required
                inputProps={{ maxLength: 10, "aria-label": "contact number" }}
                error={error}
                helperText={
                  error ? "Please enter a valid 10-digit mobile number." : ""
                }
              />
            </FormControl>
            <Stack justifyContent="center" alignItems="flex-end" mt={3}>
              <Button
                size="medium"
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#784af4",
                  textTransform: "none",
                  fontFamily: "Plus Jakarta Sans, bold",
                }}
                disabled={!validatePhoneNumber(contactNumber)}
              >
                Start Your Journey
              </Button>
            </Stack>
          </form>
        </Stack>
      </ContactDetailWrapper>
    </>
  );
};

export default ContactDetailsPage;
