import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Autocomplete,
  FormHelperText,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { State, City } from "country-state-city";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import themePalette from "../../../../styles/themePalette";

const PersonalDetailsContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ErrorMessageBox = styled(FormHelperText)(({ theme }) => ({
  margin: "4px 0",
}));

const CustomLineContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 20,
  alignItems: "center",
  justifyContent: "center",
}));

const CustomLine = styled(Box)(({ theme, active }) => ({
  border: active ? "2px solid #0089E9" : "2px solid #d7d7d7",
  borderRadius: "10px",
  width: "20%",
}));

export default function CustomerDetailsForm({
  formData,
  updateFormData,
  activeStep,
  setActiveStep,
  setShowSnackbar,
  setPopoverOpen,
}) {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [error, setError] = useState(false);
  const [value, setValue] = useState("Personal Details");
  const [contactError, setContactError] = useState(false);

  const handleContinue = () => {
    const hasError = checkError(value);
    if (!hasError) {
      if (value === "Address Details") {
        const nextStep = activeStep + 1;
        setActiveStep(nextStep);
      }
      const newValue =
        value === "Personal Details"
          ? "Address Details"
          : value === "Address Details"
          ? "Contact Details"
          : value === "Contact Details"
          ? "Proffesional Details"
          : "";
      setValue(newValue);
      setError(false);
    } else {
      setShowSnackbar(true);
    }
  };

  useEffect(() => {
    setStates(State.getStatesOfCountry("IN"));
  }, []);

  useEffect(() => {
    if (formData && formData?.state) {
      const selectedState = states.find(
        (state) => state.name === formData?.state
      );
      if (selectedState) {
        setCities(City.getCitiesOfState("IN", selectedState.isoCode));
      }
    }
  }, [formData?.state, states]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedFormData = { ...formData, [name]: value };

    // If the contactNumber field is updated, update the aa_id field as well
    if (name === "contactNumber") {
      const removeZero = value.replace(/^0+/, "");
      updatedFormData = {
        ...updatedFormData,
        aa_id: removeZero + "@finvu",
        contactNumber: removeZero,
      };
    }
    // console.log(updatedFormData);

    updateFormData(updatedFormData);
  };

  const handleAutoCompleteChange = (event, value, name) => {
    let updatedFormData = { ...formData, [name]: value };

    // Reset city if state is updated
    if (name === "state") {
      updatedFormData = { ...updatedFormData, city: "" };
    }
    // console.log(updatedFormData);
    updateFormData(updatedFormData);
  };

  const handleRadioChange = (event) => {
    updateFormData({ ...formData, gender: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const hasError = checkError(value);
    if (!hasError) {
      setPopoverOpen(true);
    } else {
      setShowSnackbar(true);
    }
  };

  const handleBack = () => {
    if (value === "Contact Details") {
      const prevStep = activeStep - 1;
      setActiveStep(prevStep);
    }
    const newValue =
      value === "Proffesional Details"
        ? "Contact Details"
        : value === "Contact Details"
        ? "Address Details"
        : value === "Address Details"
        ? "Personal Details"
        : "";
    setValue(newValue);
  };

  const checkError = (value) => {
    const loanForm = formData;
    if (value === "Personal Details") {
      if (
        !loanForm?.firstName ||
        !loanForm?.lastName ||
        !loanForm?.dob ||
        !loanForm?.gender ||
        !loanForm?.pan
      ) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000);
        return true;
      }
    } else if (value === "Address Details") {
      if (
        !loanForm?.addressL1 ||
        !loanForm?.addressL2 ||
        !loanForm?.city ||
        !loanForm?.state ||
        !loanForm?.pincode
      ) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000);
        return true;
      }
    } else if (value === "Contact Details") {
      if (!loanForm?.contactNumber || !loanForm?.email) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000);
        return true;
      }
    } else if (value === "Proffesional Details") {
      if (
        !loanForm?.companyName ||
        !loanForm?.officialEmail ||
        !loanForm?.employmentType ||
        !loanForm?.income ||
        !loanForm?.aa_id
      ) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000);
        return true;
      }
    }
    return false;
  };

  const handleContactNumberChange = (event) => {
    const { name, value } = event.target;

    // Check if the value contains only numeric characters
    const numericValue = value.replace(/\D/g, ""); // Remove any non-numeric characters
    let updatedFormData = { ...formData, [name]: numericValue };
    const removeZero = numericValue.replace(/^0+/, ""); // Remove leading zeros

    // Check if the contact number is valid (only digits and 10 digits long)
    if (removeZero.length !== 10) {
      setContactError(true);
    } else {
      setContactError(false);
    }

    updatedFormData = {
      ...updatedFormData,
      aa_id: removeZero + "@finvu",
      contactNumber: removeZero,
    };

    updateFormData(updatedFormData); // Update form data with the validated numeric value
  };

  return (
    <PersonalDetailsContainer>
      <TabContext value={value}>
        <CustomLineContainer>
          <CustomLine active={value === "Personal Details"} />
          <CustomLine active={value === "Address Details"} />
          <CustomLine active={value === "Contact Details"} />
          <CustomLine active={value === "Proffesional Details"} />
        </CustomLineContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: "10px 5px",
          }}
        >
          <Typography variant="h6">{value}</Typography>
        </Box>
        <TabPanel value="Personal Details">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.firstName && error}
              >
                <FormLabel>First Name</FormLabel>
                <TextField
                  name="firstName"
                  value={formData?.firstName}
                  onChange={handleInputChange}
                  placeholder="Enter First Name"
                  error={!formData?.firstName && error}
                />

                {!formData?.firstName && error ? (
                  <ErrorMessageBox>Enter First Name</ErrorMessageBox>
                ) : (
                  <ErrorMessageBox>
                    First Name should be as per PAN
                  </ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.lastName && error}
              >
                <FormLabel>Last Name</FormLabel>
                <TextField
                  name="lastName"
                  value={formData?.lastName}
                  onChange={handleInputChange}
                  placeholder="Enter Last Name"
                  error={!formData?.lastName && error}
                />

                {!formData?.lastName && error ? (
                  <ErrorMessageBox>Enter Last Name</ErrorMessageBox>
                ) : (
                  <ErrorMessageBox>
                    Last Name should be as per PAN
                  </ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth required error={!formData?.dob && error}>
                <FormLabel>Date of Birth</FormLabel>
                <TextField
                  name="dob"
                  type="date"
                  value={formData?.dob}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: true }}
                  placeholder="MM/DD/YYYY"
                  error={!formData?.dob && error}
                />
                {!formData?.dob && error && (
                  <ErrorMessageBox>Enter DOB</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.gender && error}
              >
                <FormLabel>Gender</FormLabel>
                <RadioGroup
                  row
                  value={formData?.gender}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
                {!formData?.gender && error && (
                  <ErrorMessageBox>Enter Gender</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth required error={!formData?.pan && error}>
                <FormLabel>PAN</FormLabel>
                <TextField
                  name="pan"
                  value={formData?.pan}
                  onChange={handleInputChange}
                  placeholder="Enter PAN Number"
                  error={!formData?.pan && error}
                />
                {!formData?.pan && error && (
                  <ErrorMessageBox>Enter PAN</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="Address Details">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.addressL1 && error}
              >
                <FormLabel>Address 1</FormLabel>
                <TextField
                  required
                  name="addressL1"
                  value={formData?.addressL1}
                  onChange={handleInputChange}
                  placeholder="Enter Address 1"
                  error={!formData?.addressL1 && error}
                />
                {!formData?.addressL1 && error && (
                  <ErrorMessageBox>Enter Address Line 1</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.addressL2 && error}
              >
                <FormLabel>Address 2</FormLabel>
                <TextField
                  name="addressL2"
                  value={formData?.addressL2}
                  onChange={handleInputChange}
                  placeholder="Enter Address 2"
                  error={!formData?.addressL2 && error}
                />
                {!formData?.addressL2 && error && (
                  <ErrorMessageBox>Enter Address Line 2</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl
                  fullWidth
                  required
                  error={!formData?.state && error}
                  sx={{ ml: 2, width: "100%" }}
                >
                  <FormLabel>State</FormLabel>
                  <Autocomplete
                    required
                    value={
                      states.find((state) => state.name === formData?.state) ||
                      null
                    }
                    options={states}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) =>
                      handleAutoCompleteChange(
                        event,
                        value ? value.name : "",
                        "state"
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!formData?.state && error}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        required
                      />
                    )}
                  />
                  {!formData?.state && error && (
                    <ErrorMessageBox>Select State</ErrorMessageBox>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  fullWidth
                  required
                  error={!formData?.city && error}
                  sx={{ ml: 2, width: "100%" }}
                >
                  <FormLabel>City</FormLabel>
                  <Autocomplete
                    required
                    value={
                      cities.find((city) => city.name === formData?.city) ||
                      null
                    }
                    options={cities}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) =>
                      handleAutoCompleteChange(
                        event,
                        value ? value.name : "",
                        "city"
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!formData?.city && error}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        required
                      />
                    )}
                    disabled={!formData?.state}
                  />
                  {!formData?.city && error && (
                    <ErrorMessageBox>Select City</ErrorMessageBox>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.pincode && error}
              >
                <FormLabel>Pincode</FormLabel>
                <TextField
                  error={!formData?.pincode && error}
                  required
                  name="pincode"
                  type="number"
                  value={formData?.pincode}
                  onChange={handleInputChange}
                  placeholder="Enter Pincode"
                />
                {!formData?.pincode && error && (
                  <ErrorMessageBox>Enter Pincode</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="Contact Details">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth required error={!formData?.email && error}>
                <FormLabel>Personal Email</FormLabel>
                <TextField
                  name="email"
                  type="email"
                  value={formData?.email}
                  onChange={handleInputChange}
                  placeholder="Enter Email (e.g., example@example.com)"
                  error={!formData?.email && error}
                />
                {!formData?.email && error && (
                  <ErrorMessageBox>Enter email</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.contactNumber && error}
              >
                <FormLabel>Contact</FormLabel>
                <TextField
                  name="contactNumber"
                  value={formData?.contactNumber}
                  onChange={handleContactNumberChange}
                  placeholder="Enter Contact Number"
                  error={!formData?.contactNumber && error}
                />
                {!formData?.contactNumber && error ? (
                  <ErrorMessageBox>Enter Contact Number</ErrorMessageBox>
                ) : (
                  contactError && (
                    <ErrorMessageBox>
                      Enter a valid 10 digit Contact Number
                    </ErrorMessageBox>
                  )
                )}
              </FormControl>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="Proffesional Details">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.employmentType && error}
              >
                <FormLabel>Employment Type</FormLabel>

                <Autocomplete
                  value={formData?.employmentType || null}
                  options={["Salaried", "Self Employment"]}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) =>
                    handleAutoCompleteChange(event, value, "employmentType")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!formData?.employmentType && error}
                    />
                  )}
                />
                {!formData?.employmentType && error && (
                  <ErrorMessageBox>Select Employment Type</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.companyName && error}
              >
                <FormLabel>
                  {formData?.employmentType === "Salaried"
                    ? " Company Name"
                    : "Occupation"}
                </FormLabel>
                <TextField
                  name="companyName"
                  value={formData?.companyName}
                  onChange={handleInputChange}
                  placeholder={`Enter ${
                    formData?.employmentType === "Salaried"
                      ? " Company"
                      : "Occupation"
                  } Name`}
                  error={!formData?.companyName && error}
                />
                {!formData?.companyName && error && (
                  <ErrorMessageBox>Enter Company Name</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>{" "}
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.officialEmail && error}
              >
                <FormLabel>Office Email</FormLabel>
                <TextField
                  name="officialEmail"
                  type="email"
                  value={formData?.officialEmail}
                  onChange={handleInputChange}
                  placeholder="Enter Office Email"
                  error={!formData?.officialEmail && error}
                />
                {!formData?.officialEmail && error && (
                  <ErrorMessageBox>Enter Office Email</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.income && error}
              >
                <FormLabel>Income</FormLabel>
                <TextField
                  type="number"
                  name="income"
                  value={formData?.income || ""}
                  onChange={handleInputChange}
                  placeholder="Income"
                  error={!formData?.income && error}
                />
                {!formData?.income && error && (
                  <ErrorMessageBox>Enter Income</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <FormLabel>Account Aggregator ID</FormLabel>
                <TextField
                  name="aa_id"
                  value={formData?.aa_id || ""}
                  placeholder="Enter Account Aggregator ID"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                fullWidth
                required
                error={!formData?.endUse && error}
              >
                <FormLabel>Purpose of Loan</FormLabel>
                <Autocomplete
                  required
                  value={formData?.endUse}
                  options={[
                    "Consumer Durable Purchase",
                    "Education",
                    "Travel",
                    "Health",
                    "Other",
                  ]}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) =>
                    handleAutoCompleteChange(event, value, "endUse")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!formData?.endUse && error}
                    />
                  )}
                />
                {!formData?.endUse && error && (
                  <ErrorMessageBox>Select End Use</ErrorMessageBox>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <FormLabel>UDYAM number</FormLabel>
                <TextField
                  name="udyamNumber"
                  value={formData?.udyamNumber || ""}
                  onChange={handleInputChange}
                  placeholder="Enter UDYAM number"
                />
              </FormControl>
            </Grid>{" "}
          </Grid>
        </TabPanel>
      </TabContext>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          gap: "10px",
        }}
      >
        <Button
          variant="outlined"
          disabled={value === "Personal Details"}
          onClick={handleBack}
          sx={{
            textTransform: "none",
            fontFamily: "Plus Jakarta Sans, bold",
          }}
        >
          Back
        </Button>
        {value === "Proffesional Details" ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: themePalette.primary,
              textTransform: "none",
              fontFamily: "Plus Jakarta Sans, bold",
            }}
          >
            Submit
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleContinue}
            sx={{
              backgroundColor: themePalette.primary,
              textTransform: "none",
              fontFamily: "Plus Jakarta Sans, bold",
            }}
          >
            Continue
          </Button>
        )}
      </Box>
    </PersonalDetailsContainer>
  );
}
