export const apis = {
  login: "v1/user/signIn",
  signup: "v1/user/signUp",
  mapManagerToAgentApi: "v1/agents/map",
  creditSearch: "v1/credit/search",
  submitCreditForm: "v1/credit/submitForm",
  creditSelect: "v1/credit/select",
  getaaUrl: "v1/credit/getAAUrl",
  getCreditOffers: "v1/credit/getOfferDetails",
  selectConsent: "v1/credit/selectConsent",
  getFormUrl: "v1/credit/getFormUrl",
  creditInit: "v1/credit/init",
  creditConfirm: "v1/credit/confirm",
  creditPayment: "v1/credit/payment",
  creditPaymentStatus: "v1/credit/status",
  listAllCreditCustomer: "v1/credit/customer/listAll",
  creditCustomerDetails: "v1/credit/customer/details",
  creditIssue: "v1/credit/issue",
  creditListIssues: "v1/customer/issues/listAll",
  creditIssueStatus: "v1/credit/issue_status",
  creditCloseIssue: "v1/credit/issue",
  sendNotification: "v1/send_notification",
  creditStatusCheck: "v1/txn_details",
  insuranceSearch: "v1/insurance/search",
  submitInsuranceForm: "v1/insurance/submitForm",
  insuranceOfferDetails: "v1/insurance/getOfferDetails",
  insuranceSelect: "v1/insurance/select",
  insuranceFormUrl: "v1/insurance/getFormUrl",
  insuranceInit: "v1/insurance/init",
  insuranceConfirm: "v1/insurance/confirm",
  insuranceCancel: "v1/insurance/cancel",
  insuranceStatus: "v1/insurance/status",
  listAllCustomer: "v1/customer/listAll",
  insuranceTC: "v1/insurance/getCancelTerms",
  generateOTP: "v1/login/generateOTP",
  loginUsingOtp: "v1/login/verifyOTP",
  getApprovedOffers: "v1/getApprovedOffers",
  getItemDetails: "v1/credit/getItemDetails",
  getItemStatus: "v1/item_status",
  resetPasswordApi: "v1/user/resetPassword",
  getAllUserByUserRole: "v1/user/list",
  getAgentsByManagerID: "v1/agents/list_all",
};
