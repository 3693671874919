import React from "react";
import { Typography } from "@mui/material";
const EMandate = () => {
  return (
    <>
      <Typography variant="h4">E-Mandate</Typography>
      <Typography>
        By clicking "Setup E-Mandate," you consent to use your account
        information for setting up an eMandate as a mode of payment for your
        loan.
      </Typography>
    </>
  );
};

export default EMandate;
