import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  creditCloseIssue,
  creditIssue,
  creditIssueStatus,
  creditListIssues,
} from "../../../slice/creditForm.slice";
import {
  Box,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Toolbar,
  IconButton,
  AppBar,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { format } from "date-fns";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0089e9",
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledSubTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0089e9a6", //"#0000005c",
    color: theme.palette.common.white,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ConsentDocsContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: "Source Sans Pro",
  ".patientList-title-wrapper": {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

const IssueStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const [isRotating, setIsRotating] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [issueDetails, setIssueDetails] = useState([]);
  const [isIssueClosed, setIsIssueClosed] = useState(false);
  const user_role = sessionStorage.getItem("user_role");

  useEffect(() => {
    setShowLoader(true);
    const payload = {
      customer_id:
        !user_role === "guest"
          ? JSON.parse(sessionStorage.getItem("user_details"))?.customer_id
          : "03",
    };
    // console.log(payload);
    dispatch(creditListIssues(payload)).then((res) => {
      setShowLoader(false);
      setIssueDetails(res?.payload);
    });
  }, []);

  const handleClickOpen = (index) => {
    setIndexValue(index);
    issueDetails[index].complainant_actions.map((action) => {
      if (action?.complainant_action === "CLOSE") {
        setIsIssueClosed(true);
      } else setIsIssueClosed(false);
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseIssue = () => {
    const payload = {
      txnId: sessionStorage.getItem("txnId"),
      orderId: sessionStorage.getItem("orderId"),
      issueAction: "CLOSE",
      issueId: issueDetails[indexValue]?.issue_id,
    };
    dispatch(creditCloseIssue(payload)).then((res) => {
      setIsIssueClosed(true);
      handleCurrentStatus(issueDetails[indexValue]?.issue_id);
    });
  };

  const handleCurrentStatus = (issueId) => {
    setIsRotating(true);
    setShowLoader(true);
    console.log("Current Status");
    const payload = {
      txnId: sessionStorage.getItem("txnId"),
      issueId: issueId,
    };
    dispatch(creditIssueStatus(payload)).then((statusResponse) => {
      if (statusResponse?.payload) {
        const payload = {
          customer_id:
            !user_role === "guest"
              ? JSON.parse(sessionStorage.getItem("user_details"))?.customer_id
              : "03",
        };
        dispatch(creditListIssues(payload)).then((res) => {
          setIssueDetails(res?.payload);
        });
      }
      setIsRotating(false);
      setShowLoader(false);
    });
  };

  return (
    <ConsentDocsContainer>
      <CustomLoader open={showLoader} />
      <div className="patientList-title-wrapper">
        <h2>Issues</h2>
        <Button
          variant="contained"
          style={{ backgroundColor: "#0089e9" }}
          onClick={() =>
            navigate(
              user_role === "guest" ? "/loan/raise-issue" : "/raise-issue"
            )
          }
        >
          Raise Issue
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Issue Category</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Created At</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issueDetails &&
              (issueDetails.length > 0 ? (
                issueDetails?.map((item, index) => (
                  <StyledTableRow
                    key={index}
                    onClick={() => handleClickOpen(index)}
                  >
                    <StyledTableCell component="th" scope="row">
                      {item.issue_category}
                    </StyledTableCell>
                    <StyledTableCell>{item.issue_short_desc}</StyledTableCell>
                    <StyledTableCell>{item.issue_id}</StyledTableCell>
                    <StyledTableCell>
                      {format(item.created_at, "dd-MM-yyyy HH:mm")}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <div style={{ padding: "10px 0" }}>
                  <h3>No issues raised</h3>
                </div>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Issuse Details
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              {issueDetails[indexValue]?.complainant_actions && (
                <>
                  <span
                    onClick={() =>
                      handleCurrentStatus(issueDetails[indexValue]?.issue_id)
                    }
                    style={{ float: "right" }}
                  >
                    {isRotating ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        style={{ verticalAlign: "middle" }}
                      />
                    ) : (
                      <RefreshIcon style={{ verticalAlign: "middle" }} />
                    )}
                  </span>
                  <h3>Complainant Actions</h3>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell align="left">ID</TableCell> */}
                          <StyledSubTableCell>Status</StyledSubTableCell>
                          <StyledSubTableCell>Description</StyledSubTableCell>
                          <StyledSubTableCell>Updated At</StyledSubTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {issueDetails[indexValue]?.complainant_actions?.map(
                          (complaint, i) => (
                            <TableRow key={i}>
                              <TableCell align="left">
                                {complaint?.complainant_action}
                              </TableCell>
                              <TableCell align="left">
                                {complaint?.short_desc}
                              </TableCell>
                              <TableCell align="left">
                                {format(
                                  complaint?.updated_at,
                                  "dd-MM-yyyy HH:mm"
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                </>
              )}

              {issueDetails[indexValue]?.respondent_actions && (
                <>
                  <h3>Responsent Actions</h3>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell align="left">ID</TableCell> */}
                          <StyledSubTableCell>Status</StyledSubTableCell>
                          <StyledSubTableCell>Description</StyledSubTableCell>
                          <StyledSubTableCell>Updated At</StyledSubTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {issueDetails[indexValue]?.respondent_actions?.map(
                          (complaint, i) => (
                            <TableRow key={i}>
                              <TableCell align="left">
                                {complaint?.respondent_action}
                              </TableCell>
                              <TableCell align="left">
                                {complaint?.short_desc}
                              </TableCell>
                              <TableCell align="left">
                                {format(
                                  complaint?.updated_at,
                                  "dd-MM-yyyy HH:mm"
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                </>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: "10px 30px 10px 0" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseDialog}
          >
            Exit
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="primary"
            disabled={isIssueClosed}
            onClick={handleCloseIssue}
          >
            Close Issue
          </Button>
        </DialogActions>
      </Dialog>
    </ConsentDocsContainer>
  );
};

export default IssueStatus;
