import {
  Grid,
  FormControl,
  FormLabel,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Typography,
  styled,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const ErrorMessageBox = styled(FormHelperText)(({ theme }) => ({
  margin: "4px 0",
}));

const BankDetails = ({
  formData,
  updateFormData,
  AccNo,
  setAccNo,
  textFieldError,
}) => {
  const [error, setError] = useState("");

  const handleInputChange = (event) => {
    updateFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleRadioChange = (event) => {
    updateFormData({ ...formData, acctype: event.target.value });
  };

  useEffect(() => {
    if (AccNo.num === AccNo.re_num) {
      updateFormData({ ...formData, accNo: AccNo.num });
      setError(false);
    } else {
      setError(true);
    }
  });
  return (
    <Box>
      <Typography fontSize={16} mb={3} variant={"h4"}>
        Bank Details for Loan Disbursement
      </Typography>
      <Grid container spacing={2} gap={5}>
        <Grid item xs={12} sm={5}>
          <FormControl
            fullWidth
            required
            error={!formData?.accHolderName && textFieldError}
          >
            <FormLabel>Account Holder Name</FormLabel>
            <TextField
              onChange={handleInputChange}
              name="accHolderName"
              value={formData?.accHolderName}
              placeholder="Enter Your Bank Name"
              error={!formData?.accHolderName && textFieldError}
            />
            {!formData?.accHolderName && textFieldError && (
              <ErrorMessageBox>Enter Your Bank Name</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControl
            fullWidth
            required
            error={!formData?.acctype && textFieldError}
            sx={{ marginBottom: 0 }}
          >
            <FormLabel>Account Type</FormLabel>
            <RadioGroup
              onChange={handleRadioChange}
              value={formData?.acctype}
              row
            >
              <Box
                sx={{
                  height: "56px",
                  width: "auto",
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  marginBottom: { xs: "70px", sm: "0px" },
                }}
              >
                <Box
                  sx={{
                    height: "auto",
                    width: "200px",
                    border:
                      !formData?.acctype && textFieldError
                        ? "1px solid red"
                        : "1px solid lightGray",
                    padding: "5px 10px",
                    margin: {
                      xs: "10px 15px 10px 5px",
                      sm: "0px 15px 0px 5px",
                    },
                    borderRadius: "5px",
                  }}
                >
                  <FormControlLabel
                    value="Savings"
                    name="Savings"
                    control={<Radio />}
                    label="Saving"
                  />
                </Box>
                <Box
                  sx={{
                    height: "auto",
                    width: "200px",
                    border:
                      !formData?.acctype && textFieldError
                        ? "1px solid red"
                        : "1px solid lightGray",
                    padding: "5px 10px",
                    margin: {
                      xs: "10px 15px 10px 5px",
                      sm: "0px 15px 0px 5px",
                    },
                    borderRadius: "5px",
                  }}
                >
                  <FormControlLabel
                    value="current"
                    name="current"
                    control={<Radio />}
                    label="Current"
                  />
                </Box>
              </Box>
            </RadioGroup>

            {!formData?.acctype && textFieldError && (
              <ErrorMessageBox>Select Account Type</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControl
            fullWidth
            required
            error={!formData?.accNo && textFieldError}
          >
            <FormLabel>Account Number</FormLabel>
            <TextField
              onChange={(e) => setAccNo({ ...AccNo, num: e.target.value })}
              value={AccNo.num}
              name="accNo"
              placeholder="Enter Account Number"
              type="password"
              error={!formData?.accNo && textFieldError}
            />
            {!formData?.accNo && textFieldError && (
              <ErrorMessageBox>Enter Account Number</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControl fullWidth>
            <FormLabel>Re-enter Account Number</FormLabel>
            <TextField
              onChange={(e) => setAccNo({ ...AccNo, re_num: e.target.value })}
              value={AccNo.re_num}
              name="re_accNo"
              placeholder="Re-enter Account Number"
            />
            <Typography color={"red"}>
              {error && AccNo.re_num !== "" && "Not matched"}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControl
            fullWidth
            required
            error={!formData?.ifscCode && textFieldError}
          >
            <FormLabel>IFSC</FormLabel>
            <TextField
              onChange={handleInputChange}
              value={formData?.ifscCode}
              name="ifscCode"
              placeholder="Enter IFSC Number"
              error={!formData?.ifscCode && textFieldError}
            />
            {!formData?.ifscCode && textFieldError && (
              <ErrorMessageBox>Enter IFSC Number</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BankDetails;
