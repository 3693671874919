import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, styled } from "@mui/material";
import themePalette from "../../../styles/themePalette";

const MyDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: `${themePalette?.primary} !important`,
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: `${themePalette?.primary} !important`,
    color: "white",
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "white",
  },
  "& .MuiDataGrid-footerContainer": {
    backgroundColor: "white",
    minHeight: "60px",
  },
  "& .MuiDataGrid-scrollbarFiller": {
    backgroundColor: `${themePalette?.primary} !important`,
  },
  "& .MuiDataGrid-scrollbarFiller--header": {
    backgroundColor: `${themePalette?.primary} !important`,
  },
  "& .MuiDataGrid-filler": {
    backgroundColor: "white !important",
  },
  "& .MuiDataGrid-toolbarContainer": {
    minHeight: "50px",
    padding: theme.spacing(0, 4),
    gap: theme.spacing(3),

    "& .MuiButton-root": {
      padding: theme.spacing(1, 2),
      color: themePalette?.primary,
      "&:hover": {
        backgroundColor: themePalette?.primary,
        color: "white",
      },
    },
  },
}));

const CustomDataGrid = ({
  columns,
  rows,
  onRowClick,
  enableRowClick,
  rowId,
  CustomSortingModel,
}) => {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });

  // Dynamically set filterable based on visibility
  const formattedColumns = columns.map((col) => ({
    field: col.key,
    headerName: col.header,
    flex: 1,
    hide: col.hide || false,
    filterable: !col.hide,
  }));

  // console.log(rows);

  return (
    <Box sx={{ height: 400, width: "100%", overflowX: "scroll" }}>
      {rows?.length > 0 ? (
        <MyDataGrid
          rows={rows}
          columns={formattedColumns}
          sx={{ minWidth: 900, flexShrink: "0" }}
          slots={{
            toolbar: GridToolbar,
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          pageSizeOptions={[25, 50, { value: -1, label: "All" }]}
          disableColumnResize={true}
          getRowId={rowId || ((row, index) => index)}
          onRowClick={(row) => {
            if (enableRowClick) {
              onRowClick(row.row);
            }
          }}
          initialState={{
            sorting: {
              sortModel: CustomSortingModel ? [CustomSortingModel] : [],
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default CustomDataGrid;
