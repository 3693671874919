import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Stack, styled } from "@mui/material";
import Navbar from "../Navbar";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { CustomStepper } from "../../../components/CustomComponents/CustomStepper";
const BodyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ContentContainer = styled("div")(({ theme, open }) => ({
  marginLeft: open ? "240px" : "100px",
  transition: "margin .2s",
  overflowX: "auto",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(0, 10),
  height: "100%",
  overflowY: "scroll",
  scrollbarWidth: "none",
  "-ms-overflow-style": "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0, 5),
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: open ? "20px" : "70px",
    marginLeft: "0px",
    padding: theme.spacing(0),
  },
}));

const creditSteps = [
  "Personal Details",
  "Proffessional Details",
  "Select Offer",
  "KYC",
  "Account Details",
  "E-Mandate",
  "Order Details",
];

const insuranceSteps = [
  "Personal Details",
  "Add Addons",
  "Select Policy",
  "Start Kyc",
  "Buyer Form",
  "Nominee Form",
  "Payment Process",
  "Order Details",
];

function OutletComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = "/home";
  const actionType = sessionStorage.getItem("actionType");
  const [open, setOpen] = useState(
    () => JSON.parse(localStorage.getItem("navbarOpen")) || false
  );
  useEffect(() => {
    localStorage.setItem("navbarOpen", JSON.stringify(open));
  }, [open]);
  return (
    <BodyContainer>
      <Navbar open={open} setOpen={setOpen} />
      <ContentContainer open={open}>
        <Stack gap={2} flexDirection="row" alignItems="center">
          <Stack
            sx={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              backgroundColor: "#1976d2",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              cursor: "pointer",
              mt: 4,
            }}
            onClick={() => navigate(-1)}
          >
            <KeyboardArrowLeftIcon sx={{ fontSize: "2rem" }} />
          </Stack>
          <Stack width="100%">
            {location.pathname.includes(pathname) && (
              <CustomStepper
                steps={
                  actionType === "insurance" ? insuranceSteps : creditSteps
                }
              />
            )}
          </Stack>
        </Stack>
        <Outlet />
      </ContentContainer>
    </BodyContainer>
  );
}

export default OutletComponent;
