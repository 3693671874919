import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./app";
import theme from "./styles/theme";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { MyProvider } from "./utils/context";
import { Provider } from "react-redux";
import { store } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MyProvider>
        <Router>
          <App />
        </Router>
      </MyProvider>
    </ThemeProvider>
  </Provider>
);

reportWebVitals();
