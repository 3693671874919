import React, { useState } from "react";
import {
  styled,
  TextField,
  Button,
  Box,
  FormControl,
  FormHelperText,
  keyframes,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  generateOTP,
  loginUser,
  loginUsingOtp,
} from "../../../app/authUser.slice";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtpInput from "react-otp-input";

const rotateBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const LoginPageContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "100vh",
  background:
    "linear-gradient(90deg, rgba(173,168,255,1), rgba(113,116,255,1), rgba(0,164,198,1))",
  backgroundSize: "200% 200%",
  animation: `${rotateBackground} 5s ease infinite`,
});
const RightComponent = styled("div")({
  margin: "80px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "white",
  height: "600px",
  width: "400px",
  border: "1px solid rgb(0, 137, 233)",
  borderRadius: "20px",
  padding: 40,
  "@media (max-width: 768px)": {
    height: "100%",
    width: "600px",
  },
});

const FooterComponent = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  width: "100%",
  fontSize: "12px",
  alignItems: "flex-end",
  "@media (max-width: 768px)": {
    justifyContent: "space-evenly",
  },
});

const LoginComponent = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "300px",
  marginTop: "10px",
  "& .LoginFields": {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
  },
});

const AssistantChangeSectionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(10),
}));

const ActionChangeSectionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  borderRadius: "30px",
  backgroundColor: "#EAF2FF",
  width: "330px",
  height: "35px",
  position: "relative",
}));

const Slider = styled("div")(({ activeButton }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 4,
  left: activeButton === 0 ? 0 : "50%",
  width: "50%",
  height: "85%",
  backgroundColor: "#0188E8",
  borderRadius: "30px",
  transition: "left 0.3s ease-in-out",
}));

const ToggleButton = styled(Button)(({ theme, isActive }) => ({
  zIndex: 1,
  backgroundColor: "transparent",
  color: isActive ? "#fff" : "#0188E8",
  width: "50%",
  height: "100%",
  fontWeight: "bold",
  fontSize: "0.8rem",
  textTransform: "none",
  transition: "color 0.3s ease-in-out",
  margin: 1,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

function CheckEmptyInput(Input) {
  // Check if phone number and password are not empty
  if (!Input) {
    return { valid: false, message: "All fields are required" };
  }
  return { valid: true, message: "" };
}

function LoginPage() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [sendingOtp, setSendingOtp] = useState(false);
  const [txnId, setTxnId] = useState("");
  const [resendOTP, setResendOTP] = useState(false);
  const [isStartOtp, setIsStartOtp] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [activeButton, setActiveButton] = useState(0);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const agent_login = "/agent-dashboard";
  const customer_login = "/user-dashboard";
  const admin_login = "/admin-dashboard";
  const manager_login = "/manager-dashboard";

  const [showLoader, setShowLoader] = useState(false);

  const showToast = (message, type) => {
    toast(message, { type });
  };

  // Login using username and password
  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  const handleLoginUsingUsername = () => {
    const { valid: isUsernameValid } = CheckEmptyInput(username);
    const { valid: isPasswordValid } = CheckEmptyInput(password);
    if (!isUsernameValid && !isPasswordValid) {
      showToast("All Fields are Required", "error");
      setError(true);
      return;
    }

    const payload = {
      username: username,
      password: password,
    };
    setShowLoader(true);
    dispatch(loginUser(payload)).then((res) => {
      setShowLoader(false);
      if (res?.payload?.access_token) {
        const accessToken = res?.payload?.access_token;
        const role = res?.payload?.user_role || "customer";
        sessionStorage.setItem("user_details", JSON.stringify(res?.payload));
        sessionStorage.setItem("user_role", role);
        sessionStorage.setItem("accessToken", accessToken);

        setUsername("");
        setPassword("");
        if (role === "agent") navigate(agent_login);
        else if (role === "customer") navigate(customer_login);
        else if (role === "admin") navigate(admin_login);
        else if (role === "manager") navigate(manager_login);

        showToast("Login Succesfull!!", "success");
      } else {
        showToast("Incorrect Username OR Password", "error");
        setError(true);
        console.log("Error while login using password", res);
        return;
      }
    });
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  // OTP Login Code

  const handleMobileNumberChange = (e) => {
    const { value } = e.target;
    if (value.toString().length <= 10) setMobileNumber(value);
  };

  const handleLoginUsingOTP = (value) => {
    const { valid: isMobileValid } = CheckEmptyInput(mobileNumber);
    const { valid: isOtpValid } = CheckEmptyInput(value);
    if (!isOtpValid && !isMobileValid) {
      showToast("All Fields are Required", "error");
      return;
    } else if (!isMobileValid) {
      showToast("Mobile Number is Required", "error");
      return;
    } else if (!isOtpValid) {
      showToast("OTP is Required", "error");
      return;
    } else {
      setShowLoader(true);
      const payload = {
        txnId: txnId,
        otp: value,
      };
      dispatch(loginUsingOtp(payload)).then((res) => {
        setShowLoader(false);
        console.log(res?.payload);

        if (res?.payload?.verification_status === "SUCCESS") {
          showToast("Login Succesfull!!", "success");
          const verificationStatus = res?.payload?.verification_status;
          const name = res?.payload?.user_details?.name || "";
          const role = res?.payload?.user_details?.user_role || "customer";
          sessionStorage.setItem(
            "user_details",
            JSON.stringify(res?.payload?.user_details)
          );
          sessionStorage.setItem("user_role", role);
          sessionStorage.setItem("verificationStatus", verificationStatus);
          setMobileNumber("");
          setOtp("");
          setIsStartOtp(false);
          //navigate our employee to the home page
          if (role === "agent") navigate(agent_login);
          else if (role === "customer") navigate(customer_login);
          else if (role === "admin") navigate(admin_login);
          else if (role === "manager") navigate(manager_login);
        } else if (res?.payload?.verification_status === "FAILED") {
          showToast(res?.payload?.message, "error");
          return;
        } else {
          showToast("Try Again!!", "error");
          console.log("Error while login using OTP", res);
          return;
        }
      });
    }
  };

  const handleSendOTP = () => {
    const { valid, message } = CheckEmptyInput(mobileNumber);
    setIsStartOtp(true);
    if (!valid) {
      showToast(message, "error");
      return;
    }
    const payload = {
      mobileNumber,
    };
    setSendingOtp(true);
    dispatch(generateOTP(payload)).then((res) => {
      if (res.error) {
        showToast("Try Again!!", "error");
        setSendingOtp(false);
        return;
      }
      if (res?.payload) {
        setTxnId(res?.payload?.txn_id);
        setSendingOtp(false);
        setResendOTP(true);
        showToast("OTP Sent Successfully", "success");
      }
    });
  };

  const mobileNumError = mobileNumber !== "" && mobileNumber?.length < 10;
  const isMobile = useMediaQuery("(max-width:400px)");

  return (
    <>
      <CustomLoader open={showLoader} />
      <LoginPageContainer>
        <ToastContainer />
        <RightComponent>
          <AssistantChangeSectionWrapper>
            <ActionChangeSectionContainer>
              <Slider activeButton={activeButton} />
              <ToggleButton
                onClick={() => handleButtonClick(0)}
                isActive={activeButton === 0}
              >
                Login Using Password
              </ToggleButton>
              <ToggleButton
                onClick={() => handleButtonClick(1)}
                isActive={activeButton === 1}
              >
                Login Using OTP
              </ToggleButton>
            </ActionChangeSectionContainer>
          </AssistantChangeSectionWrapper>
          <Box>
            <Stack gap={3}>
              <div
                style={{
                  fontFamily: "plus jakarta sans bold",
                  fontSize: "32px",
                  color: "rgb(0, 137, 233)",
                }}
              >
                Welcome to CliniQ360
              </div>
              <div
                style={{
                  fontFamily: "Source Sans pro",
                  fontWeight: "400",
                  marginTop: 1,
                }}
              >
                Please enter your details to continue login
              </div>
            </Stack>
            {activeButton === 0 ? (
              <LoginComponent>
                <FormControl>
                  <div className="LoginFields">
                    Username
                    <TextField
                      type="text"
                      id="username"
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{ marginTop: "5px" }}
                      name="username"
                      value={username}
                      onChange={handleInputChanges}
                      error={!username && error}
                    />
                  </div>
                  {!username && error && (
                    <FormHelperText sx={{ ml: "0px" }}>
                      Enter a valid Username
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl>
                  <div className="LoginFields">
                    Password
                    <TextField
                      type="password"
                      id="password"
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{ marginTop: "5px" }}
                      name="password"
                      value={password}
                      onChange={handleInputChanges}
                      error={error && !password}
                    />
                  </div>
                  {error && !password && (
                    <FormHelperText sx={{ ml: "0px" }}>
                      Enter a valid Password
                    </FormHelperText>
                  )}
                </FormControl>

                <div className="LoginFields">
                  <Button
                    className="LoginFields"
                    variant="contained"
                    onClick={handleLoginUsingUsername}
                    sx={{ backgroundColor: "rgb(0, 137, 233)" }}
                  >
                    Login
                  </Button>
                </div>
              </LoginComponent>
            ) : (
              <LoginComponent>
                <FormControl error={mobileNumError}>
                  <div className="LoginFields">
                    <div
                      style={{
                        fontFamily: "plus jakarta sans bold",
                        fontSize: "1rem",
                        color: "rgb(0, 137, 233)",
                      }}
                    >
                      Enter Your Valid Number
                    </div>
                    <TextField
                      type="number"
                      id="mobile-number"
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{ marginTop: "5px" }}
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                      error={mobileNumber !== "" && mobileNumber?.length < 10}
                      InputProps={{
                        readOnly: resendOTP,
                      }}
                    />
                  </div>
                  {mobileNumError && (
                    <FormHelperText sx={{ ml: "0px" }}>
                      Mobile Number Should Be a 10 Digit Number
                    </FormHelperText>
                  )}
                </FormControl>

                <div className="LoginFields">
                  {resendOTP && isStartOtp && (
                    <OtpInput
                      containerStyle={{
                        marginBottom: "10px",
                        justifyContent: "space-evenly",
                      }}
                      inputStyle={{
                        width: isMobile ? "1rem" : "2.5rem",
                        padding: "10px",
                        fontSize: isMobile ? "0.8rem" : "1.2rem",
                        borderRadius: "4px",
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                      }}
                      value={otp}
                      onChange={(value) => {
                        setOtp(value);
                        if (value.length === 6) {
                          handleLoginUsingOTP(value);
                        }
                      }}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  )}

                  {!resendOTP ? (
                    <Button
                      className="LoginFields"
                      variant="contained"
                      onClick={handleSendOTP}
                      sx={{ backgroundColor: "rgb(0, 137, 233)" }}
                    >
                      Send OTP
                    </Button>
                  ) : sendingOtp ? (
                    <Button
                      sx={{ backgroundColor: "rgb(0, 137, 233)" }}
                      className="LoginFields"
                      variant="contained"
                      disabled
                    >
                      Sending OTP...
                    </Button>
                  ) : (
                    <Button
                      className="LoginFields"
                      variant="contained"
                      onClick={handleSendOTP}
                      sx={{ backgroundColor: "rgb(0, 137, 233)" }}
                    >
                      Resend OTP
                    </Button>
                  )}
                </div>
              </LoginComponent>
            )}
          </Box>
          <FooterComponent
            sx={{
              height: { sm: "150px", xs: "120px" },
            }}
          >
            <div style={{ fontFamily: "Source Sans 3" }}>©2023 cliniQ360</div>
            <div style={{ fontFamily: "Inter" }}>Terms & Privacy</div>
          </FooterComponent>
        </RightComponent>
      </LoginPageContainer>
    </>
  );
}

export default LoginPage;
