import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  styled,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../app/authUser.slice";
import CustomLoader from "../../../components/CustomComponents/CustomLoader";
import CustomSnackbar from "../../../components/CustomComponents/CustomSnackbar";
import { useNavigate } from "react-router-dom";

const ChangePasswordWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(4),
  width: "100%",
  height: "70vh",
  backgroundColor: theme.palette.background.default,
}));

const ChangePasswordContainer = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  width: "100%",
  maxWidth: "400px",
  backgroundColor: "#fff",
  padding: theme.spacing(3),
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
}));

const ErrorMessageBox = styled(FormHelperText)(({ theme }) => ({
  margin: "4px 0",
  color: theme.palette.error.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  borderRadius: "8px",
  color: "#fff",
  textTransform: "none",
  fontFamily: "plus jakarta sans bold",
}));

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showSnackbar, setShowSnacker] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    message: "",
    status: "",
  });
  const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const [error, setError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validatePasswords = () => {
    const { old_password, new_password } = formData;

    if (!old_password || !new_password || !confirmPassword) {
      setError(true);
      return false;
    }

    if (new_password !== confirmPassword) {
      setError(true);
      return false;
    }

    if (new_password.length < 6) {
      setError(true);
      return false;
    }

    setError(false);
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatePasswords()) {
      setShowLoader(true);
      const payload = {
        ...formData,
        username: userDetails?.username,
      };
      dispatch(resetPassword(payload)).then((res) => {
        if (res?.error && Object.keys(res?.error)?.length > 0) {
          setShowSnacker(true);
          setSnackbarMsg({
            message: res?.error?.message,
            status: "error",
          });
          setShowLoader(false);
          return;
        }
        setShowLoader(false);
        if (res?.payload?.detail === "Provided password does not match") {
          setShowSnacker(true);
          setSnackbarMsg({
            message: "Provided password does not match",
            status: "error",
          });
        } else {
          setShowSnacker(true);
          setSnackbarMsg({
            message: "Password has been Changed!",
            status: "success",
          });
          navigate(-1);
        }
      });
      console.log("Passwords changed successfully");
    }
  };

  return (
    <>
      <CustomLoader open={showLoader} />
      <CustomSnackbar
        open={showSnackbar}
        onClose={() => setShowSnacker(false)}
        status={snackbarMsg?.status}
        message={snackbarMsg?.message}
      />
      <ChangePasswordWrapper>
        <ChangePasswordContainer>
          <Stack gap={3}>
            <form onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                required
                error={!formData?.old_password && error}
                sx={{ marginBottom: 3 }}
              >
                <FormLabel>Enter Your Old Password</FormLabel>
                <TextField
                  name="old_password"
                  type="password"
                  value={formData?.old_password}
                  onChange={handleInputChange}
                  placeholder="Enter Your Old Password"
                  error={!formData?.old_password && error}
                />
                {!formData?.old_password && error && (
                  <ErrorMessageBox>Old password is required.</ErrorMessageBox>
                )}
                {formData.old_password > 2 &&
                  formData.old_password === formData.new_password && (
                    <ErrorMessageBox>
                      Old password Cannot be same as New password
                    </ErrorMessageBox>
                  )}
              </FormControl>

              <FormControl
                fullWidth
                required
                error={!formData?.new_password && error}
                sx={{ marginBottom: 3 }}
              >
                <FormLabel>Enter Your New Password</FormLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name="new_password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={formData?.new_password}
                  onChange={handleInputChange}
                  placeholder="Enter Your New Password"
                  error={!formData?.new_password && error}
                />
                {formData?.new_password.length < 6 && error && (
                  <ErrorMessageBox>
                    Password must be at least 6 characters long.
                  </ErrorMessageBox>
                )}
              </FormControl>

              <FormControl
                fullWidth
                required
                error={confirmPassword !== formData.new_password && error}
                sx={{ marginBottom: 3 }}
              >
                <FormLabel>Enter Your Confirm Password</FormLabel>
                <TextField
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Your New Password"
                  error={confirmPassword !== formData.new_password && error}
                />
                {confirmPassword !== formData.new_password && error && (
                  <ErrorMessageBox>Passwords do not match.</ErrorMessageBox>
                )}
              </FormControl>

              <StyledButton type="submit" variant="contained" fullWidth>
                Change Password
              </StyledButton>
            </form>
          </Stack>
        </ChangePasswordContainer>
      </ChangePasswordWrapper>
    </>
  );
};

export default ChangePasswordPage;
