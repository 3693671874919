import * as React from "react";
import {
  Container,
  styled,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@mui/material";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import { MyContext } from "../../../utils/context";
import ProgressBar from "@ramonak/react-progress-bar";
import themePalette from "../../../styles/themePalette";

const StepperContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.grey[900],
  }),
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
    ...theme.applyStyles("dark", {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const QontoStepIconRoot = styled("div")(({ theme }) => ({
  color: "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  ...theme.applyStyles("dark", {
    color: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: "#784af4",
      },
    },
  ],
}));

export function CustomStepper({ steps, showProgressBarByDefault = false }) {
  const { activeStep } = React.useContext(MyContext);

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  return showProgressBarByDefault ? (
    <StepperContainer maxWidth="xl">
      <Stack sx={{ width: "100%", display: "flex" }}>
        <ProgressBar
          completed={activeStep + 1}
          maxCompleted={steps?.length}
          customLabel={
            activeStep === steps?.length - 1
              ? "Completed"
              : `${activeStep + 1} / ${steps?.length}`
          }
          bgColor={
            activeStep === steps?.length - 1 ? "#00A91C" : themePalette.primary
          }
          labelAlignment={activeStep === steps?.length - 1 ? "center" : "right"}
        />
      </Stack>
    </StepperContainer>
  ) : (
    <StepperContainer maxWidth="xl">
      <Stack sx={{ width: "100%", display: { xs: "none", md: "flex" } }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Stack sx={{ width: "100%", display: { xs: "flex", md: "none" } }}>
        <ProgressBar
          completed={activeStep + 1}
          maxCompleted={steps?.length}
          customLabel={
            activeStep === steps?.length - 1
              ? "Completed"
              : `${activeStep + 1} / ${steps?.length}`
          }
          bgColor={
            activeStep === steps?.length - 1 ? "#00A91C" : themePalette.primary
          }
          labelAlignment={activeStep === steps?.length - 1 ? "center" : "right"}
        />
      </Stack>
    </StepperContainer>
  );
}
