import { useLocation, Navigate } from "react-router-dom";
import React from "react";
import OutletComponent from "./OutletComponent";

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const userRole = sessionStorage.getItem("user_role");
  const accessToken = sessionStorage.getItem("accessToken");
  const verificationStatus = sessionStorage.getItem("verificationStatus");

  return (allowedRoles.includes(userRole) && accessToken) ||
    verificationStatus === "SUCCESS" ? (
    <OutletComponent />
  ) : accessToken || verificationStatus === "SUCCESS" ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
