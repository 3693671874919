import React from "react";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Grid,
  FormControl,
  FormLabel,
  Autocomplete,
  FormHelperText,
  Box,
  Button,
  Typography,
} from "@mui/material";

const PersonalDetailsContainer = styled("div")(({ theme }) => ({
  // padding: theme.spacing(2),
  width: "100%",
}));

const ErrorMessageBox = styled(FormHelperText)(({ theme }) => ({
  margin: "4px 0",
}));

export default function ProffesionalDetailsForm({
  formData,
  updateFormData,
  error,
  activeStep,
  setActiveStep,
  checkError,
  setShowSnackbar,
  setPopoverOpen,
}) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const hasError = checkError(activeStep);
    if (!hasError) {
      setPopoverOpen(true);
    } else {
      setShowSnackbar(true);
    }
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    setActiveStep(prevStep);
  };

  const handleInputChange = (event) => {
    updateFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleAutoCompleteChange = (event, value, name) => {
    let updatedFormData = { ...formData, [name]: value };
    console.log(updatedFormData);
    updateFormData(updatedFormData);
  };

  return (
    <PersonalDetailsContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h4">Proffessional Details</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            required
            error={!formData?.companyName && error}
          >
            <FormLabel>Company Name</FormLabel>
            <TextField
              name="companyName"
              value={formData?.companyName}
              onChange={handleInputChange}
              placeholder="Enter Company Name"
              error={!formData?.companyName && error}
            />
            {!formData?.companyName && error && (
              <ErrorMessageBox>Enter Company Name</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            required
            error={!formData?.officialEmail && error}
          >
            <FormLabel>Office Email</FormLabel>
            <TextField
              name="officialEmail"
              type="email"
              value={formData?.officialEmail}
              onChange={handleInputChange}
              placeholder="Enter Office Email"
              error={!formData?.officialEmail && error}
            />
            {!formData?.officialEmail && error && (
              <ErrorMessageBox>Enter Office Email</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            required
            error={!formData?.employmentType && error}
          >
            <FormLabel>Employment Type</FormLabel>

            <Autocomplete
              value={formData?.employmentType || null}
              options={["Salaried", "Self Employment"]}
              getOptionLabel={(option) => option}
              onChange={(event, value) =>
                handleAutoCompleteChange(event, value, "employmentType")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!formData?.employmentType && error}
                />
              )}
            />
            {!formData?.employmentType && error && (
              <ErrorMessageBox>Select Employment Type</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required error={!formData?.income && error}>
            <FormLabel>Income</FormLabel>
            <TextField
              name="income"
              value={formData?.income || ""}
              onChange={handleInputChange}
              placeholder="Income"
              error={!formData?.income && error}
            />
            {!formData?.income && error && (
              <ErrorMessageBox>Enter Income</ErrorMessageBox>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel>UDYAM number</FormLabel>
            <TextField
              name="udyamNumber"
              value={formData?.udyamNumber || ""}
              onChange={handleInputChange}
              placeholder="Enter UDYAM number"
            />
          </FormControl>
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel>Account Aggregator ID</FormLabel>
            <TextField
              name="aa_id"
              value={formData?.aa_id || ""}
              placeholder="Enter Account Aggregator ID"
              disabled
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </PersonalDetailsContainer>
  );
}
