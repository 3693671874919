import { Stack, styled, Button } from "@mui/material";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import React from "react";
import pageNotFound from "../../styles/LottieFiles/page_not_found.json";
import themePalette from "../../styles/themePalette";

const PageNotFoundWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  height: "100vh",
  textAlign: "center",
  padding: theme.spacing(2),
}));

const PageNotFoundContainer = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "90%",
  maxHeight: "70vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <PageNotFoundWrapper>
      <PageNotFoundContainer>
        <Lottie
          animationData={pageNotFound}
          loop
          style={{ width: "100%", maxHeight: "70vh" }}
        />
        <Button
          variant="contained"
          onClick={handleGoHome}
          sx={{
            mt: 2,
            backgroundColor: themePalette.primary,
            fontSize: "2rem",
            textTransform: "none",
          }}
        >
          Go to Home
        </Button>
      </PageNotFoundContainer>
    </PageNotFoundWrapper>
  );
};

export default PageNotFound;
