import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  TextField,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5, 0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(5),
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  "& .MuiDialog-paper": {
    boxShadow: "none",
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  minWidth: "600px",
  minHeight: "400px",
  maxHeight: "600px",
  overflowY: "auto",
  overflowX: "none",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "200px",
    minHeight: "100%",
    maxHeight: "none",
    padding: "10px",
  },
}));

const ViewOfferDetails = React.memo(
  ({ offer, handleDialogButton, open, setOpen, pageName }) => {
    // console.log("loanData", offer);
    const loanData =
      pageName !== "offerPage"
        ? offer?.offer_list[0]?.offer_details
        : offer?.offer_details;
    const GRO =
      pageName !== "offerPage"
        ? offer?.offer_list[0]?.provider_details
        : offer?.provider_details;
    // console.log(GRO);
    const accordionList = [
      {
        title: "Loan Details",
        content: loanData ? (
          <Grid container spacing={2}>
            {Object.entries(loanData).map(([key, value]) => (
              <Grid item xs={12} md={6} key={key}>
                <TextField label={key} value={value} fullWidth disabled />
              </Grid>
            ))}
          </Grid>
        ) : (
          <p style={{ color: "red" }}>Error While fetching Data</p>
        ),
      },
      {
        title: "GRO information",
        content: GRO ? (
          <Grid container spacing={2}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="GRO Name"
                value={GRO?.GRO_NAME}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                style={{ WebkitTextFillColor: "#000000b0" }}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="GRO Email"
                value={GRO?.GRO_EMAIL}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                style={{ WebkitTextFillColor: "#000000b0" }}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="GRO Contact Number"
                value={GRO?.GRO_CONTACT_NUMBER}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                style={{ WebkitTextFillColor: "#000000b0" }}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="GRO Designation"
                value={GRO?.GRO_DESIGNATION}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                style={{ WebkitTextFillColor: "#000000b0" }}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="GRO Address"
                value={GRO?.GRO_ADDRESS}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                style={{ WebkitTextFillColor: "#000000b0" }}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="Customer Support Link"
                value={GRO?.CUSTOMER_SUPPORT_LINK}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                style={{ WebkitTextFillColor: "#000000b0" }}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="Customer Support Contact Number"
                value={GRO?.CUSTOMER_SUPPORT_CONTACT_NUMBER}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                style={{ WebkitTextFillColor: "#000000b0" }}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="Customer Support Email"
                value={GRO?.CUSTOMER_SUPPORT_EMAIL}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                style={{ WebkitTextFillColor: "#000000b0" }}
              />
            </Grid>
          </Grid>
        ) : (
          <p style={{ color: "red" }}>Error While fetching Data</p>
        ),
      },
      {
        title: "LSP information",
        content: GRO ? (
          <Grid container spacing={2}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="LSP Name"
                value={GRO?.LSP_NAME}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="LSP Email"
                value={GRO?.LSP_EMAIL}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="LSP Contact Number"
                value={GRO?.LSP_CONTACT_NUMBER}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <TextField
                label="LSP Address"
                value={GRO?.LSP_ADDRESS}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        ) : (
          <p style={{ color: "red" }}>Error While fetching Data</p>
        ),
      },
      {
        title: "Terms and Conditions",
        content: (
          <Stack flexDirection={"row"} gap={2}>
            <Typography>Please click the link to view the</Typography>
            <a
              style={{
                color: "red",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => window.open(loanData?.TNC_LINK, "_blank")}
            >
              Terms and Conditions
            </a>{" "}
          </Stack>
        ),
      },
    ];

    const handleClose = () => {
      setOpen(false);
    };

    return pageName === "offerPage" ? (
      <React.Fragment>
        <Button variant="text" onClick={handleDialogButton}>
          View Details
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Offer Details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          <StyledDialogContent dividers>
            {accordionList.map((accordion, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}a-content`}
                  id={`panel${index + 1}a-header`}
                >
                  <Typography>{accordion.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{accordion.content}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </StyledDialogContent>
        </BootstrapDialog>
      </React.Fragment>
    ) : (
      <div>
        {accordionList.map((accordion, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}a-content`}
              id={`panel${index + 1}a-header`}
            >
              <Typography>{accordion.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{accordion.content}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  }
);

export default ViewOfferDetails;
