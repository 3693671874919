import { Typography } from "@mui/material";
import React from "react";

const StartKyc = () => {
  return (
    <>
      <Typography variant="h4">KYC</Typography>
      <Typography>
        I hereby declare that I am voluntarily sharing my Aadhaar Number and
        demographic information issued by UIDAI with the concerned authorities
        for the sole purpose of completing the KYC process. I understand that my
        information can be used and shared for purposes as may be notified from
        time to time, including compliance with regulatory requirements.
      </Typography>
    </>
  );
};

export default StartKyc;
