import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../../../utils/request";
import { apis } from "../../../utils/apis";

export const creditSearch = createAsyncThunk(
  "searchCredit",
  async (user_id) => {
    const response = await apiRequest(
      "POST",
      `${apis?.creditSearch}?user_id=${user_id}`
    );
    return response;
  }
);

export const insuranceSearch = createAsyncThunk(
  "searchInsurance",
  async (payload) => {
    if (payload?.txnId) {
      const response = await apiRequest(
        "POST",
        `${apis?.insuranceSearch}?environment=${payload?.environment}&txn_id=${payload?.txnId}`
      );
      return response;
    } else {
      const response = await apiRequest(
        "POST",
        `${apis?.insuranceSearch}?environment=${payload.environment}&user_id=${payload.user_id}`
      );
      return response;
    }
  }
);

const navbarSlice = createSlice({
  name: "SearchCredit",
  initialState: {
    creditList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetSearchData: (state, value) => {
      state.creditList = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(creditSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(creditSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.creditList = action.payload;
      })
      .addCase(creditSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
// export const { reducer } = ProductsSlice;
export default navbarSlice.reducer;
