import React, { useEffect, useState } from "react";
import { styled, Card, Typography, CardContent } from "@mui/material";
import NomineeForm from "../../../components/NomineeFormComponent";

const CardWrapper = styled("div")(({ theme }) => ({
  ".card-container": {
    "&.MuiPaper-root": {
      padding: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    "& > .MuiCardHeader-root": {
      padding: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "16px",
      },
    },
    "& > .MuiCardHeader-content": {
      padding: "24px",
    },
  },
  ".card-header": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },

  ".card-title-text": {
    "&.MuiTypography-root": theme.typography.body2,
  },
}));

const NomineeFormPage = () => {
  //   useEffect(() => {
  //   }, []);

  return (
    <>
      <CardWrapper>
        <Card className="card-container">
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              Nominee Form
            </Typography>
            <br />
            <NomineeForm />
          </CardContent>
        </Card>
      </CardWrapper>
    </>
  );
};

export default NomineeFormPage;
